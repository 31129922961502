import React, { Component } from 'react';
import withStyles from 'react-jss'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import {
//   startNewClientCreation

// } from "../../../../redux/actions/clients";

// import { API, Auth } from "aws-amplify";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Steps } from 'antd';
// import { message } from 'antd';
// import { Card, Button } from 'antd';
// import { notification } from 'antd';

import { Tabs } from 'antd';

import PromoCodeSummary from './PromoCodeSummary';


const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: "right"
  },
};
const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}

class PromoCodeViewPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_step: 0,
    }
  }

  componentDidMount() {
    
  }

  // handleSubmit = async (event) => {
  //   console.log(this.props.clients.new_client_data)
  //   API.post("clients", `/create/in-database`, {
  //     body: {
  //       ...this.props.clients.new_client_data
  //     }
  //   })
  //     .then(database_result => {
  //       console.log(database_result)
  //       // openNotificationWithIcon('success', "Record Created", "New client has been successfully created.")
  //       return database_result;
  //     })
  //     .then(database_result => {
  //       // console.log(database_result)
  //       // openNotificationWithIcon('success', "Record Created", "New client has been successfully created.")
  //       this.props.history.push(`/clients/list`);
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }


  render() {
    console.log(this.props.promocode_id)

    // const { classes } = this.props;
    // const { current_step } = this.state;

    //if (!this.props.clients && !this.props.clients.new_client_data) return null;
    if (!this.props.promocode_id) return null;

    return (

      <React.Fragment>

        {/* <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="PromoCode Summary" key="1">
        */}
            <PromoCodeSummary
              promocode_id={this.props.promocode_id}
             />

          {/* </TabPane> */}
          {/* <TabPane tab="Contact" key="2">
            Content of Tab Pane 2
          </TabPane>
          <TabPane tab="Address" key="3">
            Content of Tab Pane 3
          </TabPane> */}
        {/* </Tabs> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // startNewClientCreation: () => {
    //   dispatch(startNewClientCreation())
    // }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const PromoCodeViewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(PromoCodeViewPageComponent)));

export default PromoCodeViewPageComponentContainer;
