
import React, { Component } from 'react';
import { Table } from 'antd';

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";
import { NavLink } from "react-router-dom";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Divider } from 'antd';
// import { Card, Button } from 'antd';
import { Form, Input } from 'antd';
import { Row, Col } from 'antd';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

const { TextArea } = Input;

const expanded_row_layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    render: text => <a>{text.toString().padStart(5, '0')}</a>,
  },
  {
    title: 'nickname',
    dataIndex: 'file_by',
  },
  {
    title: 'Actions',
    key: 'action',
    render: (text, record) => (
      <span>
        <NavLink to={`/main-categories/view/${record.id}`} >View</NavLink>
        {/* <Divider type="vertical" />
        <a>Delete</a> */}
      </span>
    ),
  },
];

class CategoryMainListPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: []
    }
  }

  componentDidMount() {
    API.get("video-categories", `/main/list`)
      .then(response => {
        // console.log(response);
        // console.log(response.payload);

        let dataSource = [];
        for (let i = 0; i < response.payload.length; i++) {
          dataSource.push({
            key: response.payload[i].id,
            id: response.payload[i].id,
            file_by: response.payload[i].file_by,
            description_short: response.payload[i].description_short,
            description_long: response.payload[i].description_long
            // email: response.payload[i].email,
            // first_name: response.payload[i].first_name,
            // last_name: response.payload[i].last_name,
          });
        }
        // console.log(dataSource);
        this.setState({
          dataSource: dataSource
        })
      })
      .catch(error => {
        console.log(error);
      });
  }

  
  render() {
    // console.log(this.state);
    // console.log(this.dataSource);
    // console.log(this.props);
    return (
      <React.Fragment>
        <Table
          columns={columns}
          dataSource={this.state.dataSource}
          pagination={{
            pageSizeOptions: ['10', '20', '30'],
            showSizeChanger: true
          }}
          expandedRowRender={record => {
            // console.log(record);
            return (<React.Fragment>
              <Row gutter={[96, 24]}>
                <Row>
                </Row>
                <Col xs={12}>
                  <Form {...expanded_row_layout} onSubmit={this.handleSubmit}>
                    <Form.Item label="Short Description" >

                      <TextArea
                        value={record.description_short}
                        disabled
                        autoSize={{ minRows: 2, maxRows: 8 }}
                      />
                    </Form.Item>
                    <Form.Item label="Long Description" >
                      <TextArea
                        value={record.description_long}
                        disabled
                        autoSize={{ minRows: 2, maxRows: 8 }}
                      />
                    </Form.Item>
                  </Form>
                </Col>
                {/* <Col xs={12}>
                      <Form {...expanded_row_layout} onSubmit={this.handleSubmit}>
                        <Form.Item label="CTIN Portal" >
                          <a href="https://admin.ctin.com.au/customers/create" target="_blank">Create New Account</a>
                        </Form.Item>
                        <Form.Item label="Current SDA Product" >
                          <Tag color={record.products_badge_color} style={{ width: 100, textAlign: "center" }} key={record.current_product_id}>{record.products_nickname}</Tag>
                        </Form.Item>
                        <Form.Item label="Referring Client" >
                          <NavLink to={`/clients/view/${record.referring_client_id}`} >
                            <Tag color={record.referrer_badge_color} style={{ width: 20, textAlign: "center" }} key={record.referring_client_id}>&nbsp;</Tag>
                            {record.referring_client_nickname}
                          </NavLink>
                        </Form.Item>
                      </Form>
                    </Col> */}
              </Row>
            </React.Fragment>)
          }}
        />
      </React.Fragment>
    );
  }
}

export default CategoryMainListPageComponent;
