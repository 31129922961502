
import React, { Component } from 'react';
import { Table } from 'antd';

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";
import { NavLink } from "react-router-dom";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Divider } from 'antd';
// import { Card, Button } from 'antd';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

//display id, nickname, product nickname, term and price
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    render: text => <a>{text.toString().padStart(5, '0')}</a>,
  },
  {
    title: 'Nickname',
    dataIndex: 'nickname',
  },
  {
    title: 'Product Nickname',
    dataIndex: 'product_nickname',
  },
  {
    title: 'Term',
    dataIndex: 'term',
  },
  {
    title: 'Price',
    render: (text, record) => (
      <span>
        <p>{`AUD ${record.retail_price}`} </p>
      </span>
    ),
  },
  {
    title: 'Actions',
    key: 'action',
    render: (text, record) => (
      <span>
        <NavLink to={`/plans/view/${record.id}`} >View</NavLink>
        {/* <Divider type="vertical" />
        <a>Delete</a> */}
      </span>
    ),
  },
];

class PlansListPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: []
    }
  }

  //display id, nickname, product nickname, term and price
  componentDidMount() {
    API.get("plans", `/list`)
      .then(response => {
        // console.log(response);
        // console.log(response.payload);

        let dataSource = [];
        for (let i = 0; i < response.payload.length; i++) {
          dataSource.push({
            key: response.payload[i].id,
            id: response.payload[i].id,
            // product_id: response.payload[i].id,
            nickname: response.payload[i].nickname,
            product_nickname: response.payload[i].product_nickname,
            term: response.payload[i].term,
            retail_price: response.payload[i].retail_price,
            // email: response.payload[i].email,
            // first_name: response.payload[i].first_name,
            // last_name: response.payload[i].last_name,
          });
        }
        // console.log(dataSource);
        this.setState({
          dataSource: dataSource
        })
      })
      .catch(error => {
        console.log(error);
      });
  }


  render() {
    // console.log(this.state);
    // console.log(this.dataSource);
    // console.log(this.props);
    return (
      <React.Fragment>
        <Table
          columns={columns}
          dataSource={this.state.dataSource}
          pagination={{ 
            pageSizeOptions: ['10', '20', '30'], 
            showSizeChanger: true }}
        />
      </React.Fragment>
    );
  }
}

export default PlansListPageComponent;
