
import React, { Component } from 'react';
import { connect } from "react-redux";

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";

//import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card, Button } from 'antd';
// import { notification } from 'antd';
import { Form, Input } from 'antd';
const moment = require('moment')

// const openNotificationWithIcon = (type, header, message) => {
//   notification[type]({
//     message: header,
//     description: message,
//   });
// };

class SubscriptionSuccessPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      existing: false
    }
  }


  componentDidMount = async () => {
    API.get("stripe", `/subscription/process-success/${this.props.session_id}`)
      .then(async (response) => {
        console.log(response)
        if (response.payload.existing) {
          this.setState({
            existing: true,
            loading: false
          })
          return;
        };
        this.setState({
          client: response.payload.client_result,
          product: response.payload.product_result,
          plan: response.payload.plan_result,
          stripe_subscription: response.payload.stripe_subscription,
          loading: false
        })
        await API.post("email", `/interaction`, {
          body: {
            interaction: "Subscription Success",
            data: {
              ...response.payload.client_result,
              ...response.payload.product_result,
              ...response.payload.plan_result,
              ...response.payload.stripe_subscription
            },
            result: "Subscription Success"
          }
        })
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });
      }).catch(err => {
        console.log(err);
        API.get("stripe", `/subscription/process-success/${this.props.session_id}`)
          .then(async (response) => {
            console.log(response)
            if (response.payload.existing) {
              this.setState({
                existing: true,
                loading: false
              })
              return;
            };
            this.setState({
              client: response.payload.client_result,
              product: response.payload.product_result,
              plan: response.payload.plan_result,
              stripe_subscription: response.payload.stripe_subscription,
              loading: false
            })
            await API.post("email", `/interaction`, {
              body: {
                interaction: "Subscription Success",
                data: {
                  ...response.payload.client_result,
                  ...response.payload.product_result,
                  ...response.payload.plan_result,
                  ...response.payload.stripe_subscription
                },
                result: "Subscription Success"
              }
            })
              .then(response => {
                console.log(response);
              })
              .catch(error => {
                console.log(error);
              });
    
          }).catch(err => {
            console.log(err);
          });
      });
  }


  // async saveVideoRecord(client_record) {
  //   await API.put("clients", `/update/${this.state.id}`, {
  //     body: client_record
  //   });
  //   openNotificationWithIcon('success', "Save Successful", "Your data has been saved successfully")
  // }


  // handleChange = event => {
  //   // console.log(event)
  //   this.setState({
  //     [event.target.id]: event.target.value
  //   });
  // };

  // handleSubmit = async event => {
  //   event.preventDefault();
  //   // console.log(event)
  //   this.setState({ isLoading: true });

  //   const {
  //     // id,
  //     // aws_cognito_id,
  //     first_name,
  //     // middle_name,
  //     last_name,
  //     // email,
  //   } = this.state;
  //   try {
  //     await this.saveClientRecord({
  //       // aws_cognito_id: aws_cognito_id,
  //       first_name: first_name,
  //       // middle_name: middle_name,
  //       last_name: last_name,
  //     });
  //     // this.props.history.push("/");
  //     // this.props.reload_user(this.state.aws_cognito_id);
  //   } catch (e) {
  //     // alert(e);
  //     try {
  //       await this.saveClientRecord({
  //         // aws_cognito_id: aws_cognito_id,
  //         // first_name: first_name,
  //         // middle_name: middle_name,
  //         // last_name: last_name,
  //       });
  //       // this.props.history.push("/");
  //       // this.props.reload_user(this.state.aws_cognito_id);
  //     } catch (e) {
  //       // alert(e);
  //       openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
  //       // this.setState({ isLoading: false });
  //     }

  //     // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
  //     // this.setState({ isLoading: false });
  //   }
  // };

  render() {
    // console.log(this.state);
    if (this.state.loading) { return null; }
    if (this.state.existing) { return null; }

    return (

      <React.Fragment>
        <Form>

          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Form.Item label="Client">
                <Input
                  id="client_nickname"
                  value={this.state.client.nickname}
                />
              </Form.Item>
              <Form.Item label="Product">
                <Input
                  id="product_nickname"
                  value={this.state.product.nickname}
                />
              </Form.Item>
              <Form.Item label="Plan">
                <Input
                  id="plan_nickname"
                  value={this.state.plan.nickname}
                />
              </Form.Item>
              <Form.Item label="Period Start">
                <Input
                  id="plan_start"
                  value={moment.unix(this.state.stripe_subscription.current_period_start).format("DD MM YYYY")}
                />
              </Form.Item>
              <Form.Item label="Period End">
                <Input
                  id="plan_end"
                  value={moment.unix(this.state.stripe_subscription.current_period_end).format("DD MM YYYY")}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const SubscriptionSuccessPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionSuccessPageComponent);

export default SubscriptionSuccessPageComponentContainer;
