import React, { Component } from 'react';
import withStyles from 'react-jss'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import {
//   startNewClientCreation

// } from "../../../../redux/actions/clients";

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";

//import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input } from 'antd';

// import { Tabs } from 'antd';
// import { Upload } from 'antd';
// import { message } from 'antd';
// import { InboxOutlined } from '@ant-design/icons';
// import { DashboardOutlined, VideoCameraOutlined } from '@ant-design/icons'
import { Select } from 'antd';

// import { loadStripe } from '@stripe/stripe-js';

// import './DatePickerSample.css';
// import { DatePicker } from 'antd';

const { Option } = Select;
// const stripePromise = loadStripe('pk_live_1NNfbEQHqrpbF9ouaKrVFilx00Npb3yl14',
//   {
//     stripeAccount: 'acct_1GYvZVLdj3tiY8tr'
//   });

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: "right"
  },
};

// var moment = require("moment");

class ProductNewPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product_list: [],
    }
    API.get("products","/list").then(
      result => {
        // console.log(result);
        this.setState({
          product_list : result.payload
        })
      }
    ).catch(err => {
      console.log(err);
    });
  }

  componentDidMount = async () => {


  }

  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSelectChange_terms = value => {
    console.log(value);
    this.setState({
      //[event.target.id]: event.target.value
      term: value
    });
  }

  handleSelectChange_product= value => {
    console.log(value);
    this.setState({
      //[event.target.id]: event.target.value
      product_id: value
    });
  }

  handleSubmit = async (event) => {

    event.preventDefault();
    openNotificationWithIcon('info', "Record Creation", "New tag record creation is starting.")
    //console.log(event);
    // console.log(this.state);
    //console.log(this.props.clients.new_client_data)
    const {
      // current_user_id,
      nickname,
      product_id,
      term,
      retail_price
    } = this.state;

    try {
      API.post("plans", `/create`, {
        body: {
          // id: id,
          // current_user_id
          nickname: nickname,
          product_id,
          term,
          retail_price
          //...this.props.clients.new_client_data
        }
      })
      .then(response => {
        console.log(response)
        // openNotificationWithIcon('info', "Record Creation", "Creating a billing record for product.")

        // API.post("stripe", `/product/create/${response.payload.insertId}`, {
        //   body: {
        //     nickname
        //   }
        // })
        //   .then(response => {
        //     // console.log(response)
        //     this.setState({
        //       stripe_id: response.payload.database_result
        //     })
        //     return response;
        //   })
        //   .catch(error => {
        //     // console.log(error);
        //     API.post("stripe", `/product/create/${response.payload.insertId}`, {
        //       body: {
        //         nickname
        //       }
        //     })
        //       .then(response => {
        //         // console.log(response)
        //         this.setState({
        //           stripe_id: response.payload.database_result
        //         })
        //         return response;
        //       })
        //       .catch(error => {
        //         console.log(error);
        //       });
        //   });
        // return response;
      })
      .then(database_result => {
        console.log(database_result)
        openNotificationWithIcon('success', "Record Created", "New plans has been successfully created.")
        window.location.assign(`/plans/list`);
      })
      .catch(error => {
        console.log(error);
      });
    } catch (e) {
      alert(e);
      openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
    }

    this.setState({ isLoading: true });

  };

  validationSubmitButton = () => {
    if(!this.state.nickname || this.state.nickname === " "){
      return true
    }
    if(!this.state.product_id || this.state.product_id === " "){
      return true
    }
    if(!this.state.term || this.state.term === " "){
      return true
    }
    if(!this.state.retail_price || this.state.retail_price === " "){
      return true
    }
    return false
  }

  render() {
    // const { classes } = this.props;
    // console.log(this.props.app_state.current_user_id)

    let product_list_select_options = this.state.product_list.map(d => <Option key={d.id} >{d.nickname}</Option>);
    // console.log(product_list_select_options);
    // console.log(this.state)

    return (

      <React.Fragment>

        <Form layout="vertical">
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Form.Item
                label="Nickname"
              >
                <Input
                  id="nickname"
                  value={this.state.nickname}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Product">
                {/*<Input
                  id="product_id"
                  disabled
                  value={this.state.product_id}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />*/}
                <Select
                  id="product_id"
                  name="product_id"
                  showSearch
                  value={
                    typeof this.state.product_id === "number"
                      ? this.state.product_id.toString()
                      : (this.state.product_id === " ")
                        ? "Select Product"
                        : this.state.product_id
                  }
                  placeholder="Select Product"
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  }
                  onChange={this.handleSelectChange_product}
                >
                  {
                    product_list_select_options
                  }
                </Select>
              </Form.Item>
              <Form.Item label="Term" >
                {/*<Input
                  id="term"
                  value={this.state.term}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />*/}
                <Select
                  id="term"
                  showSearch
                  value={
                    typeof this.state.term === "number"
                      ? this.state.term.toString()
                      : this.state.term
                  }
                  placeholder="Select terms"
                  //style={{ width: 180 }}
                  onChange={value => {
                    this.handleSelectChange_terms(value)
                  }}
                >
                  <Option value="1">
                    daily
                  </Option>
                  <Option value="2">
                    weekly
                  </Option>
                  <Option value="3">
                    monthly
                  </Option>
                  <Option value="4">
                    annually
                   </Option>
                </Select>
              </Form.Item>
              <Form.Item label="Retail Price (AUD$)" >
                <Input
                  id="retail_price"
                  placeholder="0.00"
                  value={this.state.retail_price}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                disabled={this.validationSubmitButton()}
                htmlType="submit"
                onClick={this.handleSubmit}
              >
                Create New Record
              </Button>
            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {

  }
}

const ProductNewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(ProductNewPageComponent)));

export default ProductNewPageComponentContainer;
