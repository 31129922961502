
import React, { Component } from 'react';
import { Table } from 'antd';

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";
import { NavLink } from "react-router-dom";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Divider } from 'antd';
// import { Card } from 'antd';
// import { Form } from 'antd';
import { Button } from 'antd';
import { Input } from 'antd';
// import { Select } from 'antd';
import { Tag } from 'antd';

import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

// const { Option } = Select;

var moment = require("moment");

class SubscriptionListPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: []
    }

    this.columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        render: text => <a>{text.toString().padStart(5, '0')}</a>,
      },
      {
        title: 'Record Created',
        dataIndex: 'record_created',
        sorter: (a, b) => moment(a.record_created).unix() - moment(b.record_created).unix(),
        defaultSortOrder: "descend",
        render: (text, record) => {
          return {
            props: {
              style: { verticalAlign: "center" }
            },
            children: <React.Fragment>
              <span>
                {
                  moment(record.record_created).format('LLLL') === "Invalid date"
                    ? " "
                    : <span>{moment(record.record_created).format('DD MMMM YYYY')}
                      <br />
                      {moment(record.record_created).format('HH:mm a')}</span>
                }
              </span>
            </React.Fragment>
          }
        },
      },
      {
        title: 'Client',
        dataIndex: 'client_nickname',
        ...this.getColumnSearchProps('client_nickname'),
      },
      {
        title: 'Plan',
        dataIndex: 'plan_nickname',
        //...this.getColumnSearchProps('plan_nickname'),
        filters: [
          { text: 'XO Online Member Monthly', value: 'XO Online Member Monthly' },
          { text: 'XO Online Member Annual', value: 'XO Online Member Annual' },
        ],
        onFilter: (value, record) => record.plan_nickname.includes(value),
      },
      {
        title: 'Plan',
        dataIndex: 'plan_retail_price',
        render: (text, record) => (
          <span>
            {Number.parseFloat(text).toFixed(2)}
          </span>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status_id',
        sorter: (a, b) => a.id - b.id,
        filters: [
          { text: 'Active', value: 1 },
          { text: 'Cancelling', value: 2 },
          { text: 'Cancelled', value: 3 }
        ],
        onFilter: (value, record) => record.status_id === value,
        render: data_item => {
          switch (data_item) {
            case 1:
              return <Tag color={'green'} style={{ width: 100, textAlign: "center" }} key={data_item}>ACTIVE</Tag>
            case 2:
              return <Tag color={'orange'} style={{ width: 100, textAlign: "center" }} key={data_item}>CANCELLING</Tag>
            case 3:
              return <Tag color={'red'} style={{ width: 100, textAlign: "center" }} key={data_item}>CANCELLED</Tag>
            // case 3:
            //   return <Tag color={'orange'} style={{ width: 100, textAlign: "center" }} key={data_item}>REGISTERED</Tag>
            // case 4:
            //   return <Tag color={'green'} style={{ width: 100, textAlign: "center" }} key={data_item}>ACTIVE</Tag>
            // default:
            //   return <Tag color={'red'} style={{ width: 100, textAlign: "center" }} key={data_item}>ERROR</Tag>
          }
        }
      },
      {
        title: 'Actions',
        key: 'action',
        render: (text, record) => (
          <span>
            <NavLink to={`/subscription/view/${record.id}`} >View</NavLink>
            {/* <Divider type="vertical" />
            <a>Delete</a> */}
          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    API.get("subscription", `/list`)
      .then(response => {
        // console.log(response);
        console.log(response.payload);

        let dataSource = [];
        for (let i = 0; i < response.payload.length; i++) {
          dataSource.push({
            key: response.payload[i].id,
            id: response.payload[i].id,
            record_created: response.payload[i].record_created,
            client_id: response.payload[i].client_id,
            client_nickname: response.payload[i].client_nickname,
            plan_id: response.payload[i].plan_id,
            plan_nickname: response.payload[i].plan_nickname,
            plan_retail_price: response.payload[i].plan_retail_price,
            status_id: response.payload[i].status_id
            // email: response.payload[i].email,
            // first_name: response.payload[i].first_name,
            // last_name: response.payload[i].last_name,
          });
        }
        // console.log(dataSource);
        this.setState({
          dataSource: dataSource
        })
      })
      .catch(error => {
        console.log(error);
      });
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          //placeholder={`Search ${dataIndex}`}
          placeholder={`Search clients`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    // console.log(this.state);
    // console.log(this.dataSource);
    // console.log(this.props);
    return (
      <React.Fragment>
        <Table
          columns={this.columns}
          dataSource={this.state.dataSource}
          pagination={{
            pageSizeOptions: ['10', '20', '30'],
            showSizeChanger: true
          }}
        />
      </React.Fragment>
    );
  }
}

export default SubscriptionListPageComponent;
