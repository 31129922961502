
import React, { Component } from 'react';

// import { API, Auth } from "aws-amplify";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import { Card } from 'antd';
import { PageHeader } from 'antd';
// import { Button } from 'antd';
import SubscriptionViewPageComponentContainer from '../../../components/pages/dashboard/subscription/SubscriptionViewPageComponent';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class SubscriptionViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {

    // console.log(this.props.match.params.file_id)

    return (

      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="Subscription detail"
        />
        <Card
          style={{ minHeight: 360 }}
          title="View details of a subscription"
        >
          <div style={{ paddingTop: 24 }}>
            <SubscriptionViewPageComponentContainer
              id={this.props.match.params.id}
            />
          </div>
        </Card>
      </div>


    );
  }
}

export default SubscriptionViewPage;
