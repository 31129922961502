import React, { Component } from 'react';
import withStyles from 'react-jss'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import {
//   startNewClientCreation

// } from "../../../../redux/actions/clients";

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";

//import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
// import { Input } from 'antd';
import { Form } from 'antd';

// import { Tabs } from 'antd';
// import { Upload } from 'antd';
// import { message } from 'antd';
// import { InboxOutlined } from '@ant-design/icons';
// import { DashboardOutlined, VideoCameraOutlined } from '@ant-design/icons'
import { Select } from 'antd';

import { loadStripe } from '@stripe/stripe-js';


// import './DatePickerSample.css';
// import { DatePicker } from 'antd';

const { Option } = Select;
const stripePromise = loadStripe('pk_live_1NNfbEQHqrpbF9ouaKrVFilx00Npb3yl14',
  {
    stripeAccount: 'acct_1GYvZVLdj3tiY8tr'
  });

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: "right"
  },
};
// const { Dragger } = Upload;

// var moment = require("moment");

class SubscriptionNewPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client_id: " ",
      client_list: [],
      plan_id: " ",
      plan_list: [],
      description: "",
    }
  }

  componentDidMount = async () => {
    API.get("clients", `/ddl`)
      .then((response) => {
        // console.log(response)
        this.setState({
          client_list: response.payload,
        })
      }).catch(err => {
        console.log(err);
      });
    API.get("plans", `/ddl`)
      .then((response) => {
        // console.log(response)
        this.setState({
          plan_list: response.payload,
        })
      }).catch(err => {
        console.log(err);
      });
  }

  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSelectChange_client = (id) => {
    this.setState({
      client_id: id
    });
    API.get("clients", `/id/${id}`)
      .then((response) => {
        console.log(response)
        this.setState({
          client: response.payload,
        })
      }).catch(err => {
        console.log(err);
      });
  };

  handleSelectChange_plan = (id) => {
    this.setState({
      plan_id: id
    });
    API.get("plans", `/id/${id}`)
      .then((response) => {
        console.log(response)
        this.setState({
          plan: response.payload,
        })
      }).catch(err => {
        console.log(err);
      });
  };


  handleSubmit = async event => {
    event.preventDefault();
    const {
      client_id,
      plan_id
    } = this.state;

    API.post("subscription", `/create`, {
      body: {
        client_id,
        plan_id
      }
    })
      .then(async database_result => {
        console.log(database_result)
        openNotificationWithIcon('success', "Subscription Created", "New client has been successfully created.")
        // return database_result;
        console.log(database_result.payload.session.id)
        await API.post("email", `/interaction`, {
          body: {
            interaction: "Subscription Starting",
            data: {
              client_id,
              sessionId: database_result.payload.session.id,
              ...database_result.payload.session
            },
            result: "Subscription Commencing"
          }
        })
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });

        const stripe = await stripePromise;

        // eslint-disable-next-line
        const { error } = await stripe.redirectToCheckout({
          sessionId: database_result.payload.session.id
        }, {
          stripe_account: "acct_1GYvZVLdj3tiY8tr",
        });

      })
      // .then(database_result => {
      // console.log(database_result)
      // openNotificationWithIcon('success', "Record Created", "New client has been successfully created.")
      // this.props.history.push(`/subscription/list`);
      // })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    // const { classes } = this.props;
    // const { current_step } = this.state;
    // console.log(this.state);
    // console.log(this.props);

    // if (!this.props.clients && !this.props.clients.new_client_data) return null;
    let client_list_select_options = this.state.client_list
      .sort((a, b) => {return a.nickname.localeCompare(b.nickname)})
      .map(d => <Option key={d.id} >{d.nickname}</Option>);
    let plan_list_select_options = this.state.plan_list.map(d => <Option key={d.id} >{d.nickname}</Option>);

    return (

      <React.Fragment>

        <Form layout="vertical">

          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Form.Item
                name={['user', 'client_id']}
                label="Client"
                help={this.state.client ? <React.Fragment>
                  Selected client: {this.state.client.nickname}<br />
                  Email: {this.state.client.email}<br />
                  VIP: {this.state.client.vip ? "VIP Active" : "Nil"}<br />
                  Current Subscription: {this.state.client.plans_nickname || "Nil"}
                </React.Fragment> : ""}
              >
                <Select
                  id="client_id"
                  name="client_id"
                  showSearch
                  value={
                    typeof this.state.client_id === "number"
                      ? this.state.client_id.toString()
                      : (this.state.client_id === " ")
                        ? "Select Client"
                        : this.state.client_id
                  }
                  placeholder="Select Category"
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  }
                  onChange={this.handleSelectChange_client}
                >
                  {
                    client_list_select_options
                  }
                </Select>

              </Form.Item>
              {this.state.client?.plans_nickname && <React.Fragment>
                <strong>WARNING:</strong> this user already has a current subscription.
                <p>
                  Proceeding will create a new subscription and they will therefore have two current.<br/>
                  Please contact <a href="mailto: dev@pimwatech.com">Pimwa Technologies</a> to query this.
                </p>
              </React.Fragment>}
              <Form.Item
                name={['user', 'plan_id']}
                label="Plan"
                help={this.state.plan ? "Selected plan: " + this.state.plan.nickname : ""}
              >
                <Select
                  id="plan_id"
                  name="plan_id"
                  showSearch
                  value={
                    typeof this.state.plan_id === "number"
                      ? this.state.plan_id.toString()
                      : (this.state.plan_id === " ")
                        ? "Select Category"
                        : this.state.plan_id
                  }
                  placeholder="Select Category"
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  }
                  onChange={this.handleSelectChange_plan}
                >
                  {
                    plan_list_select_options
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12}>

            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                disabled={this.state.client_id == " " || this.state.plan_id == " "}
                htmlType="submit"
                onClick={this.handleSubmit}
              >
                Create Subscription
              </Button>
            </Col>
          </Row>
        </Form>


      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    // clients: state.clients,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // startNewClientCreation: () => {
    //   dispatch(startNewClientCreation())
    // }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const SubscriptionNewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(SubscriptionNewPageComponent)));

export default SubscriptionNewPageComponentContainer;
