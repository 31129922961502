import React, { Component } from 'react';

// import { API, Auth } from "aws-amplify";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import { Card } from 'antd';
import { PageHeader, Button } from 'antd';

import PlansListPageComponent from '../../../components/pages/dashboard/plans/PlansListPageComponent';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class PlansListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  handle_show_all = () => {
    this.props.history.push(`/plans/list/`);
  }

  handle_new_plans = () => {
    this.props.history.push(`/plans/new`);
  }
  
  render() {
    return (

      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="Plan List"
        />
        <Card
          style={{ minHeight: 360 }}
          title="List all plans"
          extra={[
            <React.Fragment>
              {
                <span>
                  <Button key="1" disabled onClick={this.handle_show_all}>
                    Show All
                  </Button>
                  <Button key="2" onClick={this.handle_new_plans}>
                    New Plans
                  </Button>
                </span>
              }
            </React.Fragment>

          ]}
        >
          <div style={{ paddingTop: 24 }}>
            <PlansListPageComponent />
          </div>
        </Card>
      </div>


    );
  }
}

export default PlansListPage;
