import React, { Component } from 'react';
import withStyles from 'react-jss'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { injectIntl } from "react-intl";

import {
  startNewClientCreation,
  updateNewClientCreation
} from "../../../../redux/actions/clients";
import {
  create_client_cognito_account,
  create_stripe_customer_id
} from "../../../../library/clients";

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import { Steps } from 'antd';
// import { message } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';

import NewClientWizard01Container from "./new_client_wizard/NewClientWizard01";
import NewClientWizard02Container from "./new_client_wizard/NewClientWizard02";
import NewClientWizard03Container from "./new_client_wizard/NewClientWizard03";
var generator = require('generate-password');

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: "right"
  },
};


const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

const { Step } = Steps;

const steps = [
  {
    title: 'Client Details',
    content: <NewClientWizard01Container />,
  },
  {
    title: 'Contact Details',
    content: <NewClientWizard02Container />,
  },
  {
    title: 'Confirm',
    content: <NewClientWizard03Container />,
  },
];

class ClientNewPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_step: 0,
    }
  }

  componentDidMount() {
    this.props.startNewClientCreation();
  }

  validate_step_1 = async () => {
    let validate = true;
    if (!(this.props.clients.new_client_data.first_name.length > 0)) {
      this.props.updateNewClientCreation("first_name_status", "error");
      validate = false;
    }
    if (!(this.props.clients.new_client_data.last_name.length > 0)) {
      this.props.updateNewClientCreation("last_name_status", "error");
      validate = false;
    }
    // if (!(this.props.clients.new_client_data.email.length > 0)) {
    //   this.props.updateNewClientCreation("email_status", "error");
    //   validate = false;
    // }

    if (!(this.props.clients.new_client_data.email.length > 0)) {
      this.props.updateNewClientCreation("email_status", "error");
      this.props.updateNewClientCreation("email_error_message", this.props.intl.formatMessage({
        id: "new_client_page.email_required",
        defaultMessage: `Email is required`
      }));
      validate = false;
    } else {
      validate = await API.get("clients", `/check-email/${this.props.clients.new_client_data.email}`)
        .then(
          database_result => {
            console.log(database_result);
            if (!database_result.payload) {

              openNotificationWithIcon('error', this.props.intl.formatMessage({
                id: "notifications.error.newClientWizard01.message",
                defaultMessage: `Unable to create client`
              }), this.props.intl.formatMessage({
                id: "notifications.error.newClientWizard01.description",
                defaultMessage: `Email is already in the database`
              }))

              this.props.updateNewClientCreation("email_status", "error");
              // this.props.updateNewClientCreation("email_error_message", "Email is already in the database");
              this.props.updateNewClientCreation("email_error_message", this.props.intl.formatMessage({
                id: "new_client_page.email_existed",
                defaultMessage: `Email is already in the database`
              }));
            }
            return database_result.payload;
          }
        )
    }
    console.log(validate);
    return validate;
  }

  async next_step () {
    let validate = true;
    switch (this.state.current_step) {
      case 0:
        validate = await this.validate_step_1();
        break;
      default:
        console.log("run default");
        break;
    }

    if (!validate) {
      this.setState({
        step_status_error: true
      })
      console.log(validate);
    } else {
      const current_step = this.state.current_step + 1;
      this.setState({ current_step: current_step });
      console.log(validate);
    }
  }

  prev_step() {
    const current_step = this.state.current_step - 1;
    this.setState({ current_step });
  }

  handleSubmit = async (event) => {
    console.log(this.props.clients.new_client_data)
    API.post("clients", `/create`, {
      body: {
        //first_name: "Tom"
        // last_name: "Yang"
        // email: "tom.yang@pimwa.com"
        // middle_name: "Tester"
        // telephone_home: "0423551500 home"
        // telephone_work: "0423551500 work"
        // telephone_mobile: "0423551500 mobile"
        // email_secondary: "tom.yang2@pimwa.com"
        // street_address_line_1: "Test lane 1"
        // street_address_line_2: "Test lane 2"
        // street_address_suburb: "Melbourne"
        // street_address_state: "VIC"
        // street_address_postcode: "3000"
        // street_address_country: "Australia" //9
        // postal_address_line_1: "Test Line 1 Postal"
        // postal_address_line_2: "Test Line 2 Postal"
        // postal_address_suburb: "Sydney"
        // postal_address_state: "NSW"
        // postal_address_postcode: "2000"
        // postal_address_country: "Australia" //9
        ...this.props.clients.new_client_data
      }
    })
      .then(async response => {
        console.log(response)
        var password = generator.generate({
          length: 9, //10
          numbers: true
        });
        password = password + "8";
        await create_client_cognito_account(response.payload.insertId, this.props.clients.new_client_data.email, password);
        await create_stripe_customer_id(response.payload.insertId, this.props.clients.new_client_data.first_name, this.props.clients.new_client_data.last_name, this.props.clients.new_client_data.email)
        openNotificationWithIcon('success', "Record Created", "New client has been successfully created.")
        return response;
      })
      .then(response => {
        // console.log(database_result)
        // openNotificationWithIcon('success', "Record Created", "New client has been successfully created.")
        this.props.history.push(`/clients/list`);
      })
      .catch(error => {
        console.log(error);
      });
  }


  render() {
    const { classes } = this.props;
    const { current_step } = this.state;
    if (!this.props.clients && !this.props.clients.new_client_data) return null;

    return (
      <React.Fragment>
        <Steps current={current_step}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className={classes.stepsContent}>
          {steps[current_step].content}
        </div>
        <div className={classes.stepsAction}>
          {current_step > 0 && (
            <Button style={{ marginLeft: 8 }} onClick={() => this.prev_step()}>
              Previous
            </Button>
          )}
          {current_step < steps.length - 1 && (
            <Button type="primary" onClick={() => this.next_step()}>
              Next
            </Button>
          )}
          {current_step === steps.length - 1 && (
            <Button type="primary" onClick={this.handleSubmit}>
              Done
            </Button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startNewClientCreation: () => {
      dispatch(startNewClientCreation())
    },
    updateNewClientCreation: (key, value) => {
      dispatch(updateNewClientCreation(key, value))
    }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const ClientNewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(injectIntl(ClientNewPageComponent))));

export default ClientNewPageComponentContainer;
