import React, { Component } from 'react';

// import { API, Auth } from "aws-amplify";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import { Card } from 'antd';
import { PageHeader, Button } from 'antd';

import BrickViewPageComponent from '../../../components/pages/dashboard/bricks/BrickViewPageComponent';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class BrickViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  handle_show_all = () => {
    this.props.history.push(`/bricks/list/`);
  }

  handle_new_bricks = () => {
    this.props.history.push(`/bricks/new`);
  }
  
  render() {

    // console.log(this.props.match.params.client_id)

    return (

      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="View Brick"
        />
        <Card
          style={{ minHeight: 360 }}
          title="Edit Brick detail"
          extra={[
            <React.Fragment>
              {
                <span>
                  <Button key="1" onClick={this.handle_show_all}>
                    Show All
                  </Button>
                  <Button key="2" onClick={this.handle_new_bricks}>
                    New Brick
                  </Button>
                </span>
              }
            </React.Fragment>

          ]}
        >
          <div style={{ paddingTop: 24 }}>
          <BrickViewPageComponent
            id={this.props.match.params.id}
          />

          </div>
        </Card>
      </div>


    );
  }
}

export default BrickViewPage;
