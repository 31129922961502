import React, { Component } from 'react';

// import { API } from "aws-amplify";
// import { Auth } from "aws-amplify";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Input } from 'antd';
// import { Card, Button } from 'antd';
import { PageHeader} from 'antd';
import LoginPageComponent from '../../components/pages/pages/LoginPageComponent';

import { FormattedMessage } from 'react-intl';
//import { injectIntl } from 'react-intl';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // email: "andre@pimwa.com",
      email: "",
      // password: "Passw0rd!",
      password: "",
    }
  }


  // handleChange = event => {
  //   // console.log(event);
  //   this.setState({
  //     [event.target.id]: event.target.value
  //   });
  // };


  // handleSubmit = async event => {
  //   // console.log(event);
  //   // console.log(this.state.email)
  //   // console.log(this.state.password);
  //   try {
  //     let auth = await Auth.signIn(this.state.email.toLowerCase(), this.state.password);
  //     console.log(auth);
  //     this.props.userHasAuthenticated(true);

  //   } catch (e) {
  //     // alert(e.message);
  //     console.log(e);
  //   }
  // }

  render() {
    // console.log(this.state);
    return (

      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title={<FormattedMessage id="login_page.main_title" />}
          // subTitle="This is a subtitle"
        />
        <LoginPageComponent
          isAuthenticated={this.props.isAuthenticated}
          userHasAuthenticated={this.props.userHasAuthenticated}
        />
      </div>
    );
  }
}

export default LoginPage;
