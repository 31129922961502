
import React, { Component } from 'react';
import { connect } from "react-redux";

import {
  updateNewStaffCreation

} from "../../../../../redux/actions/staffs";

// import { API, Auth } from "aws-amplify";

//import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card, Button } from 'antd';
// import { notification } from 'antd';
import { Form, Input } from 'antd';

//for #32 File upload Dragger
import { Storage } from "aws-amplify";
import uuid from "uuid";

import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
const { Dragger } = Upload;

var moment = require("moment");

// const openNotificationWithIcon = (type, header, message) => {
//   notification[type]({
//     message: header,
//     description: message,
//   });
// };

class NewStaffWizard01 extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }

    this.photo_uploader_props = {
      customRequest: ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials
      }) => {
        // console.log(file);
        const s3_filename = `photo-${moment().format("YYYYMMDD-HHmmss")}-${uuid.v1()}`;

        Storage.put(s3_filename, file, {
          contentType: file.type,
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            onProgress(
              {
                percent: Math.round((progress.loaded / progress.total) * 100)
              },
              file
            );
          }
        })
          .then((result) => {
            console.log(result);
            onSuccess(result);
            this.setState({ photo_file_id: result.key });
            this.props.updateNewStaffCreation("staff_photo_file_id", result.key); //test
          })
          .catch((err) => {
            console.log(err)
            this.setState({
              photo_upload_error: err
            })
          });
      }
    };
  }


  async componentDidMount() {
    
  }

  async componentWillReceiveProps(nextProps, nextContext) {

  }



  handleChange = (event) => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
    this.props.updateNewStaffCreation(event.target.id, event.target.value);
  };



  render() {
    // console.log(this.state);
    if (!this.props.staffs || !this.props.staffs.new_staff_data) return null;

    return (

      <React.Fragment>
        <Form onSubmit={this.handleSubmit}>

          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item label="First Name" style={{ marginBottom: 0 }}>
                    <Input
                      id="first_name"
                      value={this.props.staffs.new_staff_data.first_name}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Middle Name(s)" style={{ marginBottom: 0 }}>
                    <Input
                      id="middle_name"
                      value={this.props.staffs.new_staff_data.middle_name}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Last Name" >
                <Input
                  id="last_name"
                  value={this.props.staffs.new_staff_data.last_name}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label="E-mail">
                <Input
                  id="email"
                  value={this.props.staffs.new_staff_data.email}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item
                  label="Photo Upload"
                  help={this.state.photo_upload_error ? this.state.photo_upload_error : ""}
                >
                  <Dragger
                    {...this.photo_uploader_props}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                      Support for a single image file only.
                </p>
                  </Dragger>
                </Form.Item>
            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    staffs: state.staffs,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNewStaffCreation: (key, value) => {
      dispatch(updateNewStaffCreation(key, value))
    }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const NewStaffWizard01Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewStaffWizard01);

export default NewStaffWizard01Container;
