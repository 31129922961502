
import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ReactPlayer from 'react-player';

import { API } from "aws-amplify";
// import { Auth } from "aws-amplify";

//import { Layout } from 'antd';
import { Row, Col } from 'antd';
//import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input } from 'antd';
import { Typography } from 'antd';
import { Select, Radio } from 'antd';
import { DatePicker } from 'antd';
// import styles from '../../assets/css/react-player.css';
import { get_url } from "../../library/aws_library";
import { Checkbox } from 'antd';

//for #32 File upload Dragger
import { Storage } from "aws-amplify";
import uuid from "uuid";

import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import ImgCrop from 'antd-img-crop';

const { Dragger } = Upload;

var moment = require("moment");
const { Title } = Typography;
const { Option } = Select;

const { TextArea } = Input;

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class VideoViewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video: {
        id: "",
        record_created: "",
        file_id: "",
        description: ""
      },
      tags: [],
      video_category: [],
      public_date: "",
      //trainer_staff_id: "",
      get_ddl_trainers_list: [],
      file_id: "",
      poster_file_id: "",
      get_tag_list: [],
      equipment_required: "",
      favourite: ""
    }

    this.uploader_props = {
      customRequest: ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials
      }) => {
        // console.log(file);
        const s3_filename = `video-${moment().format("YYYYMMDD-HHmmss")}-${uuid.v1()}`;

        Storage.put(s3_filename, file, {
          contentType: file.type,
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            onProgress(
              {
                percent: Math.round((progress.loaded / progress.total) * 100)
              },
              file
            );
          }
        })
          .then(async (result) => {
            console.log(result);
            onSuccess(result);
            this.setState({ file_id: result.key });
            let video_url = await get_url(result.key);
            this.setState({ video_url: video_url });
          })
          .catch((err) => {
            console.log(err)
            this.setState({
              upload_error: err
            })
          });
      }
    };

    this.poster_uploader_props = {
      customRequest: ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials
      }) => {
        // console.log(file);
        const s3_filename = `poster-${moment().format("YYYYMMDD-HHmmss")}-${uuid.v1()}`;

        Storage.put(s3_filename, file, {
          contentType: file.type,
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            onProgress(
              {
                percent: Math.round((progress.loaded / progress.total) * 100)
              },
              file
            );
          }
        })
          .then(async (result) => {
            console.log(result);
            onSuccess(result);
            this.setState({ poster_file_id: result.key });
            let poster_url = await get_url(result.key);
            this.setState({ poster_url: poster_url });

          })
          .catch((err) => {
            console.log(err)
            this.setState({
              poster_upload_error: err
            })
          });
      }
    };
  }


  async componentDidMount() {
    const video = await this.getVideoRecord(
      this.props.video_id
    );
    console.log(video)
    // this.setState({
    //   video: video
    // })

    //API to get tags list
    await API.get("tags", `/list`).then(
      (response) => {
        console.log(response.payload)
        this.setState({
          get_tag_list: response.payload
        })
      })
      .catch(err => {
        console.log(err);
      });

    await API.get("video", `/list-video-category`).then(
      (response) => {
        console.log(response)

        //API to get get_ddl_trainers_list
        API.get("staff", `/get-ddl-trainers`).then(
          (response) => {
            console.log(response)

            this.setState({
              get_ddl_trainers_list: response.payload,
              // video_category: [
              //   {id:1, full_name:"abc"},
              //   {id:2, full_name:"def"}
              // ]
            })

          }).catch(err => {
            console.log(err);
          });

        //#86 API to show video statistics
        API.get("video", `/list/${this.props.video_id}`).then(
          (response) => {
            // console.log(response.payload[0].count)

            this.setState({
              complete_count: response.payload[0].count,
            })

          }).catch(err => {
            console.log(err);
          });

        API.get("video", `/list-favourite/${this.props.video_id}`).then(
          (response) => {
            // console.log(response.payload[0].count)

            this.setState({
              favourite_count: response.payload[0].count,
            })

          }).catch(err => {
            console.log(err);
          });


        this.setState({
          video_category: response.payload,
          // video_category: [
          //   {id:1, full_name:"abc"},
          //   {id:2, full_name:"def"}
          // ]
          isLoading: true
        })

      }).catch(err => {
        console.log(err);
      });

    //#113
    if (!this.state.category_sub_id) {
      await API.get("video-categories", `/main/list`).then(
        response => {
          // console.log(response.payload);
          this.setState({
            data_main_categories: response.payload,
            data_sub_categories: [
              {
                'id': 1,
                'file_by': ""
              }
            ]
          })
        }
      );
    } else {

      await API.get("video-categories", `/main/list`).then(
        response => {
          console.log(response.payload);
          this.setState({
            data_main_categories: response.payload,
          })

          API.get("video-categories", `/sub-list/${this.state.category_main_id}`).then(
            response => {
              console.log(response.payload);
              this.setState({
                data_sub_categories: response.payload
              })
            }
          );
        }
      );


    }
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    // console.log(nextProps)
    // if (nextProps.app_state.current_user_id && nextProps.app_state.current_user_aws_cognito_id) {
    //   const user = await this.getUserProfile(
    //     nextProps.app_state.current_user_aws_cognito_id
    //   );
    //   // console.log(user)
    //   this.setState({
    //     ...user.payload
    //   })
    // }
  }


  async getVideoRecord(video_id) {

    API.get("video", `/get-by-id/${this.props.video_id}`).then(
      async (response) => {
        console.log(response)
        if (response.payload.file_id) {
          let video_url = await get_url(response.payload.file_id);
          this.setState({ video_url })
        }
        if (response.payload.poster_file_id) {
          let poster_url = await get_url(response.payload.poster_file_id);
          this.setState({ poster_url })
        }

        this.setState({
          video: response.payload,
          // video_category_id: response.payload.video_category_id,
          public_date: response.payload.public_date,
          trainer_staff_id: response.payload.trainer_staff_id,
          file_id: response.payload.file_id,
          poster_file_id: response.payload.poster_file_id,
          long_description: response.payload.long_description,
          free_content: response.payload.free_content,
          tags: response.payload.tags,
          category_main_id: response.payload.category_main_id,
          category_sub_id: response.payload.category_sub_id,
          equipment_required: response.payload.equipment_required,
          favourite: response.payload.favourite
        })

      }).catch(err => {
        console.log(err);
      });
  }

  async saveVideoRecord(video_record) {
    console.log(video_record);
    await API.put("video", `/update/${this.state.video.id}`, {
      body: video_record,
      trainer_staff_id: this.state.trainer_staff_id
    });
    openNotificationWithIcon('success', "Save Successful", "Your data has been saved successfully")
  }


  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleCheckbox = event => {
    console.log(`checked = ${event.target.checked}`);
    this.setState({
      free_content: event.target.checked
    });
  }

  // handleSelectChange_video_category = (value) => {
  //   this.setState({
  //     "video_category_id": value
  //   });
  // };

  handleSelectChange_upload_trainer = (value) => {
    this.setState({
      "trainer_staff_id": value
    });
  };

  handleSelectChange_multiple_tags = (value) => {
    this.setState({
      "tags": value
    });
  };

  onChangePublicDateTime = (date, dateString) => {
    console.log(date, dateString);
    // App.setState ({
    //   selectedDate: date
    // });
    console.log(moment(dateString).utc().format("YYYY-MM-DD HH:mm:ss"));
    //console.log(this);
    this.setState({
      public_date: moment(dateString).utc().format("YYYY-MM-DD HH:mm:ss")
    });
  }

  onRadioEquipRequiredChange = (e) => {
    // console.log(`radio checked:${e.target.value}`);
    this.setState({
      equipment_required: e.target.value
    });
  }
  onRadioFavouriteChange = (e) => {
    // console.log(`radio checked:${e.target.value}`);
    this.setState({
      favourite: e.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    console.log(event)
    this.setState({ isLoading: true });

    const {
      description,
      // video_category_id,
      public_date,
      trainer_staff_id,
      long_description,
      free_content,
      tags,
      category_main_id,
      category_sub_id,
      equipment_required,
      favourite
    } = this.state;

    let file_id = "";
    let poster_file_id = "";

    if (this.state.file_id !== "") {
      file_id = this.state.file_id
    }

    if (this.state.poster_file_id !== "") {
      poster_file_id = this.state.poster_file_id
    }

    try {
      await this.saveVideoRecord({
        description: description,
        // video_category_id: video_category_id,
        public_date: public_date,
        trainer_staff_id: trainer_staff_id,
        file_id: file_id,
        poster_file_id: poster_file_id,
        long_description: long_description,
        free_content: free_content,
        tags: tags,
        category_main_id: category_main_id,
        category_sub_id: category_sub_id,
        equipment_required: equipment_required,
        favourite
      });
      this.props.history.push(`/video/list`);
    } catch (e) {
      //alert(e);
      console.log(e);
      //     try {
      //       await this.saveVideoRecord({
      //         // aws_cognito_id: aws_cognito_id,
      //         // first_name: first_name,
      //         // middle_name: middle_name,
      //         // last_name: last_name,
      //       });
      //       // this.props.history.push("/");
      //       // this.props.reload_user(this.state.aws_cognito_id);
      //     } catch (e) {
      //       // alert(e);
      //       openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      //       // this.setState({ isLoading: false });
      //     }

      openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving, please type in your description")
      this.setState({ isLoading: false });
    }
  };

  handleDeleteVideoButton = (event) => {
    // alert('remove this video');
    const {
      description,
      // video_category_id,
      public_date,
      trainer_staff_id,
      long_description,
      free_content,
      poster_file_id,
      category_main_id,
      category_sub_id,
      favourite
    } = this.state;
    // I'm not sure why were are doing the following logic on file_id and poster_id ???
    // let poster_file_id = "";
    // if (this.state.poster_file_id !== "") {
    //   poster_file_id = this.state.poster_file_id
    // }

    try {
      this.saveVideoRecord({
        description: description,
        // video_category_id: video_category_id,
        public_date: public_date,
        trainer_staff_id: trainer_staff_id,
        file_id: null,
        poster_file_id: poster_file_id,
        long_description: long_description,
        free_content: free_content,
        category_main_id: category_main_id,
        category_sub_id: category_sub_id,
        favourite
      })
    } catch (e) {
      console.log(e)
    } finally {

      // //refresh the data
      // this.getVideoRecord(`${this.state.video.id}`);

      this.setState({
        file_id: null,
        video_url: null
      });

      // this.props.history.push(`/video/view/${this.state.video.id}`);
      //video/view/
      //window.location.reload(true);
    };

  }

  handleDeletePosterButton = (event) => {
    // alert('remove this poster');

    const {
      description,
      // video_category_id,
      public_date,
      trainer_staff_id,
      long_description,
      free_content,
      file_id,
      category_main_id,
      category_sub_id,
      favourite
    } = this.state;

    try {
      this.saveVideoRecord({
        poster_file_id: null,
        description: description,
        // video_category_id: video_category_id,
        public_date: public_date,
        trainer_staff_id: trainer_staff_id,
        file_id: file_id,
        long_description: long_description,
        free_content: free_content,
        category_main_id: category_main_id,
        category_sub_id: category_sub_id,
        favourite
      })

    } catch (e) {
      console.log(e)
    } finally {
      this.setState({
        poster_file_id: null,
        poster_url: null
      });

      //refresh the data
      this.getVideoRecord(`${this.state.video.id}`);
      this.props.history.push(`/video/view/${this.state.video.id}`);
      //video/view/
      //window.location.reload(true);
    };

  }

  handleSelectChange_main_category_id = (id) => {
    this.setState({
      category_main_id: id
    });

    API.get("video-categories", `/sub-list/${id}`)
      .then((response) => {
        console.log(response)
        this.setState({
          data_sub_categories: response.payload,
        })
      }).catch(err => {
        console.log(err);
      });

    API.get("video-categories", `/main/id/${id}`)
      .then((response) => {
        console.log(response)
        this.setState({
          main_category: response.payload,
        })
      }).catch(err => {
        console.log(err);
      });
  };

  handleSelectChange_sub_category_id = (id) => {
    this.setState({
      category_sub_id: id
    });
    API.get("video-categories", `/sub/id/${id}`)
      .then((response) => {
        console.log(response)
        this.setState({
          sub_category: response.payload,
        })
      }).catch(err => {
        console.log(err);
      });
  };

  render() {
    // console.log(this);
    console.log(this.state);
    // console.log(this.props);

    // var video_categories_list_select_options = this.state.video_category.map(d => <Option key={d.id} >{d.nickname}</Option>);
    var get_ddl_trainers_list_select_options = this.state.get_ddl_trainers_list.map(d => <Option key={d.id} >{d.nickname}</Option>);
    var get_tag_list_select_options = this.state.get_tag_list.map(d => <Option key={d.id} >{d.nickname}</Option>);

    if (!this.state.data_main_categories) return null;
    const main_categories_option = this.state.data_main_categories.map(d => <Option key={d.id} >{d.file_by}</Option>);

    if (!this.state.data_sub_categories) return null;
    const sub_categories_option = this.state.data_sub_categories.map(d => <Option key={d.id} >{d.file_by}</Option>);

    const radio_options_yes_no = [
      { label: "Yes", value: 1 },
      { label: "No", value: 0 },
    ]

    return (
      <React.Fragment>

        <Form layout="vertical" onSubmit={this.handleSubmit}>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Title level={4}> Video details </Title>
              <Row gutter={[96, 24]}>
                <Col xs={7} md={7}>
                  <Form.Item
                    label="ID"
                  >
                    <Input
                      id="id"
                      disabled
                      value={this.state.video.id}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={17} md={17}>
                  <Form.Item
                    label="Record created"
                  >
                    <Input
                      id="record_created"
                      // disabled
                      value={this.state.video.record_created}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* <Form.Item
                label="Video Category ID"
              >
                <Select
                  id="video_category_id"
                  showSearch
                  value={
                    typeof this.state.video_category_id === "number"
                      ? this.state.video_category_id.toString()
                      : this.state.video_category_id
                  }
                  placeholder="Select Category"
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  }
                  onChange={this.handleSelectChange_video_category}
                >
                  {
                    video_categories_list_select_options
                  }
                </Select>
              </Form.Item> */}

              <Form.Item label="Main Category">
                <Select
                  id="category_main_id"
                  name="category_main_id"
                  showSearch
                  value={
                    typeof this.state.category_main_id === "number"
                      ? this.state.category_main_id.toString()
                      : (this.state.category_main_id === " ")
                        ? "Select Main Category"
                        : this.state.category_main_id
                  }
                  placeholder="Select Category"
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                  onChange={this.handleSelectChange_main_category_id}
                >
                  {main_categories_option}
                </Select>
              </Form.Item>

              <Form.Item label="Sub Category">
                <Select
                  id="category_sub_id"
                  name="category_sub_id"
                  showSearch
                  value={
                    typeof this.state.category_sub_id === "number"
                      ? this.state.category_sub_id.toString()
                      : (this.state.category_sub_id === " ")
                        ? "Select Sub Category"
                        : this.state.category_sub_id
                  }
                  placeholder="Select Category"
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                  onChange={this.handleSelectChange_sub_category_id}
                >
                  {sub_categories_option}
                </Select>
              </Form.Item>

              <Form.Item
                label="Upload Trainer"
              >
                <Select
                  id="trainer_staff_id"
                  mode="multiple"
                  showSearch
                  value={
                    typeof this.state.trainer_staff_id === "number"
                      ? this.state.trainer_staff_id.toString()
                      : (this.state.trainer_staff_id === " ")
                        ? "Select Trainer"
                        : this.state.trainer_staff_id
                  }
                  placeholder="Select Trainer"
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  }
                  onChange={this.handleSelectChange_upload_trainer}
                >
                  {
                    get_ddl_trainers_list_select_options
                  }
                </Select>
              </Form.Item>
              {/*<Form.Item
                label="File ID"
              >
                <Input
                  id="file_id"
                  disabled
                  value={this.state.file_id} //this.state.video.file_id
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Poster File ID"
              >
                <Input
                  id="poster_file_id"
                  disabled
                  value={this.state.poster_file_id} //this.state.video.poster_file_id
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
                </Form.Item>*/}
              <Row gutter={[96, 24]}>
                <Col xs={13}>
                  <Form.Item label="Public DateTime">
                    <React.Fragment>
                      {<DatePicker
                        placeholder={moment(this.state.public_date).format('YYYY-MM-DD HH:mm:ss')}
                        showTime={{ format: 'HH:mm' }}
                        onChange={this.onChangePublicDateTime}
                      />}
                    </React.Fragment>
                  </Form.Item>
                </Col>
                <Col xs={11}>
                  <Form.Item label="Free Content" >
                    <Checkbox checked={this.state.free_content} onChange={this.handleCheckbox}>Is a free content</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Description"
              >
                <Input
                  id="description"
                  //disabled
                  value={
                    this.state.description
                      ? this.state.description
                      : this.state.video.description
                  }
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Long Description"
              >
                <TextArea
                  id="long_description"
                  value={
                    this.state.long_description
                  }
                  rows={4}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <React.Fragment>
                {/* #99 Add tags from view / edit video page */}

                <Form.Item
                  label="Tag"
                >
                  <Select
                    mode="multiple"
                    value={this.state.tags}
                    style={{ width: '100%' }}
                    placeholder="Select Tag"
                    showArrow={true}
                    // optionFilterProp="children"
                    // filterOption={(input, option) => {
                    //   return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                    // }
                    onChange={this.handleSelectChange_multiple_tags}
                  >
                    {get_tag_list_select_options}
                  </Select>
                </Form.Item>
              </React.Fragment>
              <Form.Item
                label="Complete Count"
              >
                <p>{this.state.complete_count}</p>
              </Form.Item>
              <Form.Item
                label="Favourite Count"
              >
                <p>{this.state.favourite_count}</p>
              </Form.Item>
            </Col>
            <Col xs={12} md={12}>

              {this.state.video_url &&
                <React.Fragment>
                  <Form.Item
                    label="Video"
                  >
                    <div className='player-wrapper'>
                      <ReactPlayer url={this.state.video_url} //'https://tom-test-1234.s3-ap-southeast-1.amazonaws.com/Saturday+1.mp4'
                        //playing
                        width='100%'
                        height='100%'
                        controls
                      />
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      onClick={() => this.handleDeleteVideoButton(this.state.video.file_id)}
                    >
                      Remove
                  </Button>
                  </Form.Item>
                </React.Fragment>
              }

              {!this.state.video_url &&
                <Form.Item
                  label="Video Upload"
                  help={this.state.upload_error ? this.state.upload_error : ""}
                >
                  <Dragger
                    disabled={!this.state.isLoading}
                    {...this.uploader_props}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                      Support for a single video file only.
                </p>
                  </Dragger>
                </Form.Item>
              }

              {this.state.poster_url &&
                <React.Fragment>
                  <Form.Item
                    label="Poster"
                  >
                    <img src={this.state.poster_url} style={{ height: 180, maxWidth: 320 }}></img>
                  </Form.Item>
                  <Form.Item>

                    <Button
                      type="primary"
                      onClick={() => this.handleDeletePosterButton(this.state.video.poster_file_id)}
                    >
                      Remove
                    </Button>
                  </Form.Item>
                </React.Fragment>
              }

              {!this.state.poster_url &&
                <Form.Item
                  label="Poster Upload"
                  help={this.state.poster_upload_error ? this.state.poster_upload_error : ""}
                >
                  <ImgCrop aspect="1.33" grid>
                    <Dragger
                      disabled={!this.state.isLoading}
                      {...this.poster_uploader_props}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">
                        Support for a single image file only.
                      </p>
                    </Dragger>
                  </ImgCrop>
                </Form.Item>
              }
              <Form.Item
                label="Equipment Required"
              >
                <Radio.Group
                  onChange={this.onRadioEquipRequiredChange}
                  value={this.state.equipment_required}
                  options={radio_options_yes_no}
                  optionType="button"
                  buttonStyle="solid"
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label="Favourite"
              >
                <Radio.Group
                  onChange={this.onRadioFavouriteChange}
                  value={this.state.favourite}
                  options={radio_options_yes_no}
                  optionType="button"
                  buttonStyle="solid"
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>

            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={this.handleSubmit}
              >
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>
      </React.Fragment >
    );
  }
}


// export default UserSummary;



const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const VideoViewComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoViewComponent);

export default withRouter(VideoViewComponentContainer);
