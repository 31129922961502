import React, { Component } from 'react';

// import { API, Auth } from "aws-amplify";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import { Card } from 'antd';
import { PageHeader } from 'antd'; 
// import { Button } from 'antd';

import ClientNewPageComponentContainer from '../../../components/pages/dashboard/clients/ClientNewPageComponent';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class ClientNewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    // console.log(this.state);
    return (

      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="Create New Client"
        />
        <Card
          style={{ minHeight: 360 }}
          title="Create a New Client Record via these simple steps"
        >
          <div style={{ paddingTop: 24 }}>
            <ClientNewPageComponentContainer />
          </div>
        </Card>
      </div>


    );
  }
}

export default ClientNewPage;
