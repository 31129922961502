
import React, { Component } from 'react';
import { Table } from 'antd';

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";
import { NavLink } from "react-router-dom";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Divider } from 'antd';
import { Form, Input, Button, Tag } from 'antd';
import { Row, Col } from 'antd';


import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

const moment = require("moment");
const { TextArea } = Input;

const expanded_row_layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class ExerciseListPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: []
    }
    this.columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        render: text => <a>{text.toString().padStart(5, '0')}</a>,
      },
      {
        title: 'Record Created',
        dataIndex: 'record_created',
        // ...this.getColumnSearchProps('record_created'),
        render: (text, record) => (
          <span>
            {`${moment(record.record_created).format("DD-MM-YYYY")}`}
          </span>
        ),
      },
      {
        title: 'Description',
        dataIndex: 'short_description',
        ...this.getColumnSearchProps('short_description'),
      },
      {
        title: 'Equipment Required',
        dataIndex: 'equipment_required',
        // ...this.getColumnSearchProps('exercise_required'),
        filters: [
          { text: 'Yes', value: 1 },
          { text: 'No', value: 0 }
        ],
        onFilter: (value, record) => record.equipment_required === value,
        render: data_item => {
          switch (data_item) {
            case 1:
              return <Tag color={'green'} style={{ width: 100, textAlign: "center" }} key={data_item}> Yes </Tag>
            default:
              return <Tag color={'orange'} style={{ width: 100, textAlign: "center" }} key={data_item}> No </Tag>
          }
        }
      },
      {
        title: 'Free Content',
        dataIndex: 'free_content',
        //...this.getColumnSearchProps('vip'),
        filters: [
          { text: 'Free Content', value: 1 },
          { text: 'Not a Free', value: 0 }
        ],
        onFilter: (value, record) => record.vip === value,
        render: data_item => {
          switch (data_item) {
            case 1:
              return <Tag color={'green'} style={{ width: 100, textAlign: "center" }} key={data_item}> Free Content </Tag>
            default:
              return ""
          }
        }
      },
      {
        title: 'Deleted',
        dataIndex: 'deleted',
        //...this.getColumnSearchProps('vip'),
        filters: [
          { text: 'Deleted', value: 1 },
          { text: 'Not Deleted', value: 0 }
        ],
        onFilter: (value, record) => record.deleted === value,
        render: data_item => {
          switch (data_item) {
            case 1:
              return <Tag color={'volcano'} style={{ width: 100, textAlign: "center" }} key={data_item}> Deleted </Tag>
            default:
              return ""
          }
        }
      },
      {
        title: 'Actions',
        key: 'action',
        render: (text, record) => (
          <span>
            <NavLink to={`/exercises/view/${record.id}`} >View</NavLink>
            {/* <Divider type="vertical" />
            <a>Delete</a> */}
          </span>
        ),
      },
    ];

  }

  componentDidMount() {
    API.get("exercises", `/list`)
      .then(response => {
        // console.log(response);
        // console.log(response.payload);

        let dataSource = [];
        for (let i = 0; i < response.payload.length; i++) {
          dataSource.push({
            key: response.payload[i].id,
            id: response.payload[i].id,
            record_created: response.payload[i].record_created,
            short_description: response.payload[i].short_description,
            long_description: response.payload[i].long_description,
            free_content: response.payload[i].free_content,
            exercise_category_id: response.payload[i].exercise_category_id,
            category_nickname: response.payload[i].category_nickname,
            equipment_required: response.payload[i].equipment,
            deleted: response.payload[i].deleted
          });
        }
        console.log(dataSource);
        this.setState({
          dataSource: dataSource
        })
      })
      .catch(error => {
        console.log(error);
      });
  }


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    console.log(this.state);
    // console.log(this.dataSource);
    // console.log(this.props);
    return (
      <React.Fragment>
        <Table
          columns={this.columns}
          dataSource={this.state.dataSource}
          pagination={{
            pageSizeOptions: ['10', '20', '30'],
            showSizeChanger: true
          }}
          expandedRowRender={record => {
            // console.log(record);
            return (<React.Fragment>
              <Row gutter={[96, 24]}>
                <Row>
                </Row>
                <Col xs={12}>
                  <Form {...expanded_row_layout} onSubmit={this.handleSubmit}>
                    <Form.Item label="Short Description" >

                      <TextArea
                        value={record.description_short}
                        disabled
                        autoSize={{ minRows: 2, maxRows: 8 }}
                      />
                    </Form.Item>
                    <Form.Item label="Long Description" >
                      <TextArea
                        value={record.description_long}
                        disabled
                        autoSize={{ minRows: 2, maxRows: 8 }}
                      />
                    </Form.Item>
                  </Form>
                </Col>

              </Row>
            </React.Fragment>)
          }}
        />
      </React.Fragment>
    );
  }
}

export default ExerciseListPageComponent;
