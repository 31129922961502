import React, { Component } from 'react';
import withStyles from 'react-jss'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  startNewClientCreation,
  updateNewClientCreation
} from "../../../../redux/actions/clients";
import {
  create_client_cognito_account,
  create_stripe_customer_id
} from "../../../../library/clients";

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";
import { Row, Col } from 'antd';
// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Steps } from 'antd';
// import { message } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input } from 'antd';

// import NewClientWizard01Container from "./new_client_wizard/NewClientWizard01";
// import NewClientWizard02Container from "./new_client_wizard/NewClientWizard02";
// import NewClientWizard03Container from "./new_client_wizard/NewClientWizard03";
// var generator = require('generate-password');

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: "right"
  },
};


const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

// const { Step } = Steps;

// const steps = [
//   {
//     title: 'Client Details',
//     content: <NewClientWizard01Container />,
//   },
//   {
//     title: 'Contact Details',
//     content: <NewClientWizard02Container />,
//   },
//   {
//     title: 'Confirm',
//     content: <NewClientWizard03Container />,
//   },
// ];

class ExerciseNewPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_step: 0,
    }
  }

  componentDidMount() {
    // this.props.startNewClientCreation();
  }

  handleChange = (event) => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
    // this.props.updateNewClientCreation(event.target.id, event.target.value);

    // this.validate_form(event);
  };

  handleSubmit = async (event) => {
    console.log(this.props.clients.new_client_data)
    API.post("exercises", `/create`, {
      body: {
        short_description: this.state.short_description
      }
    })
      .then(async response => {
        console.log(response)
        // var password = generator.generate({
        //   length: 9, //10
        //   numbers: true
        // });
        // password = password + "8";
        // await create_client_cognito_account(response.payload.insertId, this.props.clients.new_client_data.email, password);
        // await create_stripe_customer_id(response.payload.insertId, this.props.clients.new_client_data.first_name, this.props.clients.new_client_data.last_name, this.props.clients.new_client_data.email)
        openNotificationWithIcon('success', "Record Created", "New exercise has been successfully created.")
        return response;
      })
      .then(response => {
        console.log(response)
        // openNotificationWithIcon('success', "Record Created", "New client has been successfully created.")
        this.props.history.push(`/exercises/view/${response.payload.insertId}`);
      })
      .catch(error => {
        console.log(error);
      });
  }


  render() {
    const { classes } = this.props;
    const { current_step } = this.state;
    if (!this.props.clients && !this.props.clients.new_client_data) return null;

    return (
      <React.Fragment>
        <Form layout="vertical" onSubmit={this.handleSubmit}>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Form.Item label="Short Description">
                <Input
                  id="short_description"
                  value={this.state.short_description}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!this.state.short_description}
                onClick={this.handleSubmit}
              >
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>
        {/* <Steps current={current_step}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className={classes.stepsContent}>
          {steps[current_step].content}
        </div>
        <div className={classes.stepsAction}>
          {current_step > 0 && (
            <Button style={{ marginLeft: 8 }} onClick={() => this.prev_step()}>
              Previous
            </Button>
          )}
          {current_step < steps.length - 1 && (
            <Button type="primary" onClick={() => this.next_step()}>
              Next
            </Button>
          )}
          {current_step === steps.length - 1 && (
            <Button type="primary" onClick={this.handleSubmit}>
              Done
            </Button>
          )}
        </div> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startNewClientCreation: () => {
      dispatch(startNewClientCreation())
    },
    updateNewClientCreation: (key, value) => {
      dispatch(updateNewClientCreation(key, value))
    }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const ExerciseNewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(ExerciseNewPageComponent)));

export default ExerciseNewPageComponentContainer;
