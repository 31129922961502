// import { API } from "aws-amplify";

export const STAFFS_START_NEW_STAFF_CREATION = "STAFFS_START_NEW_STAFF_CREATION";
export const STAFFS_UPDATE_NEW_STAFF_CREATION = "STAFFS_UPDATE_NEW_STAFF_CREATION";


export function startNewStaffCreation() {
  return {
    type: STAFFS_START_NEW_STAFF_CREATION
  };
}

export function updateNewStaffCreation(key, value) {
  // console.log(key, value);
  return {
    type: STAFFS_UPDATE_NEW_STAFF_CREATION,
    value: { key, value }
  };
}
