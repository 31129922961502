import { API, Auth } from "aws-amplify";
import Amplify from "aws-amplify";
import config from "../config/aws_config";

//== Code from GAT CRM ===
// export function get_client_status_value (data_client_status_list, id)  {
//     if (id > 0) {
//         for (var i = 0; i < data_client_status_list.length; i++) {
//             if (id === data_client_status_list[i].id) {
//                 return data_client_status_list[i].nickname;
//             }
//         }
//     } else {
//         return "";
//     }
// }

// export function get_client_status_value(data_client_status_list, id) {
//   if (id > 0) {
//     for (var i = 0; i < data_client_status_list.length; i++) {
//       if (id === data_client_status_list[i].id) {
//         return data_client_status_list[i].nickname;
//       }
//     }
//   } else {
//     return "";
//   }
// }
// export function get_client_value(data_client_list, id) {
//   if (id > 0) {
//     for (var i = 0; i < data_client_list.length; i++) {
//       if (id === data_client_list[i].id) {
//         return data_client_list[i].nickname;
//       }
//     }
//   } else {
//     return "NO CLIENT RECORD";
//   }
// }

export async function create_client_cognito_account(id, username, password) {
  // console.log(id);
  // console.log(username);
  // return async function(dispatch) {
  //   dispatch(createCognitoAccountStart());

  try {

    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: config.cognito_clients.REGION,
        userPoolId: config.cognito_clients.USER_POOL_ID,
        identityPoolId: config.cognito_clients.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito_clients.APP_CLIENT_ID
      }
    });

    const newUser = await Auth.signUp({
      username: username,
      password: password
    });

    console.log(newUser);

    await update_client_in_database(id, {
      aws_cognito_id: newUser.userSub,
      // portal_account_created: "1"
    })

    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: config.cognito_staff.REGION,
        userPoolId: config.cognito_staff.USER_POOL_ID,
        identityPoolId: config.cognito_staff.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito_staff.APP_CLIENT_ID
      }
    });
    API.post("email", `/client/new-portal-account`, {
      body: {
        // api_key: "92490f7c-7676-447a-8b56-91431512b3d8",
        id: id,
        username: username,
        password: password,
        // language_id: language_id
      }
    })
      .then(response => {
        // console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
    API.post("email", `/interaction`, {
      body: {
        interaction: "Create New Client Portal Record",
        data: {
          id: id,
          username: username,
          password: password,
          aws_cognito_id: newUser.userSub
        },
        result: "portal record created successfully"
      }
    })
      .then(response => {
        // console.log(response);
      })
      .catch(error => {
        console.log(error);
      });

    // dispatch(createCognitoAccountSuccess());
  } catch (e) {
    console.log(e);
    // if (e.code === "UsernameExistsException") {
    //   await Auth.resendSignUp(data.email);
    // }
  }
};

async function update_client_in_database(id, client_record) {
  console.log(id)
  console.log(client_record);
  return API.put("clients", `/update/${id}`, {
    body: client_record
  });
  // return API.put(
  //     "clients",
  //     `/update/id/${cl_id}`,
  //     {
  //         body: client_detail
  //     }
  // );
}

export async function create_stripe_customer_id(id, first_name, last_name, email) {
  // const {
  //   email,
  //   first_name,
  //   last_name
  // } = this.state;
  let name = `${first_name} ${last_name}`;

  API.post("stripe", `/customer/create/${id}`, {
    body: {
      email,
      name
    }
  })
    .then(response => {
      console.log(response)
      return response.payload.stripe_result.id
    })
    .catch(error => {
      console.log(error);
      API.post("stripe", `/customer/create/${id}`, {
        body: {
          email,
          name
        }
      })
        .then(response => {
          console.log(response)
          return response.payload.stripe_result.id
        })
        .catch(error => {
          console.log(error);
        });
    });

}
