
import React, { Component } from 'react';
import { connect } from "react-redux";

import { API } from "aws-amplify";
// import { Auth } from "aws-amplify";

// import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input } from 'antd';
import { Select } from 'antd';
import { Area } from '@ant-design/charts';
import { Card, Avatar } from 'antd';
var dayjs = require("dayjs");

const { Option } = Select;
const { Meta } = Card;

class ClientStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }


  async componentDidMount() {
    API.get("video", `/client/${this.props.client_id}`)
      .then(async response => {
        console.log(response)
        let data = []
        for (let i = 60; i >= 0; i--) {
          let view_date = dayjs().startOf('day').subtract(i, 'day')
          let view_count = 0;
          for (let j = 0; j < response.payload.length; j++) {
            let record = response.payload[j];
            if (record.record_datetime) {
              if (dayjs(record.record_datetime).isSame(view_date, 'day')) {
                view_count++;
              } 
            } else {
              if (dayjs(record.record_date).isSame(view_date, 'day')) {
                view_count++;
              } 
            }
          }
          data.push({
            day: view_date.format("DD-MM-YYYY"),
            views: view_count
          })
        }
        this.setState({
          data
        })
      })
      .catch((err) => {
        console.log(err);
      })
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    // console.log(nextProps)
    // if (nextProps.app_state.current_user_id && nextProps.app_state.current_user_aws_cognito_id) {
    //   const user = await this.getUserProfile(
    //     nextProps.app_state.current_user_aws_cognito_id
    //   );
    //   // console.log(user)
    //   this.setState({
    //     ...user.payload
    //   })
    // }
  }

  render() {
    console.log(this.state.data)
    const config = {
      data: this.state.data,
      title: {
        visible: true,
        text: 'Your Stats',
      },
      xField: 'day',
      yField: 'views',
      color: ['#c30f48'],
      // xAxis: {
      //   type: 'dateTime',
      //   // tickCount: 60,
      // },
      // meta: {
      //   day: {
      //     alias: " "
      //   }
      // }
    };
    return (
      <React.Fragment>
        <Form layout="vertical">
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Card

                style={{ cursor: "pointer" }}
                hoverable
                cover={
                  <Area style={{ height: 300 }} {...config} />
                }
              >
                <Meta
                  title="60-day Stats"
                  description={`Your 60-day statistics for workout completions`}
                />
              </Card>


            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const ClientStatsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientStats);

export default ClientStatsContainer;
