import React, { Component } from 'react';
import withStyles from 'react-jss'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";

import { Row, Col } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input } from 'antd';

// import { Tabs } from 'antd';
// import { Upload } from 'antd';
// import { message } from 'antd';
// import { InboxOutlined } from '@ant-design/icons';
// import { DashboardOutlined, VideoCameraOutlined } from '@ant-design/icons'
// import { Select } from 'antd';

// import { loadStripe } from '@stripe/stripe-js';


// import './DatePickerSample.css';
// import { DatePicker } from 'antd';

// const { Option } = Select;
// const stripePromise = loadStripe('pk_live_1NNfbEQHqrpbF9ouaKrVFilx00Npb3yl14',
//   {
//     stripeAccount: 'acct_1GYvZVLdj3tiY8tr'
//   });

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: "right"
  },
};

// var moment = require("moment");

class ProductNewPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {


    }
  }

  componentDidMount = async () => {


  }

  validate_step_1 = () => {

    if(!!this.state.nickname_state) {

      if(this.state.nickname_state !== "success") {
        return false;
      } else if(this.state.nickname_state === "success") {
        return true;
      }

    }
    return false;
  }

  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });

    if(event.target.value.length > 0) {
      this.setState({
        nickname_state: "success"
      })
    } else {
      this.setState({
        nickname_state: "error"
      })
    };

  };


  handleSubmit = async (event) => {

    event.preventDefault();

    //console.log(this.validate_step_1());
    if(this.validate_step_1()) {
      openNotificationWithIcon('info', "Record Creation", "New tag record creation is starting.")
      //console.log(event);
      // console.log(this.state);
      //console.log(this.props.clients.new_client_data)
      const {
        // current_user_id,
        nickname,
      } = this.state;

      try {
        API.post("products", `/create/in-database`, {
          body: {
            // id: id,
            // current_user_id
            nickname: nickname,
            //...this.props.clients.new_client_data
          }
        })
        .then(response => {
          // console.log(response)
          openNotificationWithIcon('info', "Record Creation", "Creating a billing record for product.")

          API.post("stripe", `/product/create/${response.payload.insertId}`, {
            body: {
              nickname
            }
          })
            .then(response => {
              // console.log(response)
              this.setState({
                stripe_id: response.payload.database_result
              })
              return response;
            })
            .catch(error => {
              // console.log(error);
              API.post("stripe", `/product/create/${response.payload.insertId}`, {
                body: {
                  nickname
                }
              })
                .then(response => {
                  // console.log(response)
                  this.setState({
                    stripe_id: response.payload.database_result
                  })
                  return response;
                })
                .catch(error => {
                  console.log(error);
                });
            });
          return response;
        })
        .then(database_result => {
          // console.log(database_result)
          openNotificationWithIcon('success', "Record Created", "New client has been successfully created.")
          // window.location.assign(`/products/list`);
        })
        .catch(error => {
          console.log(error);
        });
      } catch (e) {
        alert(e);
        openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      }
    } else {
      //set error message #66
      this.setState({
        nickname_state :"error"
      });
    }
    this.setState({ isLoading: true });

  };

  render() {
    // const { classes } = this.props;
    // console.log(this.props.app_state.current_user_id)
    console.log(this.state);

    return (

      <React.Fragment>

        <Form layout="vertical" onSubmit={this.handleSubmit}>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Form.Item
                label="Nickname"
                validateStatus={this.state.nickname_state}
                hasFeedback
                help={this.state.nickname_state === "error" ? "Nickname is required" : ""}
              >
                <Input
                  id="nickname"
                  value={this.state.nickname}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                onClick = {this.validate_step_1}
                >
                Create New Record
              </Button>
            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {

  }
}

const ProductNewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(ProductNewPageComponent)));

export default ProductNewPageComponentContainer;
