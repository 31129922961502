
import React, { Component } from 'react';
import { connect } from "react-redux";

import {
  updateNewStaffCreation

} from "../../../../../redux/actions/staffs";

// import { API, Auth } from "aws-amplify";

//import { Layout } from 'antd';
// import { Row, Col } from 'antd';
// import { Card, Button } from 'antd';
// import { notification } from 'antd';
// import { Form, Input } from 'antd';

// const openNotificationWithIcon = (type, header, message) => {
//   notification[type]({
//     message: header,
//     description: message,
//   });
// };

class NewStaffWizard04 extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  async componentDidMount() {

  }

  async componentWillReceiveProps(nextProps, nextContext) {

  }



  handleChange = (event) => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
    this.props.updateNewStaffCreation(event.target.id, event.target.value);

  };



  render() {
    // console.log(this.state);

    return (

      <React.Fragment>
        {this.props.staffs.new_staff_data.first_name}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    staffs: state.staffs,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNewStaffCreation: (key, value) => {
      dispatch(updateNewStaffCreation(key, value))
    }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const NewStaffWizard04Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewStaffWizard04);

export default NewStaffWizard04Container;
