import React from 'react';
//import { Component } from 'react';
import { NavLink } from "react-router-dom";

// import { API, Auth } from "aws-amplify";
import { Switch, Redirect } from "react-router-dom";
// import PropTypes from "prop-types";

import { Layout, Menu, Icon } from 'antd';
//import { Breadcrumb } from 'antd';
// import { Input } from 'antd';
// import { Button } from 'antd';

import dashboardRoutes from "../routes/dashboard.jsx";
import AuthenticatedRoute from "../components/routes/AuthenticatedRoute";
import DashboardHeaderContainer from '../components/layout/dashboard/DashboardHeader.jsx';

import logo from '../assets/img/branding/fitnessxo-logo.png';
// const logo = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAYAAADeko4lAAAAEElEQVR42mNkYPhfz0BFAAB0hwGAwLqecAAAAABJRU5ErkJggg=="

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      isAuthenticated: false
    };
    // this.resizeFunction = this.resizeFunction.bind(this);
  }
  // componentDidMount() {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(this.refs.mainPanel, {
  //       suppressScrollX: true,
  //       suppressScrollY: false
  //     });
  //     document.body.style.overflow = "hidden";
  //   }
  //   window.addEventListener("resize", this.resizeFunction);
  // }
  // componentWillUnmount() {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps.destroy();
  //   }
  //   window.removeEventListener("resize", this.resizeFunction);
  // }
  // componentDidUpdate(e) {
  //   if (e.history.location.pathname !== e.location.pathname) {
  //     this.refs.mainPanel.scrollTop = 0;
  //     if (this.state.mobileOpen) {
  //       this.setState({ mobileOpen: false });
  //     }
  //   }
  // }
  // handleDrawerToggle = () => {
  //   this.setState({ mobileOpen: !this.state.mobileOpen });
  // };
  // getRoute() {
  //   return this.props.location.pathname !== "/maps/full-screen-maps";
  // }
  // sidebarMinimize() {
  //   this.setState({ miniActive: !this.state.miniActive });
  // }
  // resizeFunction() {
  //   if (window.innerWidth >= 960) {
  //     this.setState({ mobileOpen: false });
  //   }
  // }

  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  onClick = () => {
    window.location.assign("/datePickerSample");
  }

  switchRoutes() {
    return (
      <Switch>
        {dashboardRoutes.map((prop, key) => {
          if (prop.redirect)
            return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
          if (prop.collapse)
            return prop.views.map((prop, key) => {
              return (
                <AuthenticatedRoute
                  path={prop.path}
                  component={prop.component}
                  key={key}
                  props={{
                    isAuthenticated: this.props.isAuthenticated,
                    current_user: this.props.current_user,
                    reload_user: this.props.reload_user
                  }}
                />
              );
            });
          return (
            <AuthenticatedRoute
              path={prop.path}
              component={prop.component}
              key={key}
              props={{
                isAuthenticated: this.props.isAuthenticated,
                current_user: this.props.current_user,
                reload_user: this.props.reload_user
              }}
            />
          );
        })}
      </Switch>
    );
  }

  generate_sidebar = () => {
    return <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" style={{ height: "100%" }}>
      <Menu.Item key="1">
        <NavLink to="/" >
          <Icon type="user" />
          <span>Dashboard</span>
        </NavLink>
      </Menu.Item>
      <SubMenu
        key="clients_sub"
        title={
          <span>
            <Icon type="team" />
            <span>Clients</span>
          </span>
        }
      >
        <Menu.Item key="3">
          <NavLink to="/clients/new" >
            New Client
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4">
          <NavLink to="/clients/list" >
            Client List
          </NavLink>
        </Menu.Item>
        {/* <Menu.Item key="5">
          <NavLink to="/clients/view" >
            View Client
          </NavLink>
        </Menu.Item> */}
      </SubMenu>
      <SubMenu
        key="staff_sub"
        title={
          <span>
            <Icon type="team" />
            <span>Staff</span>
          </span>
        }
      >
        <Menu.Item key="6">
          <NavLink to="/staff/new" >
            New Staff Member
            </NavLink>
        </Menu.Item>
        <Menu.Item key="7">
          <NavLink to="/staff/list" >
            Staff List
          </NavLink>
        </Menu.Item>
        {/* <Menu.Item key="8">
          <NavLink to="/staff/view" >
            View Staff Member
            </NavLink>
        </Menu.Item> */}
      </SubMenu>
      <SubMenu
        key="videos_sub"
        title={
          <span>
            <Icon type="user" />
            <span>Videos</span>
          </span>
        }
      >
        <Menu.Item key="11">
          <NavLink to="/video/list" >
            <span>Video List</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="11a">
          <NavLink to="/video/list/views" >
            <span>Video Views</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="11b">
          <NavLink to="/video/list/favourites" >
            <span>Video Favourites</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="12">
          <NavLink to="/video/upload" >
            <span>Upload Video</span>
          </NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="Subscription_sub"
        title={
          <span>
            <Icon type="user" />
            <span>Subscription</span>
          </span>
        }
      >
        <Menu.Item key="13">
          <NavLink to="/subscription/list" >
            <span>Subscription List</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="14">
          <NavLink to="/subscription/new" >
            <span>New Subscription</span>
          </NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="Products_sub"
        title={
          <span>
            <Icon type="user" />
            <span>Products</span>
          </span>
        }
      >
        <Menu.Item key="15">
          <NavLink to="/products/list" >
            <span>Product List</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="16">
          <NavLink to="/products/new" >
            <span>New Product</span>
          </NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="PromoCodes_sub"
        title={
          <span>
            <Icon type="user" />
            <span>PromoCodes</span>
          </span>
        }
      >
        <Menu.Item key="17">
          <NavLink to="/promocodes/list" >
            <span>PromoCode List</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="18">
          <NavLink to="/promocodes/new" >
            <span>New PromoCode</span>
          </NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="Plans_sub"
        title={
          <span>
            <Icon type="user" />
            <span>Plans</span>
          </span>
        }
      >
        <Menu.Item key="17">
          <NavLink to="/plans/list" >
            <span>Plan List</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="18">
          <NavLink to="/plans/new" >
            <span>New Plans</span>
          </NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="Tags_sub"
        title={
          <span>
            <Icon type="user" />
            <span>Tags</span>
          </span>
        }
      >
        <Menu.Item key="19">
          <NavLink to="/tags/list" >
            <span>Tags List</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="20">
          <NavLink to="/tags/new" >
            <span>New Tags</span>
          </NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="Categories_sub"
        title={
          <span>
            <Icon type="user" />
            <span>Categories</span>
          </span>
        }
      >
        { //#119 - Disabling the code because we are not using it anymore by Tom
          /*<Menu.Item key="21">
          <NavLink to="/categories/list" >
            <span>Category List</span>
          </NavLink>
        </Menu.Item>*/}
        <Menu.Item key="main-21">
          <NavLink to="/main-categories/list" >
            <span>Main Categories</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="sub-21">
          <NavLink to="/sub-categories/list" >
            <span>Sub Categories</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="22">
          <NavLink to="/categories/new" >
            <span>New Category</span>
          </NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="WorkoutBuilder_sub"
        title={
          <span>
            <Icon type="user" />
            <span>Workout Builder</span>
          </span>
        }
      >
        <Menu.Item key="28">
          <NavLink to="/workout-builder/setup" >
            <span>Workout Setup</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="27">
          <NavLink to="/exercises/new" >
            <span>Create Exercise</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="26">
          <NavLink to="/exercises/list" >
            <span>Exercise List</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="23">
          <NavLink to="/workouts/new" >
            <span>Create Workout</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="24">
          <NavLink to="/workouts/list" >
            <span>Workout List </span>
          </NavLink>

        </Menu.Item>
        { /*
          //#127 - Disabling the code because we are not using it anymore by Tom
        <Menu.Item key="25">
          <NavLink to="/bricks/new" >
            <span>Upload Bricks</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="24">
          <NavLink to="/bricks/list" >
            <span>List Bricks</span>
          </NavLink>
        </Menu.Item>
      */}
      </SubMenu>

      <Menu.Item key="9">
        <NavLink to="/user-profile" >
          <Icon type="user" />
          <span>User Profile</span>
        </NavLink>
      </Menu.Item>
    </Menu>

  }

  render() {
    // const { classes } = this.props;
    // const { ...rest } = this.props;

    // console.log(this.props);
    // const mainPanel =
    //   classes.mainPanel +
    //   " " +
    //   cx({
    //     [classes.mainPanelSidebarMini]: this.state.miniActive,
    //     [classes.mainPanelWithPerfectScrollbar]:
    //       navigator.platform.indexOf("Win") > -1
    //   });
    //   // console.log(this.props);
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider style={{ minHeight: '100vh' }} collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
          <div className="logo" />
          <img src={logo} alt="" style={{ width: "99%", paddingLeft: 2, paddingTop: 4, paddingRight: 2, paddingBottom: 12 }} />
          {this.generate_sidebar()}
        </Sider>
        <Layout>
          <Header style={{ background: '#fff', padding: 0 }}>
            <DashboardHeaderContainer
              isAuthenticated={this.props.isAuthenticated}
              userHasAuthenticated={this.props.userHasAuthenticated}
              handleLogout={this.props.handleLogout}
            />
          </Header>
          <Layout>
            <Content style={{ margin: '16px' }}>
              <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
                <div >{this.switchRoutes()}</div>
              </div>
            </Content>
          </Layout>
          <Footer style={{ textAlign: 'center' }}>
            <strong>XOlation </strong>Admin Portal ©2020 Fitness XO Pty Ltd - <small>
              Development by <a href="https://pimwatech.com/" target="_blank">Pimwa Technologies</a>
            </small>
          </Footer>
        </Layout>
      </Layout>


      // <div className={classes.wrapper} style={{backgroundImage:`url(${background_image})`}}>
      //   <SidebarContainer
      //     routes={dashboardRoutes}
      //     logoText={"Forex Worldwide"}
      //     logo={logo}
      //     image={image}
      //     handleDrawerToggle={this.handleDrawerToggle}
      //     open={this.state.mobileOpen}
      //     color="blue"
      //     bgColor="black"
      //     miniActive={this.state.miniActive}
      //     {...rest}
      //   />
      //   <div className={mainPanel} ref="mainPanel">
      //     <HeaderContainer
      //       sidebarMinimize={this.sidebarMinimize.bind(this)}
      //       miniActive={this.state.miniActive}
      //       routes={dashboardRoutes}
      //       handleDrawerToggle={this.handleDrawerToggle}
      //       {...rest}
      //     />
      //     {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
      //     {this.getRoute() ? (
      //       <div className={classes.content}>
      //         <div className={classes.container}>{this.switchRoutes()}</div>
      //       </div>
      //     ) : (
      //       <div className={classes.map}>{this.switchRoutes()}</div>
      //     )}
      //     {this.getRoute() ? <Footer fluid /> : null}
      //   </div>
      // </div>
    );
  }
}

// Dashboard.propTypes = {
//   classes: PropTypes.object.isRequired
// };

export default Dashboard;
