import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import { API, Auth } from "aws-amplify";

import { Skeleton } from 'antd';

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import { Card } from 'antd';
import { PageHeader, Button } from 'antd';

import ProductNewPageComponentContainer from '../../../components/pages/dashboard/products/ProductNewPageComponent';


// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class ProductNewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  handle_show_all = () => {
    this.props.history.push(`/products/list/`);
  }

  handle_new_product = () => {
    this.props.history.push(`/products/new`);
  }
  
  
  render() {
    
    let main_component = <ProductNewPageComponentContainer />
    if (!this.props.app_state.current_user_id) main_component = <Skeleton active />;


    return (

      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="Create New Product"
        />
        <Card
          style={{ minHeight: 360 }}
          title="Create a New Product"
          extra={[
            <React.Fragment>
              {
                <span>
                  <Button key="1" onClick={this.handle_show_all}>
                    Show All
                  </Button>
                  <Button key="2" disabled onClick={this.handle_new_product}>
                    New Product
                  </Button>
                </span>
              }
            </React.Fragment>
          ]}
        >
          <div style={{ paddingTop: 24 }}>
            {main_component}
          </div>
        </Card>
      </div>


    );
  }
}

// export default ProductNewPage;
const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {

  }
}

const ProductNewPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductNewPage);

export default withRouter(ProductNewPageContainer);
