import { combineReducers } from "redux";

import app_state from "./app_state";
import clients from "./clients";
import language from "./language";
import staffs from "./staffs";

// const initialState = {
//   test_value: 0
// };

// function app_state(state = initialState, action) {
//   switch (action.type) {
//     case TEST:
//       return Object.assign({}, state, {
//          test: action.value
//       });

//     default:
//       return state;
//   }
// }

const rootReducer = combineReducers({
  app_state,
  clients,
  language,
  staffs
});

export default rootReducer;
