import React, { Component } from 'react';

// import { API, Auth } from "aws-amplify";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import { Card } from 'antd';
import { PageHeader, Button } from 'antd';
import ProductViewPageComponentContainer from '../../../components/pages/dashboard/products/ProductViewPageComponent';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class ProductViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  handle_show_all = () => {
    this.props.history.push(`/products/list/`);
  }

  handle_new_product = () => {
    this.props.history.push(`/products/new`);
  }
  
  render() {

    console.log(this.props.match.params.product_id)

    return (

      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="Product detail"
        />
        <Card
          style={{ minHeight: 360 }}
          title="Edit product detail"
          extra={[
            <React.Fragment>
              {
                <span>
                  <Button key="1" onClick={this.handle_show_all}>
                    Show All
                  </Button>
                  <Button key="2" onClick={this.handle_new_product}>
                    New Product
                  </Button>
                </span>
              }
            </React.Fragment>
          ]}
        >
          <div style={{ paddingTop: 24 }}>
            <ProductViewPageComponentContainer
              product_id={this.props.match.params.product_id}
            />
          </div>
        </Card>
      </div>


    );
  }
}

export default ProductViewPage;
