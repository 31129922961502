import React, { Component } from "react";
import { API } from "aws-amplify";
// import { Auth } from "aws-amplify";
import { Storage } from "aws-amplify";
import uuid from "uuid";

import {
  Button, notification, Row, Col, Form, Input, Upload
} from "antd";
import { InboxOutlined } from '@ant-design/icons';

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};
const { Dragger } = Upload;

const moment = require("moment");

class WorkoutSetupPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      pre_file_id: "",
      post_file_id: "",
      instruction_file_id: "",
      video_category_id: " ",
      video_category: [],
    }

    this.uploader_props = {
      customRequest: ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials
      }) => {
        // console.log(file);
        const s3_filename = `video-${moment().format("YYYYMMDD-HHmmss")}-${uuid.v1()}`;

        Storage.put(s3_filename, file, {
          contentType: file.type,
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            onProgress(
              {
                percent: Math.round((progress.loaded / progress.total) * 100)
              },
              file
            );
          }
        })
          .then((result) => {
            console.log(result);
            onSuccess(result);
            this.setState({ pre_file_id: result.key });
          })
          .catch((err) => {
            console.log(err)
            this.setState({
              upload_error: err
            })
          });
      }
    }

    this.post_uploader_props = {
      customRequest: ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials
      }) => {
        // console.log(file);
        const s3_filename = `video-${moment().format("YYYYMMDD-HHmmss")}-${uuid.v1()}`;

        Storage.put(s3_filename, file, {
          contentType: file.type,
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            onProgress(
              {
                percent: Math.round((progress.loaded / progress.total) * 100)
              },
              file
            );
          }
        })
          .then((result) => {
            console.log(result);
            onSuccess(result);
            this.setState({ post_file_id: result.key });
          })
          .catch((err) => {
            console.log(err)
            this.setState({
              post_upload_error: err
            })
          });
      }
    }

    this.instruction_uploader_props = {
      customRequest: ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials
      }) => {
        // console.log(file);
        const s3_filename = `video-${moment().format("YYYYMMDD-HHmmss")}-${uuid.v1()}`;

        Storage.put(s3_filename, file, {
          contentType: file.type,
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            onProgress(
              {
                percent: Math.round((progress.loaded / progress.total) * 100)
              },
              file
            );
          }
        })
          .then((result) => {
            console.log(result);
            onSuccess(result);
            this.setState({ instruction_file_id: result.key });
          })
          .catch((err) => {
            console.log(err)
            this.setState({
              instruction_upload_error: err
            })
          });
      }
    }
  }

  componentDidMount() {
    //this.props.startNewClientCreation();
  }

  handleChange = (event) => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    openNotificationWithIcon(
      "info",
      "Record Creation",
      "New Workout record creation is starting."
    );
    //console.log(event);
    console.log(this.state);
    //console.log(this.props.clients.new_client_data)
    const {
      // id,
      pre_file_id,
      post_file_id,
      instruction_file_id
    } = this.state;

    try {
      API.put("workouts", `/setup/1`, {
        body: {
          // id: id,
          preWorkoutVideo: pre_file_id,
          postWorkoutVideo: post_file_id,
          instructionVideo: instruction_file_id
          //...this.props.clients.new_client_data
        },
      })
        .then((response) => {
          console.log(response);
          openNotificationWithIcon(
            "success",
            "Record Created",
            "New Workout has been successfully created."
          );

          return response;
        })
        .then((database_result) => {
          console.log(database_result);
          openNotificationWithIcon(
            "success",
            "Record Created",
            "New Workout has been successfully created."
          );
          //this.props.history.push(`/workouts/list`);
          window.location.assign(`/workout-builder/setup`);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      alert(e);
      openNotificationWithIcon(
        "error",
        "Save Failed",
        "An error occurred in data saving"
      );
      // this.setState({ isLoading: false });
      // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
    }

    this.setState({ isLoading: true });
  };

  render() {
    // const { classes } = this.props;
    console.log(this.state);
    return (
      <React.Fragment>
        <Form layout="vertical">
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Form.Item
                label="Pre-workout Video Upload"
                help={this.state.upload_error ? this.state.upload_error : "Please upload .mp4 video file only."}
              >
                <Dragger
                  {...this.uploader_props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">
                    Support for a single video file only.
                </p>
                </Dragger>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Post-workout Video Upload"
                help={this.state.post_upload_error ? this.state.post_upload_error : "Please upload .mp4 video file only."}
              >
                <Dragger
                  {...this.post_uploader_props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">
                    Support for a single video file only.
                </p>
                </Dragger>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Instruction Video Upload"
                help={this.state.instruction_upload_error ? this.state.instruction_upload_error : "Please upload .mp4 video file only."}
              >
                <Dragger
                  {...this.instruction_uploader_props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">
                    Support for a single video file only.
                </p>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[96, 24]}>
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                disabled={!this.state.pre_file_id || this.state.pre_file_id === " "}
                htmlType="submit"
                onClick={this.handleSubmit}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

// const mapStateToProps = state => {
//   return {
//     app_state: state.app_state,
//     language: state.language
//   };
// };

// const mapDispatchToProps = dispatch => {
//   return {
// startNewClientCreation: () => {
//   dispatch(startNewClientCreation())
// },
// updateNewClientCreation: (key, value) => {
//   dispatch(updateNewClientCreation(key, value))
// }
// cancelCreateNewStaffMember: () => {
// dispatch(cancelCreateNewStaffMember())
// },
// confirmNewStaffMember: (data) => {
//   dispatch(confirmNewStaffMember(data))
// },
// selectStaffUi: (data) => {
//   dispatch(selectStaffUi(data))
// },
//   }
// }

// const CategoryNewPageComponentContainer = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(withStyles(styles)(CategoryNewPageComponent)));

export default WorkoutSetupPageComponent;
