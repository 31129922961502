
import React, { Component } from 'react';
import { connect } from "react-redux";

import {
  updateNewClientCreation

} from "../../../../../redux/actions/clients";

// import { API, Auth } from "aws-amplify";

//import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card, Button } from 'antd';
// import { notification } from 'antd';
import { Checkbox } from 'antd';
import { Form, Input } from 'antd';

// const openNotificationWithIcon = (type, header, message) => {
//   notification[type]({
//     message: header,
//     description: message,
//   });
// };

class NewClientWizard01 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      email_status: "",
      first_name_status: "",
      last_name_status: "",
      vip: false
    }
  }


  async componentDidMount() {
    
  }

  async componentWillReceiveProps(nextProps, nextContext) {

  }

  //validate_form_and_put_success_icon at form page
  validate_form = (event) => {
    // console.log(event.target.id);
    // console.log(event.target.value);
    // console.log(event.target.value.length);

    if (event.target.value.length > 0) {
      this.setState({
        [event.target.id + '_status']: "success",
      })
    } else {
      this.setState({
        [event.target.id + '_status']: "error",
      })
    }
  }

  handleChange = (event) => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
    this.props.updateNewClientCreation(event.target.id, event.target.value);

    this.validate_form(event);
  };

  handleCheckbox = event => {
    console.log(`checked = ${event.target.checked}`);
    this.setState({
      vip: event.target.checked
    });
    this.props.updateNewClientCreation("vip", event.target.checked);
  }

  render() {
    // console.log(this.state);

    if (!this.props.clients || !this.props.clients.new_client_data) return null;

    return (

      <React.Fragment>
        <Form layout="vertical" onSubmit={this.handleSubmit}>

          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item
                    label="First Name"
                    // style={{ marginBottom: 0 }}
                    validateStatus={
                      this.state.first_name_status === "success"
                        ? "success"
                        : this.props.clients.new_client_data.first_name_status
                    }
                    hasFeedback
                    help={this.props.clients.new_client_data.first_name_status === "error" && (this.state.first_name_status !== "success")
                      ? "First name is required" : ""}
                  >
                    <Input
                      id="first_name"
                      value={this.props.clients.new_client_data.first_name}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label="Middle Name(s)"
                    // style={{ marginBottom: 0 }}
                  >
                    <Input
                      id="middle_name"
                      value={this.props.clients.new_client_data.middle_name}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Last Name"
                //validateStatus={this.props.clients.new_client_data.last_name_status}
                validateStatus={
                  this.state.last_name_status === "success"
                    ? "success"
                    : this.props.clients.new_client_data.last_name_status
                }
                hasFeedback
                help={this.props.clients.new_client_data.last_name_status === "error" && (this.state.last_name_status !== "success")
                  ? "Last name is required" : ""}
              >
                <Input
                  id="last_name"
                  value={this.props.clients.new_client_data.last_name}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="E-mail"
                validateStatus={this.props.clients.new_client_data.email_status}
                // validateStatus={
                //   this.state.email_status === "success"
                //     ? "success"
                //     : this.props.clients.new_client_data.email_status
                // }
                hasFeedback
                help={
                  this.props.clients.new_client_data.email_error_message
                  // this.props.clients.new_client_data.email_status === "error" && (this.state.email_status !== "success")
                  //   ? "Email is required" : ""
                }
              >
                <Input
                  id="email"
                  value={this.props.clients.new_client_data.email}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Client" >
                <Checkbox checked={this.state.vip} onChange={this.handleCheckbox}>Is a VIP</Checkbox>
              </Form.Item>

            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNewClientCreation: (key, value) => {
      dispatch(updateNewClientCreation(key, value))
    }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const NewClientWizard01Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewClientWizard01);

export default NewClientWizard01Container;
