import React, { Component } from 'react';

// import { API, Auth } from "aws-amplify";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import { Card } from 'antd';
import { PageHeader } from 'antd';
// import { Button } from 'antd';
import StaffViewPageComponentContainer from '../../../components/pages/dashboard/staff/StaffViewPageComponent';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class StaffViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {

    //console.log(this.props.match.params.staff_id);

    return (

      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="View Staff"
        />
        <Card
          style={{ minHeight: 360 }}
          title="View and update your Staff Details"
        >
          <div style={{ paddingTop: 24 }}>
          <StaffViewPageComponentContainer
            staff_id={this.props.match.params.staff_id}
           />

          </div>
        </Card>
      </div>


    );
  }
}

export default StaffViewPage;