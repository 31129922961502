import React, { Component } from 'react';
import withStyles from 'react-jss'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { API } from "aws-amplify";
// import { Auth } from "aws-amplify";
import { Storage } from "aws-amplify";
import uuid from "uuid";

//import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card } from 'antd'; 
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input } from 'antd';

// import { Tabs } from 'antd';
import { Upload } from 'antd';
// import { message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
// import { DashboardOutlined, VideoCameraOutlined } from '@ant-design/icons'
import { Select, Radio } from 'antd';
import { Checkbox } from 'antd';

import './DatePickerSample.css';
import { DatePicker } from 'antd';

import ImgCrop from 'antd-img-crop';

const { Option } = Select;
const { TextArea } = Input;

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: "right"
  },
};
const { Dragger } = Upload;

const moment = require("moment");

class VideoUploadPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      file_id: "",
      video_category_id: " ",
      video_category: [],
      get_ddl_trainers_list: [],
      free_content: "",
      category_main_id: 1,
      equipment_required: ""
    }

    this.uploader_props = {
      customRequest: ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials
      }) => {
        // console.log(file);
        const s3_filename = `video-${moment().format("YYYYMMDD-HHmmss")}-${uuid.v1()}`;

        Storage.put(s3_filename, file, {
          contentType: file.type,
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            onProgress(
              {
                percent: Math.round((progress.loaded / progress.total) * 100)
              },
              file
            );
          }
        })
          .then((result) => {
            console.log(result);
            onSuccess(result);
            this.setState({ file_id: result.key });
          })
          .catch((err) => {
            console.log(err)
            this.setState({
              upload_error: err
            })
          });
      }
    };

    this.poster_uploader_props = {
      customRequest: ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials
      }) => {
        // console.log(file);
        const s3_filename = `poster-${moment().format("YYYYMMDD-HHmmss")}-${uuid.v1()}`;

        Storage.put(s3_filename, file, {
          contentType: file.type,
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            onProgress(
              {
                percent: Math.round((progress.loaded / progress.total) * 100)
              },
              file
            );
          }
        })
          .then((result) => {
            console.log(result);
            onSuccess(result);
            this.setState({ poster_file_id: result.key });
          })
          .catch((err) => {
            console.log(err)
            this.setState({
              poster_upload_error: err
            })
          });
      }
    };

  }

  componentDidMount() {
    // API.get("video", `/list-video-category`).then(
    //   (response) => {
    //     console.log(response)

    //API to get get_ddl_trainers_list
    API.get("staff", `/get-ddl-trainers`).then(
      (response) => {
        console.log(response)

        this.setState({
          get_ddl_trainers_list: response.payload,
          // video_category: [
          //   {id:1, full_name:"abc"},
          //   {id:2, full_name:"def"}
          // ]
        })

      }).catch(err => {
        console.log(err);
      });

    // this.setState({
    //   video_category: response.payload,
    //   // video_category: [
    //   //   {id:1, full_name:"abc"},
    //   //   {id:2, full_name:"def"}
    //   // ]
    // })

    // }).catch(err => {
    //   console.log(err);
    // });

    if (!this.state.category_sub_id) {
      API.get("video-categories", `/main/list`).then(
        response => {
          console.log(response.payload);
          this.setState({
            data_main_categories: response.payload
            // data_sub_categories: [
            //   {
            //     'id': 1,
            //     'file_by': ""
            //   }
            // ]
          })

          API.get("video-categories", `/sub-list/${this.state.category_main_id}`).then(
            response => {
              console.log(response.payload);
              this.setState({
                data_sub_categories: response.payload
              })
            }
          );
        }
      );
    } else {

      API.get("video-categories", `/main/list`).then(
        response => {
          console.log(response.payload);
          this.setState({
            data_main_categories: response.payload,
          })

          API.get("video-categories", `/sub-list/${this.state.category_main_id}`).then(
            response => {
              console.log(response.payload);
              this.setState({
                data_sub_categories: response.payload
              })
            }
          );
        }
      );
    }
  }

  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleCheckbox = event => {
    console.log(`checked = ${event.target.checked}`);
    this.setState({
      free_content: event.target.checked
    });
  }

  // handleSelectChange_video_category = (value) => {
  //   this.setState({
  //     video_category_id: value
  //   });
  //   //this.props.updateNewClientCreation("postal_address_country", value);
  // };

  handleSelectChange_upload_trainer = (value) => {
    this.setState({
      trainer_staff_id: value
    });
    //this.props.updateNewClientCreation("postal_address_country", value);
  };

  onChangePublicDateTime = (date, dateString) => {
    console.log(date, dateString);
    // App.setState ({
    //   selectedDate: date
    // });
    console.log(moment(dateString).utc().format("YYYY-MM-DD HH:mm:ss"));
    //console.log(this);
    this.setState({
      public_date: moment(dateString).utc().format("YYYY-MM-DD HH:mm:ss")
    });
  }

  onRadioEquipRequiredChange = (e) => {
    // console.log(`radio checked:${e.target.value}`);
    this.setState({
      equipment_required: e.target.value
    });
  }

  handleSubmit = async event => {

    event.preventDefault();
    openNotificationWithIcon('info', "Record Creation", "New video record creation is starting.")
    const {
      description,
      file_id,
      // video_category_id,
      public_date,
      trainer_staff_id,
      long_description,
      poster_file_id,
      free_content,
      category_main_id,
      category_sub_id,
      equipment_required
    } = this.state;

    try {
      API.post("video", `/create`, {
        body: {
          description,
          file_id,
          // video_category_id,
          public_date,
          trainer_staff_id,
          long_description,
          poster_file_id,
          free_content,
          category_main_id,
          category_sub_id,
          equipment_required
        }
      })
        .then(database_result => {
          console.log(database_result)

          //#52 add interaction email on video upload
          API.post("email", `/interaction`, {
            body: {
              interaction: "Create New Video Record",
              data: {
                description,
                file_id,
                // video_category_id,
                public_date,
                trainer_staff_id,
                long_description,
                poster_file_id,
                free_content,
                category_main_id,
                category_sub_id,
                equipment_required
              },
              result: "New video uploaded successfully"
            }
          }).then(database_result => {
            //console.log(database_result)
          }).catch(e => {
            console.log(e)
          })

          openNotificationWithIcon('success', "Record Created", "New video has been successfully created.")
          return database_result;
        })
        .then(database_result => {
          // console.log(database_result)
          // openNotificationWithIcon('success', "Record Created", "New client has been successfully created.")
          this.props.history.push(`/video/list`);
        })
    } catch (e) {
      // alert(e);
      openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
      // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
    }

    // console.log(event)
    this.setState({ isLoading: true });

  };

  handleSelectChange_main_category_id = (id) => {
    this.setState({
      category_main_id: id
    });

    API.get("video-categories", `/sub-list/${id}`)
      .then((response) => {
        console.log(response)
        this.setState({
          data_sub_categories: response.payload,
        })
      }).catch(err => {
        console.log(err);
      });

    API.get("video-categories", `/main/id/${id}`)
      .then((response) => {
        console.log(response)
        this.setState({
          main_category: response.payload,
        })
      }).catch(err => {
        console.log(err);
      });
  };

  handleSelectChange_sub_category_id = (id) => {
    this.setState({
      category_sub_id: id
    });
    API.get("video-categories", `/sub/id/${id}`)
      .then((response) => {
        console.log(response)
        this.setState({
          sub_category: response.payload,
        })
      }).catch(err => {
        console.log(err);
      });
  };

  render() {
    // const { classes } = this.props;
    // const { current_step } = this.state;
    console.log(this.state);
    // console.log(this.props);

    if (!this.props.clients && !this.props.clients.new_client_data) return null;
    // var video_categories_list_select_options = this.state.video_category.map(d => <Option key={d.id} >{d.nickname}</Option>);
    if (this.state.get_ddl_trainers_list) {
      var get_ddl_trainers_list_select_options = this.state.get_ddl_trainers_list.map(d => <Option key={d.id} >{d.nickname}</Option>);
    }

    if (!this.state.data_main_categories) return null;
    const main_categories_option = this.state.data_main_categories.map(d => <Option key={d.id} >{d.file_by}</Option>);

    if (!this.state.data_sub_categories) return null;
    const sub_categories_option = this.state.data_sub_categories.map(d => <Option key={d.id} >{d.file_by}</Option>);
    
    const radio_options_yes_no = [
      { label: "Yes", value: 1 },
      { label: "No", value: 0 },
    ]
    
    return (

      <React.Fragment>

        <Form onSubmit={this.handleSubmit}>

          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Row gutter={[96, 24]}>
                <Col xs={11}>
                  <Form.Item
                    label="Date / Time"
                  >
                    <React.Fragment>
                      {<DatePicker
                        showTime={{ format: 'HH:mm' }}
                        onChange={this.onChangePublicDateTime}
                      />}
                    </React.Fragment>
                  </Form.Item>
                </Col>
                <Col xs={12} >
                  <Form.Item label="Free Content" >
                    <Checkbox checked={this.state.free_content} onChange={this.handleCheckbox}>Is a free content</Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[96, 24]}>
                <Col xs={11}>
                  <Form.Item label="Main Category" style={{ marginBottom: 0 }}>
                    <Select
                      id="category_main_id"
                      name="category_main_id"
                      showSearch
                      value={
                        typeof this.state.category_main_id === "number"
                          ? this.state.category_main_id.toString()
                          : (this.state.category_main_id === " ")
                            ? "Select Main Category"
                            : this.state.category_main_id
                      }
                      placeholder="Select Category"
                      showArrow={true}
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }}
                      onChange={this.handleSelectChange_main_category_id}
                    >
                      {main_categories_option}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Sub Category" style={{ marginBottom: 0 }}>
                    <Select
                      id="category_sub_id"
                      name="category_sub_id"
                      showSearch
                      value={
                        typeof this.state.category_sub_id === "number"
                          ? this.state.category_sub_id.toString()
                          : (this.state.category_sub_id === " ")
                            ? "Select Sub Category"
                            : this.state.category_sub_id
                      }
                      placeholder="Select Category"
                      showArrow={true}
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }}
                      onChange={this.handleSelectChange_sub_category_id}
                    >
                      {sub_categories_option}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[96, 24]}>
                {/* <Col xs={11}>
                  <Form.Item
                    label="Video Category ID"
                  >
                    <Select
                      id="video_category_id"
                      showSearch
                      value={
                        typeof this.state.video_category_id === "number"
                          ? this.state.video_category_id.toString()
                          : (this.state.video_category_id === " ")
                            ? "Select Category"
                            : this.state.video_category_id
                      }
                      placeholder="Select Category"
                      showArrow={true}
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      }
                      onChange={this.handleSelectChange_video_category}
                    >
                      {
                        video_categories_list_select_options
                      }
                    </Select>
                  </Form.Item>
                </Col> */}

                <Col xs={12}>
                  <Form.Item
                    //name={['user', 'video_category_id']}
                    label="Select Trainer"
                  >
                    <Select
                      id="trainer_staff_id"
                      showSearch
                      mode="multiple"
                      value={
                        typeof this.state.trainer_staff_id === "number"
                          ? this.state.trainer_staff_id.toString()
                          : (this.state.trainer_staff_id === " ")
                            ? "Select Trainer"
                            : this.state.trainer_staff_id
                      }
                      placeholder="Select Trainer"
                      showArrow={true}
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      }
                      onChange={this.handleSelectChange_upload_trainer}
                    >
                      {
                        get_ddl_trainers_list_select_options
                      }
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label="1-line Description"
              >
                <Input
                  id="description"
                  value={this.state.description}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Long Description (1,000 characters)"
              >
                <TextArea
                  id="long_description"
                  value={
                    this.state.long_description
                  }
                  rows={4}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              {/* <Form.Item label="File ID">
                <Input
                  id="file_id"
                  disabled
                  value={this.state.file_id}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Poster File ID">
                <Input
                  id="poster_file_id"
                  disabled
                  value={this.state.poster_file_id}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              */}
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Video Upload"
                help={this.state.upload_error ? this.state.upload_error : "Please upload .mp4 video file only."}
              >
                <Dragger
                  {...this.uploader_props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">
                    Support for a single video file only.
                </p>
                </Dragger>
              </Form.Item>
              <Form.Item
                label="Poster Upload"
                help={this.state.poster_upload_error ? this.state.poster_upload_error : "Please upload a poster image as close to 150px x 150px resolution as possible."}
              >
                <ImgCrop aspect="1.33" grid>
                  <Dragger
                    {...this.poster_uploader_props}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                      Support for a single image file only.
                    </p>
                  </Dragger>
                </ImgCrop>
              </Form.Item>

              <Form.Item
                label="Equipment Required"
              >
                <Radio.Group
                  onChange={this.onRadioEquipRequiredChange}
                  value={this.state.equipment_required}
                  options={radio_options_yes_no}
                  optionType="button"
                  buttonStyle="solid"
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                onClick={this.handleSubmit}
                // disabled={!this.state.file_id || this.state.video_category_id === " "}
                disabled={!this.state.file_id || this.state.category_sub_id === " "}
                htmlType="submit">
                Save
              </Button>
            </Col>
          </Row>
        </Form>


      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // startNewClientCreation: () => {
    //   dispatch(startNewClientCreation())
    // }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const VideoUploadPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(VideoUploadPageComponent)));

export default VideoUploadPageComponentContainer;
