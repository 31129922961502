import React, { Component } from 'react';

// import { API, Auth } from "aws-amplify";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import { Card } from 'antd';
import { PageHeader } from 'antd';
// import { Button } from 'antd';
import SubscriptionSuccessPageComponentContainer from '../../../components/pages/dashboard/subscription/SubscriptionSuccessPageComponent';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;
const queryString = require('query-string');

class SubscriptionSuccessPage extends Component {
  constructor(props) {
    super(props);
    const parsed = queryString.parse(this.props.location.search);
    this.state = {
      session_id: parsed.session_id
    }
  }


  render() {
    // console.log(this.state);
    return (

      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="Subscription Success"
        />
        <Card
          style={{ minHeight: 360 }}
          title="Your subscription has been successful"
        >
          <div style={{ paddingTop: 24 }}>
            <SubscriptionSuccessPageComponentContainer session_id={this.state.session_id} />
          </div>
        </Card>
      </div>


    );
  }
}

export default SubscriptionSuccessPage;
