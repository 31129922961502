
import React, { Component } from 'react';
import { Table } from 'antd';

import { API } from "aws-amplify";
// import { Storage } from "aws-amplify";
import { NavLink } from "react-router-dom";
import { get_url } from "../../library/aws_library";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Divider } from 'antd';
// import { Card } from 'antd';
// import { Form } from 'antd';
import { Button } from 'antd';
import { Input } from 'antd';

import { Tag } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

import { QuestionCircleOutlined } from "@ant-design/icons";

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

var moment = require("moment");

class VideoListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: []
    }

    this.columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        render: text => <a>{text.toString().padStart(5, '0')}</a>,
      },
      {
        title: 'Date',
        //dataIndex: 'public_date', //WARN: don't use this one.. it will change the value of record
        key: 'public_date',
        width: 150,
        sorter: (a, b) => moment(a.public_date).unix() - moment(b.public_date).unix(),
        defaultSortOrder: "descend",
        render: (text, record) => {
          return {
            props: {
              style: { verticalAlign: "center" }
            },
            children: <React.Fragment>
              <span>
                {
                  moment(record.public_date).format('LLLL') === "Invalid date"
                    ? " "
                    : <span>{moment(record.public_date).format('DD MMMM YYYY')}
                      <br />
                      {moment(record.public_date).format('HH:mm a')}</span>
                }
              </span>
            </React.Fragment>
          }
        },
      },
      {
        title: 'Poster',
        dataIndex: 'poster',
        render: (text, record) => {
          // console.log(record)
          if (!record.is_poster) {
            return <React.Fragment><div style={{ textAlign: "center", fontSize: 20 }}><QuestionCircleOutlined /></div></React.Fragment>
          }
          return <React.Fragment>
            <div >
              <img
                src={text}
                style={{ height: this.props.size === "small" ? 90 : 180, maxWidth: this.props.size === "small" ? 160 : 320 }}
              >
              </img>
            </div>
          </React.Fragment>
        },
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        ...this.getColumnSearchProps('description'),
        render: (text, record) => {
          if (this.props.size === "small") {
            return {
              props: {
                style: { verticalAlign: "top" }
              },
              children: <React.Fragment>
                <div style={{ height: "100%", verticalAlign: "top" }}>
                  <h4>{text}</h4>
                  {record.long_description}
                </div>
              </React.Fragment>
            }
          }
          return {
            props: {
              style: { verticalAlign: "top" }
            },
            children: <React.Fragment>
              <div style={{ height: "100%", verticalAlign: "top" }}>
                <h3>{text}</h3>
                <br />
                {record.long_description}
              </div>
            </React.Fragment>
          }
        }
      },
      // {
      //   title: 'Category',
      //   dataIndex: 'category_nickname',
      //   render: (data_item, record) => {
      //     return <Tag color={record.category_tag_color} style={{ width: 100, textAlign: "center" }} key={record.video_category_id}>{record.category_nickname}</Tag>
      //   }
      // },
      {
        title: 'Main Category',
        dataIndex: 'category_main_file_by',
        render: (data_item, record) => {
          return <Tag color={"blue"} style={{ width: 100, textAlign: "center" }} key={record.category_main_file_by}>{record.category_main_file_by}</Tag>
        }
      },
      {
        title: 'Sub Category',
        dataIndex: 'category_sub_file_by',
        render: (data_item, record) => {
          return <Tag color={"green"} style={{ width: 150, textAlign: "center" }} key={record.category_sub_file_by}>{record.category_sub_file_by}</Tag>
        }
      },
      {
        title: 'Equipment Required',
        dataIndex: 'equipment_required',
        // ...this.getColumnSearchProps('exercise_required'),
        filters: [
          { text: 'Yes', value: 1 },
          { text: 'No', value: 0 || null}
        ],
        onFilter: (value, record) => record.equipment_required === value,
        render: data_item => {
          switch (data_item) {
            case 1:
              return <Tag color={'green'} style={{ width: 100, textAlign: "center" }} key={data_item}> Yes </Tag>
            default:
              return <Tag color={'orange'} style={{ width: 100, textAlign: "center" }} key={data_item}> No </Tag>
          }
        }
      },
      {
        title: 'Favourite',
        dataIndex: 'favourite',
        // ...this.getColumnSearchProps('exercise_required'),
        filters: [
          { text: 'Yes', value: 1 },
          { text: 'No', value: 0 || null}
        ],
        onFilter: (value, record) => record.favourite === value,
        render: data_item => {
          switch (data_item) {
            case 1:
              return <Tag color={'green'} style={{ width: 100, textAlign: "center" }} key={data_item}> Yes </Tag>
            default:
              return <Tag color={'orange'} style={{ width: 100, textAlign: "center" }} key={data_item}> No </Tag>
          }
        }
      },
      {
        title: 'Content',
        dataIndex: 'free_content',
        render: (data_item, record) => {
          switch (data_item) {
            case 1:
              return <Tag color={'green'} style={{ width: 110, textAlign: "center" }} key={data_item}>FREE CONTENT</Tag>
            default:
              return <Tag color={'blue'} style={{ width: 110, textAlign: "center" }} key={data_item}>STANDARD</Tag>
          }
        }
      },
      {
        title: 'Actions',
        key: 'action',
        render: (text, record) => (
          <span>
            <NavLink to={`/video/view/${record.id}`} >View</NavLink>
            {/* <Divider type="vertical" />
            <a>Delete</a> */}
          </span>
        ),
      },
    ];

    if (this.props.views) {
      this.columns.push(
        {
          title: 'View Count',
          dataIndex: 'view_count',
          sorter: (a, b) => a.view_count - b.view_count,
          render: (data_item, record) => {
            return data_item
          }
        }
      )
    }
    if (this.props.favourites) {
      this.columns.push(
        {
          title: 'Favourite Count',
          dataIndex: 'favourite_count',
          sorter: (a, b) => a.favourite_count - b.favourite_count,
          render: (data_item, record) => {
            return data_item
          }
        }
      )
    }
  }

  async componentDidMount() {
    if (this.props.views) {
      API.get("video", `/list-with-count`)
        .then(async response => {
          console.log(response);
          console.log(response.payload);

          let dataSource = [];
          for (let i = 0; i < response.payload.length; i++) {
            dataSource.push({
              key: response.payload[i].id,
              id: response.payload[i].id,
              description: response.payload[i].description,
              file_id: response.payload[i].file_id,
              record_created: response.payload[i].record_created,
              category_nickname: response.payload[i].category_nickname,
              category_tag_color: response.payload[i].category_tag_color,
              video_category_id: response.payload[i].video_category_id,
              public_date: response.payload[i].public_date,
              // email: response.payload[i].email,
              // first_name: response.payload[i].first_name,
              // last_name: response.payload[i].last_name,
              // description: response.payload[i].description,
              long_description: response.payload[i].long_description,
              is_poster: !(response.payload[i].poster_file_id == "" || response.payload[i].poster_file_id == null),
              poster: await get_url(response.payload[i].poster_file_id),
              free_content: response.payload[i].free_content,
              view_count: response.payload[i].view_count,
              equipment_required: response.payload[i].equipment_required,
              favourite: response.payload[i].favourite
            });
          }
          console.log(dataSource);
          this.setState({
            dataSource: dataSource
          })
        })
        .catch(error => {
          console.log(error);
        });
    } else if (this.props.favourites) {
      API.get("video", `/list-with-favourite`)
        .then(async response => {
          console.log(response);
          console.log(response.payload);

          let dataSource = [];
          for (let i = 0; i < response.payload.length; i++) {
            dataSource.push({
              key: response.payload[i].id,
              id: response.payload[i].id,
              description: response.payload[i].description,
              file_id: response.payload[i].file_id,
              record_created: response.payload[i].record_created,
              category_nickname: response.payload[i].category_nickname,
              category_tag_color: response.payload[i].category_tag_color,
              video_category_id: response.payload[i].video_category_id,
              public_date: response.payload[i].public_date,
              // email: response.payload[i].email,
              // first_name: response.payload[i].first_name,
              // last_name: response.payload[i].last_name,
              // description: response.payload[i].description,
              long_description: response.payload[i].long_description,
              is_poster: !(response.payload[i].poster_file_id == "" || response.payload[i].poster_file_id == null),
              poster: await get_url(response.payload[i].poster_file_id),
              free_content: response.payload[i].free_content,
              favourite_count: response.payload[i].favourite_count,
              equipment_required: response.payload[i].equipment_required,
              favourite: response.payload[i].favourite
            });
          }
          console.log(dataSource);
          this.setState({
            dataSource: dataSource
          })
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      API.get("video", `/list-with-category`)
        .then(async response => {
          console.log(response);
          console.log(response.payload);

          let dataSource = [];
          for (let i = 0; i < response.payload.length; i++) {
            dataSource.push({
              key: response.payload[i].id,
              id: response.payload[i].id,
              description: response.payload[i].description,
              file_id: response.payload[i].file_id,
              record_created: response.payload[i].record_created,
              category_main_file_by: response.payload[i].category_main_file_by,
              category_sub_file_by: response.payload[i].category_sub_file_by,
              category_tag_color: response.payload[i].category_tag_color,
              video_category_id: response.payload[i].video_category_id,
              public_date: response.payload[i].public_date,
              // email: response.payload[i].email,
              // first_name: response.payload[i].first_name,
              // last_name: response.payload[i].last_name,
              // description: response.payload[i].description,
              long_description: response.payload[i].long_description,
              is_poster: !(response.payload[i].poster_file_id == "" || response.payload[i].poster_file_id == null),
              poster: await get_url(response.payload[i].poster_file_id),
              free_content: response.payload[i].free_content,
              equipment_required: response.payload[i].equipment_required,
              favourite: response.payload[i].favourite
            });
          }
          console.log(dataSource);
          this.setState({
            dataSource: dataSource
          })
        })
        .catch(error => {
          console.log(error);
        });
    }
  }


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    // console.log(this.state);
    // console.log(this.dataSource);
    // console.log(this.props);
    return (
      <React.Fragment>
        <Table
          columns={this.columns}
          dataSource={this.state.dataSource}
          pagination={{
            pageSizeOptions: ['10', '20', '30'],
            showSizeChanger: true
          }}
        />
      </React.Fragment>
    );
  }
}

export default VideoListComponent;
