import React, { Component } from 'react';
// import withStyles from 'react-jss'
// import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Steps } from 'antd';
// import { message } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';

import { Row, Col } from 'antd';
import { Form, Input } from 'antd';

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

// const { Step } = Steps;

class TagNewPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    //this.props.startNewClientCreation();
  }

  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    openNotificationWithIcon('info', "Record Creation", "New tag record creation is starting.")
    //console.log(event);
    console.log(this.state);
    //console.log(this.props.clients.new_client_data)
    const {
      // id,
      nickname
    } = this.state;

    try {
      API.post("tags", `/create`, {
        body: {
          // id: id,
          nickname: nickname,
          //...this.props.clients.new_client_data
        }
      })
        .then(response => {
          console.log(response)
          openNotificationWithIcon('success', "Record Created", "New Tag has been successfully created.")

          return response;
        })
        .then(database_result => {
          console.log(database_result)
          openNotificationWithIcon('success', "Record Created", "New client has been successfully created.")
          //this.props.history.push(`/tags/list`);
          window.location.assign(`/tags/list`);
        })
        .catch(error => {
          console.log(error);
        });
    } catch (e) {
      alert(e);
      openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
      // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
    }

    this.setState({ isLoading: true });

  }


  render() {
    // const { classes } = this.props;

    return (
      <React.Fragment>
        <Form layout="vertical">

          <Row gutter={[96, 24]}>
            <Col xs={12}>
              {/*<Form.Item
            label="ID"
          >
            <Input
              id="id"
              value={this.state.id}
              onChange={event => {
                this.handleChange(event);
              }}
            />
            </Form.Item>*/}
              <Form.Item
                label="Nickname"
              >
                <Input
                  id="nickname"
                  value={this.state.nickname}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>

            </Col>

          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                disabled={!this.state.nickname || this.state.nickname === " "}
                htmlType="submit"
                onClick={this.handleSubmit}
              >
                Save
          </Button>
            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

// const mapStateToProps = state => {
//   return {
//     app_state: state.app_state,
//     language: state.language
//   };
// };

// const mapDispatchToProps = dispatch => {
//   return {
// startNewClientCreation: () => {
//   dispatch(startNewClientCreation())
// },
// updateNewClientCreation: (key, value) => {
//   dispatch(updateNewClientCreation(key, value))
// }
// cancelCreateNewStaffMember: () => {
// dispatch(cancelCreateNewStaffMember())
// },
// confirmNewStaffMember: (data) => {
//   dispatch(confirmNewStaffMember(data))
// },
// selectStaffUi: (data) => {
//   dispatch(selectStaffUi(data))
// },
//   }
// }

// const TagNewPageComponentContainer = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(withStyles(styles)(TagNewPageComponent)));

//export default TagNewPageComponentContainer;

export default TagNewPageComponent;
