import {
  STAFFS_START_NEW_STAFF_CREATION,
  STAFFS_UPDATE_NEW_STAFF_CREATION
} from "../actions/staffs";

const initialState = {
  new_staff_data: null,
  new_staff_submitted: false,
  new_staff_created: false
};

function staffs(state = initialState, action) {
  switch (action.type) {
    case STAFFS_START_NEW_STAFF_CREATION:
      console.log("=debug=");
      return Object.assign({}, state, {
        // staff_detail: action.staff_detail,
        new_staff_data: {
          first_name: "",
          last_name: "",
          email: "",
        },
        new_staff_submitted: false,
        new_staff_created: false
        // client_detail_loaded: true
        // staff_detail_loaded: true
      });
      case STAFFS_UPDATE_NEW_STAFF_CREATION:
        return Object.assign({}, state, {
          new_staff_data: Object.assign({}, state.new_staff_data, {
            [action.value.key]: action.value.value
          }),
        });
    default:
      return state;
  }
}

// const rootReducer = combineReducers({
//   app_state
// });

export default staffs;
