
import React, { Component } from 'react';
import { connect } from "react-redux";

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";

//import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input } from 'antd';
import { Select } from 'antd';

const { Option } = Select;

const moment = require('moment');

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class SubscriptionSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  async componentDidMount() {
    const client_list = await this.getClientList(
    );
    console.log(client_list)
    this.setState({
      client_list: client_list.payload
    })

    const plan_list = await this.getPlanList(
    );
    console.log(plan_list)
    this.setState({
      plan_list: plan_list.payload
    })

    const subscription = await this.getSubscriptionRecord(
      this.props.id
    );
    console.log(subscription)
    this.setState({
      ...subscription.payload.subscription,
      subscription_stripe: subscription.payload.stripe_result
    })
    const client = await this.getClientRecord(
      this.state.client_id
    );
    console.log(client);
    this.setState({
      //...client.payload
      // id: plan.payload.id, //10, client_id
      first_name: client.payload.first_name,
      middle_name: client.payload.middle_name,
      last_name: client.payload.last_name,
      email: client.payload.email,
    })
    const plan = await this.getPlanRecord(
      this.state.plan_id
    );
    console.log(plan);
    this.setState({
      //...plan.payload
      // id: plan.payload.id, //1, plan_id
      nickname: plan.payload.nickname, //"TEST XO Member Monthly"
      stripe_id: plan.payload.stripe_id, //"plan_H7SDYAf2B4TLfI"
      product_id: plan.payload.product_id, // 1
      term: plan.payload.term, //"monthly"
      retail_price: plan.payload.retail_price, //44.99
      product_nickname: plan.payload.product_nickname,//"TEST XO Online Member"
      product_stripe_id: plan.payload.product_stripe_id, //"prod_H7SCozdGsEdM0I"
    })
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    // console.log(nextProps)
    // if (nextProps.app_state.current_user_id && nextProps.app_state.current_user_aws_cognito_id) {
    //   const user = await this.getUserProfile(
    //     nextProps.app_state.current_user_aws_cognito_id
    //   );
    //   // console.log(user)
    //   this.setState({
    //     ...user.payload
    //   })
    // }
  }


  async getSubscriptionRecord(id) {
    return API.get("subscription", `/id/${id}`);
  }

  async getPlanRecord(id) {
    return API.get("plans", `/id/${id}`);
  }

  async getClientRecord(id) {
    return API.get("clients", `/id/${id}`);
  }

  async getClientList() {
    return API.get("clients", `/list`);
  }

  async getPlanList() {
    return API.get("plans", `/list`);
  }

  // async saveVideoRecord(client_record) {
  //   await API.put("clients", `/update/${this.state.id}`, {
  //     body: client_record
  //   });
  //   openNotificationWithIcon('success', "Save Successful", "Your data has been saved successfully")
  // }


  handleSelectClientChange = value => {

    const selected_client = this.getClientRecord(
      value
    );
    console.log(selected_client);
    selected_client.then(selected_client => {
      this.setState({
        //...selected_client.payload
        client_id: Number(value),
        first_name: selected_client.payload.first_name,
        middle_name: selected_client.payload.middle_name,
        last_name: selected_client.payload.last_name,
        email: selected_client.payload.email,
      })
    })
  };

  handleSelectChange_subscription_status = (item_id, option) => {
    console.log(item_id)
    console.log(option)
    // this.setState({
    //   [name]: item_id
    // });
    this.setState({
      //client_status: item_id
      status_id: item_id
    });
  };

  handleSelectPlanChange = value => {

    const selected_plan = this.getPlanRecord(
      value
    );
    console.log(selected_plan);
    selected_plan.then(selected_plan => {
      this.setState({
        plan_id: Number(value),
        nickname: selected_plan.payload.nickname,
        stripe_id: selected_plan.payload.stripe_id,
        product_id: selected_plan.payload.product_id,
        term: selected_plan.payload.term,
        retail_price: selected_plan.payload.retail_price,
        product_nickname: selected_plan.payload.product_nickname,
        product_stripe_id: selected_plan.payload.product_stripe_id
        //...selected_plan.payload
      })
    })
  };

  // handleChange = event => {
  //   // console.log(event)
  //   this.setState({
  //     [event.target.id]: event.target.value
  //   });
  // };

  handleSubmit = async event => {
    event.preventDefault();
    // console.log(event)
    this.setState({ isLoading: true });

    const {
      id,
      client_id,
      plan_id,
      status_id
    } = this.state;
    try {
      //     await this.saveClientRecord({
      //       // aws_cognito_id: aws_cognito_id,
      //       first_name: first_name,
      //       // middle_name: middle_name,
      //       last_name: last_name,
      //     });
      API.put("subscription", `/update/${id}`, {
        body: {
          client_id,
          plan_id,
          status_id
        }
      }).then(
        () => {
          openNotificationWithIcon('success', "Save Successful", "Your data has been saved successfully");
        }
      );

      //     // this.props.history.push("/");
      //     // this.props.reload_user(this.state.aws_cognito_id);
    } catch (e) {
      alert(e);
      //     try {
      //       await this.saveClientRecord({
      //         // aws_cognito_id: aws_cognito_id,
      //         // first_name: first_name,
      //         // middle_name: middle_name,
      //         // last_name: last_name,
      //       });
      //       // this.props.history.push("/");
      //       // this.props.reload_user(this.state.aws_cognito_id);
      //     } catch (e) {
      //       // alert(e);
      //       openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      //       // this.setState({ isLoading: false });
      //     }

      openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      this.setState({ isLoading: false });
    }
  }

  // handleSubmit = async event => {
  //   event.preventDefault();
  //   // console.log(event)
  //   this.setState({ isLoading: true });

  //   const {
  //     // id,
  //     // aws_cognito_id,
  //     first_name,
  //     // middle_name,
  //     last_name,
  //     // email,
  //   } = this.state;
  //   try {
  //     await this.saveClientRecord({
  //       // aws_cognito_id: aws_cognito_id,
  //       first_name: first_name,
  //       // middle_name: middle_name,
  //       last_name: last_name,
  //     });
  //     // this.props.history.push("/");
  //     // this.props.reload_user(this.state.aws_cognito_id);
  //   } catch (e) {
  //     // alert(e);
  //     try {
  //       await this.saveClientRecord({
  //         // aws_cognito_id: aws_cognito_id,
  //         // first_name: first_name,
  //         // middle_name: middle_name,
  //         // last_name: last_name,
  //       });
  //       // this.props.history.push("/");
  //       // this.props.reload_user(this.state.aws_cognito_id);
  //     } catch (e) {
  //       // alert(e);
  //       openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
  //       // this.setState({ isLoading: false });
  //     }

  //     // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
  //     // this.setState({ isLoading: false });
  //   }
  // };

  render() {
    // console.log(this.state);
    // console.log(this.props.app_state);

    if (!this.props.app_state || !this.props.app_state.data_clients_status_list ||!this.props.app_state.data_subscription_status_list) return null;
    const clients_status_list_select_options = this.props.app_state.data_clients_status_list.map(d => <Option key={d.id} >{d.nickname}</Option>);
    const subscription_status_list_select_options = this.props.app_state.data_subscription_status_list.map(d => <Option key={d.id} >{d.nickname}</Option>);

    return (

      <React.Fragment>
        <Form layout="vertical">

          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <p> Client Details </p>
              {
                //== #94 hide this DDL ==
                /*<Form.Item label="Clients" >
                  <Select
                    placeholder="Select clients"
                    filterOption={false}
                    onChange={this.handleSelectClientChange}
                    style={{ width: '100%' }}
                  >
                    {this.state.client_list
                      ? this.state.client_list.map(d => (
                        <Option key={d.id}>{d.nickname}</Option>
                      ))
                      : ""
                    }
                  </Select>
                </Form.Item>*/
              }
              <Row gutter={[12, 0]}>

                <Col xs={12}>
                  <Form.Item label="First Name">
                    <Input
                      disabled
                      id="first_name"
                      value={this.state.first_name}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Middle Name(s)">
                    <Input
                      disabled
                      id="middle_name"
                      value={this.state.middle_name}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Last Name" >
                <Input
                  disabled
                  id="last_name"
                  value={this.state.last_name}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="E-mail">
                <Input
                  disabled
                  id="email"
                  value={this.state.email}
                  // disabled={true}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Subscrtiption Status">
                <Select
                  id="subscription_status"
                  showSearch
                  value={
                    typeof this.state.status_id === "number" //subscription_status
                      ? this.state.status_id.toString()
                      : this.state.status_id
                  }
                  placeholder="Select Subscription Status"
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  }
                  onChange={this.handleSelectChange_subscription_status}
                >
                  {subscription_status_list_select_options}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <p> Plan Details </p>
              {
                //== #94 hide this DDL ==
              /*<Form.Item label="Plans" >
                <Select
                  placeholder="Select plans"
                  filterOption={false}
                  onChange={this.handleSelectPlanChange}
                  style={{ width: '100%' }}
                >
                  {this.state.plan_list
                    ? this.state.plan_list.map(d => (
                      <Option key={d.id}>{d.nickname}</Option>
                    ))
                    : ""
                  }
                </Select>
                </Form.Item>
              */}
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item label="Nickname">
                    <Input
                      disabled
                      id="nickname" //plan_nickname
                      value={this.state.nickname}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Retail Price (AUD$)" >
                    <Input
                      disabled
                      id="retail_price"
                      value={this.state.retail_price}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Term" >
                {/*<Input
                  id="term"
                  value={this.state.term}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />*/}
                <Select
                  disabled
                  id="term"
                  showSearch
                  value={
                    typeof this.state.term === "number"
                      ? this.state.term.toString()
                      : this.state.term
                  }
                  placeholder="Select terms"
                  //style={{ width: 180 }}
                  onChange={value => {
                    this.handleSelectChange(value)
                  }}
                >
                  <Option value="1">
                    daily
                  </Option>
                  <Option value="2">
                    weekly
                  </Option>
                  <Option value="3">
                    monthly
                  </Option>
                  <Option value="4">
                    annually
                   </Option>
                </Select>
              </Form.Item>
              <Form.Item label="Billing ID" >
                <Input
                  disabled
                  id="stripe_id"
                  value={this.state.stripe_id}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>

              <Form.Item label="Product ID">
                <Input
                  disabled
                  id="product_id"
                  value={this.state.product_id}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Product Nickname">
                <Input
                  disabled
                  id="product_nickname"
                  value={this.state.product_nickname}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Product Billing ID" >
                <Input
                  disabled
                  id="product_stripe_id"
                  value={this.state.product_stripe_id}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Billing Status Code" >
                <Input
                  disabled
                  id="subscription_stripe_status"
                  value={this.state.subscription_stripe?.status}
                />
              </Form.Item>
              <Form.Item label="Billing Start Date" >
                <Input
                  disabled
                  id="subscription_stripe_status"
                  value={moment.unix(this.state.subscription_stripe?.start_date).format("DD-MM-YYYY")}
                />
              </Form.Item>
              <Form.Item label="Billing Current Period Starts" >
                <Input
                  disabled
                  id="subscription_stripe_status"
                  value={moment.unix(this.state.subscription_stripe?.current_period_start).format("DD-MM-YYYY")}
                />
              </Form.Item>
              <Form.Item label="Billing Current Period Ends" >
                <Input
                  disabled
                  id="subscription_stripe_status"
                  value={moment.unix(this.state.subscription_stripe?.current_period_end).format("DD-MM-YYYY")}
                />
              </Form.Item>
              <Form.Item label="View The Client" >
                <a href={`/clients/view/${this.state.client_id}`}>{`${this.state.first_name}, ${this.state.last_name}`}</a>
              </Form.Item>
              
            </Col>
          </Row>

          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button 
                type="primary" 
                disabled={!this.state.status_id}
                htmlType="submit"
                onClick={this.handleSubmit}
              >
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>
        

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const SubscriptionSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionSummary);

export default SubscriptionSummaryContainer;
