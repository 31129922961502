
import React, { Component } from 'react';
import { connect } from "react-redux";

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";

//import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input } from 'antd';
// import { Select } from 'antd';

// const { Option } = Select;

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class TagSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  async componentDidMount() {
    const tag = await this.getTagRecord(
      this.props.id
    );
    console.log(tag)
    this.setState({
      ...tag.payload
    })
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    // console.log(nextProps)
    // if (nextProps.app_state.current_user_id && nextProps.app_state.current_user_aws_cognito_id) {
    //   const user = await this.getUserProfile(
    //     nextProps.app_state.current_user_aws_cognito_id
    //   );
    //   // console.log(user)
    //   this.setState({
    //     ...user.payload
    //   })
    // }
  }

  async getTagRecord(id) {
    return API.get("tags", `/id/${id}`);
  }

  async saveTagRecord(tag_record) {
    await API.put("tags", `/update/${this.state.id}`, {
      body: tag_record
    });
    openNotificationWithIcon('success', "Save Successful", "Your data has been saved successfully")
  }


  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    // console.log(event)
    this.setState({ isLoading: true });

    const {
      id,
      nickname
    } = this.state;
    try {
      await this.saveTagRecord({
        id,
        nickname
      });
      // this.props.history.push("/");
      // this.props.reload_user(this.state.aws_cognito_id);
    } catch (e) {
      // alert(e);
      // try {
      //   await this.saveClientRecord({
      //     // aws_cognito_id: aws_cognito_id,
      //     first_name: first_name,
      //     // middle_name: middle_name,
      //     last_name: last_name,
      //   });
      //   // this.props.history.push("/");
      //   // this.props.reload_user(this.state.aws_cognito_id);
      // } catch (e) {
      // alert(e);
      openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
      // }

      // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
    }
  };

  render() {
    console.log(this.state);

    return (

      <React.Fragment>
        <Form layout="vertical">

          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item label="ID" style={{ marginBottom: 0 }}>
                    <Input
                      id="id"
                      disabled
                      value={this.state.id}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Nickname" style={{ marginBottom: 0 }}>
                    <Input
                      id="nickname"
                      value={this.state.nickname}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={12}>

            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={this.handleSubmit}
              >
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const TagSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TagSummary);

export default TagSummaryContainer;
