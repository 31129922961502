
import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { API } from "aws-amplify";
// import { Auth } from "aws-amplify";
import { Storage } from "aws-amplify";
import uuid from "uuid";
import { get_url } from "../../../../../library/aws_library";

// import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input } from 'antd';
import { Select, Radio } from 'antd';
import { DatePicker } from 'antd';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import ImgCrop from 'antd-img-crop';

const { Dragger } = Upload;

// const moment = require("moment");
var dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');

const { Option } = Select;
const { TextArea } = Input;

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class ExerciseSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      exercise_category_ids: [],
      get_ddl_trainers_list: [],
      get_tag_list: [],
      poster_url: ""
    }

    this.poster_uploader_props = {
      customRequest: ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials
      }) => {
        // console.log(file);
        const s3_filename = `exercise-poster-${dayjs().format('YYYYMMDD-HHmmss')}-${uuid.v1()}`;

        Storage.put(s3_filename, file, {
          contentType: file.type,
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            onProgress(
              {
                percent: Math.round((progress.loaded / progress.total) * 100)
              },
              file
            );
          }
        })
          .then((result) => {
            // console.log(result);
            onSuccess(result);
            this.setState({ poster_file_id: result.key });
          })
          .catch((err) => {
            console.log(err)
            this.setState({
              poster_upload_error: err
            })
          });
      }
    };
  }


  async componentDidMount() {
    const exercise = await this.getExerciseRecord(
      this.props.exercise_id
    );
    // const last_login = await this.getLastLoginRecord(
    //   this.props.client_id
    // );

    // console.log(exercise)
    // console.log(last_login)

    //API to get get_ddl_trainers_list
    await API.get("staff", `/get-ddl-trainers`).then(
      (response) => {
        // console.log(response)

        this.setState({
          get_ddl_trainers_list: response.payload,
        })
      }).catch(err => {
        console.log(err);
      });

    //API to get tags list
    await API.get("tags", `/list`).then(
      (response) => {
        // console.log(response.payload)
        this.setState({
          get_tag_list: response.payload
        })
      })
      .catch(err => {
        console.log(err);
      });

    // this.setState({
    //   ...exercise.payload,
    // })

    //if null
    // if(last_login.payload[0].last_login){
    //   this.setState({
    //     last_login: moment(last_login.payload[0].last_login).format('MMMM Do YYYY, h:mm:ss a')
    //   })
    // }

  }

  async componentWillReceiveProps(nextProps, nextContext) {
    // console.log(nextProps)
    // if (nextProps.app_state.current_user_id && nextProps.app_state.current_user_aws_cognito_id) {
    //   const user = await this.getUserProfile(
    //     nextProps.app_state.current_user_aws_cognito_id
    //   );
    //   // console.log(user)
    //   this.setState({
    //     ...user.payload
    //   })
    // }
  }

  // async getLastLoginRecord(client_id) {
  //   return API.get("clients", `/log/id/${client_id}`);
  // }

  async getExerciseRecord(id) {
    // return API.get("exercises", `/id/${id}`);

    API.get("exercises", `/id/${id}`).then(
      async (response) => {
        console.log(response)
        // if (response.payload.file_id) {
        //   let video_url = await get_url(response.payload.file_id);
        //   this.setState({ video_url })
        // }
        if (response.payload.poster_file_id) {
          let poster_url = await get_url(response.payload.poster_file_id);
          this.setState({ poster_url });
          this.setState({ poster_file_id: response.payload.poster_file_id });
        }

        this.setState({
          exercise: response.payload,
          id: response.payload.id,
          record_created: response.payload.record_created,
          exercise_category_ids: response.payload.exercise_category_ids,
          short_description: response.payload.short_description,
          long_description: response.payload.long_description,
          trainer_staff_ids: response.payload.trainer_staff_ids,
          tags: response.payload.tags,
          equipment: response.payload.equipment,
          deleted: response.payload.deleted,
          exercise_category_text: response.payload.exercise_category_text
        })

      }).catch(err => {
        console.log(err);
      });
  }

  async saveExerciseRecord(exercise_record) {
    await API.put("exercises", `/update/${this.state.id}`, {
      body: exercise_record,
      trainer_staff_ids: this.state.trainer_staff_ids,
      exercise_category_ids: this.state.exercise_category_ids
    });
    openNotificationWithIcon('success', "Save Successful", "Your data has been saved successfully")
  }

  async saveNormalExerciseRecord(exercise_record) {
    await API.put("exercises", `/update-normal/${this.state.id}`, {
      body: exercise_record
    });
    openNotificationWithIcon('success', "Save Successful", "Your data has been saved successfully")
  }


  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  // handleSelectChange = (item_id, option) => {
  //   console.log(item_id)
  //   console.log(option)

  //   this.setState({
  //     exercise_category_id: item_id
  //   });
  // };

  handleSubmit = async event => {
    event.preventDefault();
    // console.log(event)
    this.setState({ isLoading: true });

    const {
      // id,
      // aws_cognito_id,
      id,
      record_created,
      exercise_category_ids,
      short_description,
      long_description,
      trainer_staff_ids,
      // tags,
      equipment,
      poster_file_id,
      deleted,
      exercise_category_text
    } = this.state;
    try {
      await this.saveExerciseRecord({
        id,
        record_created: record_created,
        exercise_category_ids: exercise_category_ids,
        short_description: short_description,
        long_description: long_description,
        trainer_staff_ids: trainer_staff_ids,
        // tags: tags,
        equipment: equipment,
        poster_file_id: poster_file_id,
        deleted: deleted,
        exercise_category_text: exercise_category_text
      });
      // this.props.history.push("/");
      // this.props.reload_user(this.state.aws_cognito_id);
    } catch (e) {
      // alert(e);
      // try {
      //   await this.saveExerciseRecord({
      //     // aws_cognito_id: aws_cognito_id,
      //     first_name: first_name,
      //     // middle_name: middle_name,
      //     last_name: last_name,
      //   });
      //   // this.props.history.push("/");
      //   // this.props.reload_user(this.state.aws_cognito_id);
      // } catch (e) {
      // alert(e);
      openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
      // }

      // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
    }
  };

  handleDeletePosterButton = (event) => {
    alert('remove this file');

    const {
      id,
      poster_file_id
    } = this.state;

    try {
      this.saveNormalExerciseRecord({
        id,
        poster_file_id: null
      })

    } catch (e) {
      console.log(e)
    } finally {
      this.setState({
        poster_file_id: null,
        poster_url: null
      });

      //refresh the data
      setTimeout(() => this.getExerciseRecord(`${this.state.id}`), 3000);
      this.props.history.push(`/exercises/view/${this.state.id}`);
      //video/view/
      //window.location.reload(true);
    };

  }

  handleSelectChange_upload_trainer = (value) => {
    this.setState({
      "trainer_staff_ids": value
    });
  };

  // handleSelectChange_multiple_tags = (value) => {
  //   this.setState({
  //     "tags": value
  //   });
  // };

  handleSelectChange_multiple_categories = (value) => {
    this.setState({
      "exercise_category_ids": value
    });
  };

  // onShortDescChange = ({ target: { value } }) => {
  //   this.setState({ 
  //     short_description: value 
  //   });
  // };

  onLongDescChange = ({ target: { value } }) => {
    this.setState({
      long_description: value
    });
  };

  onChangeRecordCreatedDateTime = (date, dateString) => {
    // console.log(date, dateString);

    dayjs.extend(utc);

    // console.log(dayjs(dateString).utc().format('YYYY-MM-DD HH:mm:ss'));
    // console.log(moment(dateString).utc().format("YYYY-MM-DD HH:mm:ss"));
    //console.log(this);
    this.setState({
      record_created: dayjs(dateString).utc().format('YYYY-MM-DD HH:mm:ss')
    });
  }

  onRadioDeletedChange = (e) => {
    // console.log(`radio checked:${e.target.value}`);
    this.setState({
      deleted: e.target.value
    });
  }

  onRadioEquipmentChange = (e) => {
    // console.log(`radio checked:${e.target.value}`);
    this.setState({
      equipment: e.target.value
    });
  }

  render() {
    // console.log(this.state);

    const radio_options_yes_no = [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ]

    var get_ddl_trainers_list_select_options = this.state.get_ddl_trainers_list.map(d => <Option key={d.id} >{d.nickname}</Option>);
    // var get_tag_list_select_options = this.state.get_tag_list.map(d => <Option key={d.id} >{d.nickname}</Option>);

    if (!this.props.app_state || !this.props.app_state.data_exercise_categories_list) return null;
    const exercise_category_list_select_options = this.props.app_state.data_exercise_categories_list.map(d => <Option key={d.id} >{d.file_by}</Option>);

    return (

      <React.Fragment>
        <Form layout="vertical" onSubmit={this.handleSubmit}>

          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Row gutter={[12, 0]}>
                {/*<Col xs={12}>
                  <Form.Item label="ID">
                    <Input
                      id="id"
                      disabled
                      value={this.state.id}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                 </Col>*/}
                <Col xs={12}>
                  <Form.Item label="Record Created">
                    {/* 
                      <Input
                        id="record_created"
                        disabled
                        value={this.state.record_created}
                        onChange={event => {
                          this.handleChange(event);
                        }}
                      />
                    */}
                    {/* 
                      = Sample code for day.js date picker =
                      dayjs('2018-08-08') // parse
                      dayjs().format('{YYYY} MM-DDTHH:mm:ss SSS [Z] A') // display
                      dayjs().set('month', 3).month() // get & set
                      dayjs().add(1, 'year') // manipulate
                      dayjs().isBefore(dayjs()) // query
                    */}
                    <React.Fragment>
                      {<DatePicker
                        allowClear={false}
                        placeholder={dayjs(this.state.record_created).format('YYYY-MM-DD HH:mm:ss')}
                        showTime={{ format: 'HH:mm' }}
                        onChange={this.onChangeRecordCreatedDateTime}
                      />}
                    </React.Fragment>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Deleted" >
                    <Radio.Group
                      onChange={this.onRadioDeletedChange}
                      value={!!this.state.deleted}
                      options={radio_options_yes_no}
                      optionType="button"
                      buttonStyle="solid"
                    >
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Exercise Category"
              >
                <Select
                  mode="multiple"
                  value={this.state.exercise_category_ids}
                  style={{ width: '100%' }}
                  placeholder="Select Exercise Category"
                  showArrow={true}
                  // optionFilterProp="children"
                  // filterOption={(input, option) => {
                  //   return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  // }
                  // }
                  onChange={this.handleSelectChange_multiple_categories}
                >
                  {exercise_category_list_select_options}
                </Select>
              </Form.Item>
              <Form.Item label="Exercise Category Text">
                <Input
                  id="exercise_category_text"
                  value={this.state.exercise_category_text}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Short Description">
                <Input
                  id="short_description"
                  value={this.state.short_description}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Long Description">
                <TextArea
                  id="long_description"
                  value={this.state.long_description}
                  autoSize={{ minRows: 2, maxRows: 8 }}
                  onChange={this.onLongDescChange}
                />
              </Form.Item>
            </Col>

            <Col xs={12}>

              <Form.Item
                label="Trainer"
              >
                <Select
                  id="trainer_staff_ids"
                  mode="multiple"
                  showSearch
                  value={
                    typeof this.state.trainer_staff_ids === "number"
                      ? this.state.trainer_staff_ids.toString()
                      : (this.state.trainer_staff_ids === " ")
                        ? "Select Trainer"
                        : this.state.trainer_staff_ids
                  }
                  placeholder="Select Trainer"
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  }
                  onChange={this.handleSelectChange_upload_trainer}
                >
                  {
                    get_ddl_trainers_list_select_options
                  }
                </Select>
              </Form.Item>
              <Form.Item label="Equipment Required" >
                <Radio.Group
                  onChange={this.onRadioEquipmentChange}
                  value={!!this.state.equipment}
                  options={radio_options_yes_no}
                  optionType="button"
                  buttonStyle="solid"
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>

              {/* #128 improve exercise list and view
              <Form.Item label="Exercise Category">
                <Select
                  id="exercise_category_id"
                  showSearch
                  value={
                    typeof this.state.exercise_category_id === "number" //exercise_category_id
                    ? this.state.exercise_category_id.toString()
                    : this.state.exercise_category_id
                  }
                  placeholder="Select Exercise Category"
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                }
                onChange={this.handleSelectChange}
              >
                {exercise_category_list_select_options}
              </Select>
              </Form.Item>
               */}
              <React.Fragment>
                {/* #99 Add tags from view / edit video page 

                <Form.Item
                  label="Tags"
                >
                  <Select
                    mode="multiple"
                    value={this.state.tags}
                    style={{ width: '100%' }}
                    placeholder="Select Tag"
                    showArrow={true}
                    // optionFilterProp="children"
                    // filterOption={(input, option) => {
                    //   return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                    // }
                    onChange={this.handleSelectChange_multiple_tags}
                  >
                    {get_tag_list_select_options}
                  </Select>
                </Form.Item>
                */}
              </React.Fragment>
            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={12}>
              {this.state.poster_url &&
                <React.Fragment>
                  <Form.Item
                    label="Poster"
                  >
                    <img src={this.state.poster_url} style={{ height: 180, maxWidth: 320 }}></img>
                  </Form.Item>
                  <Form.Item>

                    <Button
                      type="primary"
                      onClick={() => this.handleDeletePosterButton(this.state.exercise.poster_file_id)}
                    >
                      Remove
                    </Button>
                  </Form.Item>
                </React.Fragment>
              }
              {!this.state.poster_url &&
                <React.Fragment>
                  <Form.Item
                    label="Poster Upload"
                    help={this.state.poster_upload_error ? this.state.poster_upload_error : ""}
                  >
                    <ImgCrop aspect="1.33" grid>
                      <Dragger
                        {...this.poster_uploader_props}>
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                          Support for a single image file only.
                        </p>
                      </Dragger>
                    </ImgCrop>
                  </Form.Item>
                </React.Fragment>}
            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!this.state.id || !this.state.record_created}
                onClick={this.handleSubmit}
              >
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>

      </React.Fragment >
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const ExerciseSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExerciseSummary);

export default withRouter(ExerciseSummaryContainer);
