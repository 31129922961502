// import uuid from "uuid";	

import { Storage } from "aws-amplify";	

const CLOUDFRONT_URL = "https://d3o1qc2449xp6l.cloudfront.net";

export async function get_url(file_id) {	
    const res = await Storage.get(file_id)	
      .then((result) => {	
      // console.log(result)
      let url = result.replace("https://s3.ap-southeast-2.amazonaws.com/xolation.uploads.001", CLOUDFRONT_URL);
      // console.log(url)
      return url;
      })	
    return res;	
  } 