
import React, { Component } from 'react';
import { Table } from 'antd';

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";
import { NavLink } from "react-router-dom";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Divider } from 'antd';
// import { Card, Button } from 'antd';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;
const moment = require("moment");

class WorkoutListPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: []
    }

    this.columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        render: (text, record) => <NavLink to={`/workouts/view/${record.id}`}>{text}</NavLink>,
      },
      {
        title: 'File By',
        dataIndex: 'file_by',
      },
      {
        title: 'Client',
        dataIndex: 'client_name',
      },
      {
        title: 'Created Datetime',
        dataIndex: 'created_datetime',
      },
      {
        title: 'Show All',
        dataIndex: 'showAll',
      }
    ];
  }

  componentDidMount() {
    API.get("workouts", `/list`)
      .then(response => {
        // console.log(response);
        // console.log(response.payload);

        let dataSource = [];
        for (let i = 0; i < response.payload.length; i++) {
          if (response.payload[i].deleted !== 1){
          dataSource.push({
              key: response.payload[i].id,
              id: response.payload[i].id,
              file_by: response.payload[i].file_by,
              client_id: response.payload[i].client_id,
              client_name: response.payload[i].nickname,
              showAll: response.payload[i].showAll,
              created_datetime: response.payload[i].created_datetime 
              ? moment(response.payload[i].created_datetime).format('YYYY/MM/DD hh:mm:ss a') : '',
              deleted: response.payload[i].deleted
            });
          }
        }
        console.log(dataSource);
        this.setState({
          dataSource: dataSource
        })
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    // console.log(this.state);
    // console.log(this.dataSource);
    // console.log(this.props);
    return (
      <React.Fragment>
        <Table
          columns={this.columns}
          dataSource={this.state.dataSource}
          pagination={{ 
            pageSizeOptions: ['10', '20', '30'], 
            showSizeChanger: true }}
        />
      </React.Fragment>
    );
  }
}

export default WorkoutListPageComponent;
