import React, { Component } from 'react';

// import { API, Auth } from "aws-amplify";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import { Card } from 'antd';
import { PageHeader } from 'antd';
// import { Button } from 'antd';

// import SubscriptionNewPageComponentContainer from '../../../components/pages/dashboard/subscription/SubscriptionNewPageComponent';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class SubscriptionCancelPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    // console.log(this.state);
    return (

      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="Create New Subscription"
        />
        <Card
          style={{ minHeight: 360 }}
          title="Subscription Process Cancelled"
        >
          <div style={{ paddingTop: 24 }}>
            {/* <SubscriptionNewPageComponentContainer /> */}
            The subscription payment has been cancelled.<br/>
            No subscription has been created on the billing system.<br/>
            <br/>
            Please <a href="/subscription/new">click here</a> to start a new subscription.
          </div>
        </Card>
      </div>


    );
  }
}

export default SubscriptionCancelPage;
