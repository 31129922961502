
import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ReactPlayer from 'react-player';
import { get_url } from "../../../../../library/aws_library";

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";
import { Storage } from "aws-amplify";
import uuid from "uuid";

//import { Layout } from 'antd';
import { Row, Col } from 'antd';
import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input, InputNumber } from 'antd';

import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
// import { DashboardOutlined, VideoCameraOutlined } from '@ant-design/icons'
// import { Select } from 'antd';
import { Radio } from 'antd';
import { Typography } from 'antd';

// import { TimePicker } from 'antd';
// import moment from 'moment';

const { Title } = Typography;

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

// const styles = {
//   stepsContent: {
//     minHeight: 280,
//     paddingTop: 48,
//   },
//   stepsAction: {
//     marginTop: 24,
//     float: "right"
//   },
// };

const { Dragger } = Upload;

const moment = require("moment");

// const { Option } = Select;
// const { TextArea } = Input;

class ExerciseFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // description: "",
      // file_id,
      file_1_file: "",
      file_2_file: "",
      file_3_file: "",
      file_4_file: "",
      file_5_file: "",
      file_6_file: "",
      // video_1_url: "",
      // video_2_url: "",
      // video_3_url: "",
      // video_4_url: "",
      // video_5_url: "",
      // video_6_url: ""
    }

    this.uploader_props = {
      customRequest: ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials
      }) => {
        // console.log(file);
        const s3_filename = `video-${moment().format("YYYYMMDD-HHmmss")}-${uuid.v1()}`;

        Storage.put(s3_filename, file, {
          contentType: file.type,
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            onProgress(
              {
                percent: Math.round((progress.loaded / progress.total) * 100)
              },
              file
            );
          }
        })
          .then((result) => {
            // console.log(result);
            onSuccess(result);
            this.setState({ file_1_file: result.key });
          })
          .catch((err) => {
            console.log(err)
            this.setState({
              upload_error: err
            })
          });
      }
    };

    this.uploader_2_props = {
      customRequest: ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials
      }) => {
        // console.log(file);
        const s3_filename = `video-${moment().format("YYYYMMDD-HHmmss")}-${uuid.v1()}`;

        Storage.put(s3_filename, file, {
          contentType: file.type,
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            onProgress(
              {
                percent: Math.round((progress.loaded / progress.total) * 100)
              },
              file
            );
          }
        })
          .then((result) => {
            // console.log(result);
            onSuccess(result);
            this.setState({ file_2_file: result.key });
          })
          .catch((err) => {
            console.log(err)
            this.setState({
              upload_error: err
            })
          });
      }
    };

    this.uploader_3_props = {
      customRequest: ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials
      }) => {
        // console.log(file);
        const s3_filename = `video-${moment().format("YYYYMMDD-HHmmss")}-${uuid.v1()}`;

        Storage.put(s3_filename, file, {
          contentType: file.type,
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            onProgress(
              {
                percent: Math.round((progress.loaded / progress.total) * 100)
              },
              file
            );
          }
        })
          .then((result) => {
            // console.log(result);
            onSuccess(result);
            this.setState({ file_3_file: result.key });
          })
          .catch((err) => {
            console.log(err)
            this.setState({
              upload_error: err
            })
          });
      }
    };

    this.uploader_4_props = {
      customRequest: ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials
      }) => {
        // console.log(file);
        const s3_filename = `video-${moment().format("YYYYMMDD-HHmmss")}-${uuid.v1()}`;

        Storage.put(s3_filename, file, {
          contentType: file.type,
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            onProgress(
              {
                percent: Math.round((progress.loaded / progress.total) * 100)
              },
              file
            );
          }
        })
          .then((result) => {
            // console.log(result);
            onSuccess(result);
            this.setState({ file_4_file: result.key });
          })
          .catch((err) => {
            console.log(err)
            this.setState({
              upload_error: err
            })
          });
      }
    };

    this.uploader_5_props = {
      customRequest: ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials
      }) => {
        // console.log(file);
        const s3_filename = `video-${moment().format("YYYYMMDD-HHmmss")}-${uuid.v1()}`;

        Storage.put(s3_filename, file, {
          contentType: file.type,
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            onProgress(
              {
                percent: Math.round((progress.loaded / progress.total) * 100)
              },
              file
            );
          }
        })
          .then((result) => {
            // console.log(result);
            onSuccess(result);
            this.setState({ file_5_file: result.key });
          })
          .catch((err) => {
            console.log(err)
            this.setState({
              upload_error: err
            })
          });
      }
    };

    this.uploader_6_props = {
      customRequest: ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials
      }) => {
        // console.log(file);
        const s3_filename = `video-${moment().format("YYYYMMDD-HHmmss")}-${uuid.v1()}`;

        Storage.put(s3_filename, file, {
          contentType: file.type,
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            onProgress(
              {
                percent: Math.round((progress.loaded / progress.total) * 100)
              },
              file
            );
          }
        })
          .then((result) => {
            // console.log(result);
            onSuccess(result);
            this.setState({ file_6_file: result.key });
          })
          .catch((err) => {
            console.log(err)
            this.setState({
              upload_error: err
            })
          });
      }
    };
  }


  async componentDidMount() {
    const exercise = await this.getExerciseRecord(
      this.props.exercise_id
    );
    // console.log(exercise)
    // this.setState({
    //   ...exercise.payload
    // })
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    // console.log(nextProps)
    // if (nextProps.app_state.current_user_id && nextProps.app_state.current_user_aws_cognito_id) {
    //   const user = await this.getUserProfile(
    //     nextProps.app_state.current_user_aws_cognito_id
    //   );
    //   // console.log(user)
    //   this.setState({
    //     ...user.payload
    //   })
    // }
  }


  // async getClientRecord(aws_cognito_id) {
  //   return API.get("clients", `/id/${aws_cognito_id}`);
  // }

  // async saveClientRecord(client_record) {
  //   await API.put("clients", `/update/${this.state.id}`, {
  //     body: client_record
  //   });
  //   openNotificationWithIcon('success', "Save Successful", "Your data has been saved successfully")
  // }

  async getExerciseRecord(id) {
    // return API.get("exercises", `/id/${id}`);



    API.get("exercises", `/id/${id}`).then(
      async (response) => {
        console.log(response)
        // console.log(this.state);
        if (response.payload.file_1_file) {
          let video_1_url = await get_url(response.payload.file_1_file);
          this.setState({ video_1_url })
        }
        if (response.payload.file_2_file) {
          let video_2_url = await get_url(response.payload.file_2_file);
          this.setState({ video_2_url })
        }
        if (response.payload.file_3_file) {
          let video_3_url = await get_url(response.payload.file_3_file);
          this.setState({ video_3_url })
        }
        if (response.payload.file_4_file) {
          let video_4_url = await get_url(response.payload.file_4_file);
          this.setState({ video_4_url })
        }
        if (response.payload.file_5_file) {
          let video_5_url = await get_url(response.payload.file_5_file);
          this.setState({ video_5_url })
        }
        if (response.payload.file_6_file) {
          let video_6_url = await get_url(response.payload.file_6_file);
          this.setState({ video_6_url })
        }
        // if (response.payload.file_id) {
        //   let video_url = await get_url(response.payload.file_id);
        //   this.setState({ video_url })
        // }
        // if (response.payload.poster_file_id) {
        //   let poster_url = await get_url(response.payload.poster_file_id);
        //   this.setState({ poster_url })
        // }

        this.setState({
          exercise: response.payload,
          id: response.payload.id,
          file_1_file: response.payload.file_1_file,
          file_2_file: response.payload.file_2_file,
          file_3_file: response.payload.file_3_file,
          file_4_file: response.payload.file_4_file,
          file_5_file: response.payload.file_5_file,
          file_6_file: response.payload.file_6_file,
          file_1_length: response.payload.file_1_length,
          file_2_length: response.payload.file_2_length,
          file_3_length: response.payload.file_3_length,
          file_4_length: response.payload.file_4_length,
          file_5_length: response.payload.file_5_length,
          file_6_length: response.payload.file_6_length,
          file_1_description: response.payload.file_1_description,
          file_1_gender: response.payload.file_1_gender,
          file_2_description: response.payload.file_2_description,
          file_2_gender: response.payload.file_2_gender,
          file_3_description: response.payload.file_3_description,
          file_3_gender: response.payload.file_3_gender,
          file_4_description: response.payload.file_4_description,
          file_4_gender: response.payload.file_4_gender,
          file_5_description: response.payload.file_5_description,
          file_5_gender: response.payload.file_5_gender,
          file_6_description: response.payload.file_6_description,
          file_6_gender: response.payload.file_6_gender
          // id: response.payload.id,
          // record_created: response.payload.record_created,
          // exercise_category_id: response.payload.exercise_category_id,
          // free_content: response.payload.free_content,
          // short_description: response.payload.short_description,
          // long_description: response.payload.long_description,
          // trainer_staff_ids:response.payload.trainer_staff_ids,
          // tags: response.payload.tags,
          // equipment: response.payload.equipment
        })

      }).catch(err => {
        console.log(err);
      });
  }

  async saveExerciseRecord(exercise_record) {
    await API.put("exercises", `/update-normal/${this.state.id}`, {
      body: exercise_record
    });
    openNotificationWithIcon('success', "Save Successful", "Your data has been saved successfully")
  }

  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleFile1NumberonChange = (value) => {
    // console.log('changed', value);
    this.setState({
      file_1_length: parseInt(value)
    });
  }

  handleFile2NumberonChange = (value) => {
    // console.log('changed', value);
    this.setState({
      file_2_length: parseInt(value)
    });
  }

  handleFile3NumberonChange = (value) => {
    // console.log('changed', value);
    this.setState({
      file_3_length: parseInt(value)
    });
  }

  handleFile4NumberonChange = (value) => {
    // console.log('changed', value);
    this.setState({
      file_4_length: parseInt(value)
    });
  }

  handleFile5NumberonChange = (value) => {
    // console.log('changed', value);
    this.setState({
      file_5_length: parseInt(value)
    });
  }

  handleFile6NumberonChange = (value) => {
    // console.log('changed', value);
    this.setState({
      file_6_length: parseInt(value)
    });
  }

  handleDeletePoster1Button = (event) => {
    alert('remove this file');

    // const {
    //   file_1_file,
    //   file_1_description,
    //   file_1_gender,
    //   file_1_length
    //   // small_poster_file_id
    // } = this.state;

    try {
      this.saveExerciseRecord({
        file_1_file: null,
        file_1_description: "",
        file_1_gender: null,
        file_1_length: ""
      })

    } catch (e) {
      console.log(e)
    } finally {
      this.setState({
        file_1_file: "",
        file_1_description: "",
        file_1_gender: null,
        file_1_length: null,
        video_1_url: null
      });

      //refresh the data
      setTimeout(() => this.getExerciseRecord(`${this.state.id}`), 3000);
      this.props.history.push(`/exercises/view/${this.state.id}`);
      //video/view/
      // window.location.reload(true);
    };
  }

  handleDeletePoster2Button = (event) => {
    alert('remove this file');

    // const {
    //   file_2_file,
    //   file_2_description,
    //   file_2_gender,
    //   file_2_length
    //   // small_poster_file_id
    // } = this.state;

    try {
      this.saveExerciseRecord({
        file_2_file: null,
        file_2_description: "",
        file_2_gender: null,
        file_2_length: ""
      })

    } catch (e) {
      console.log(e)
    } finally {
      this.setState({
        file_2_file: "",
        file_2_description: "",
        file_2_gender: null,
        file_2_length: null,
        video_2_url: null
      });

      //refresh the data
      setTimeout(() => this.getExerciseRecord(`${this.state.id}`), 3000);
      this.props.history.push(`/exercises/view/${this.state.id}`);
      //video/view/
      //window.location.reload(true);
    };
  }

  handleDeletePoster3Button = (event) => {
    alert('remove this file');

    // const {
    //   file_3_file,
    //   file_3_description,
    //   file_3_gender,
    //   file_3_length
    //   // small_poster_file_id
    // } = this.state;

    try {
      this.saveExerciseRecord({
        file_3_file: null,
        file_3_description: "",
        file_3_gender: null,
        file_3_length: ""
      })

    } catch (e) {
      console.log(e)
    } finally {
      this.setState({
        file_3_file: "",
        file_3_description: "",
        file_3_gender: null,
        file_3_length: null,
        video_3_url: null
      });

      //refresh the data
      setTimeout(() => this.getExerciseRecord(`${this.state.id}`), 3000);
      this.props.history.push(`/exercises/view/${this.state.id}`);
      //video/view/
      //window.location.reload(true);
    };
  }

  handleDeletePoster4Button = (event) => {
    alert('remove this file');

    // const {
    //   file_4_file,
    //   file_4_description,
    //   file_4_gender,
    //   file_4_length
    //   // small_poster_file_id
    // } = this.state;

    try {
      this.saveExerciseRecord({
        file_4_file: null,
        file_4_description: "",
        file_4_gender: null,
        file_4_length: ""
      })

    } catch (e) {
      console.log(e)
    } finally {
      this.setState({
        file_4_file: "",
        file_4_description: "",
        file_4_gender: null,
        file_4_length: null,
        video_4_url: null
      });

      //refresh the data
      setTimeout(() => this.getExerciseRecord(`${this.state.id}`), 3000);
      this.props.history.push(`/exercises/view/${this.state.id}`);
      //video/view/
      //window.location.reload(true);
    };
  }

  handleDeletePoster5Button = (event) => {
    alert('remove this file');

    // const {
    //   file_5_file,
    //   file_5_description,
    //   file_5_gender,
    //   file_5_length
    //   // small_poster_file_id
    // } = this.state;

    try {
      this.saveExerciseRecord({
        file_5_file: null,
        file_5_description: "",
        file_5_gender: null,
        file_5_length: ""
      })

    } catch (e) {
      console.log(e)
    } finally {
      this.setState({
        file_5_file: "",
        file_5_description: "",
        file_5_gender: null,
        file_5_length: null,
        video_5_url: null
      });

      //refresh the data
      setTimeout(() => this.getExerciseRecord(`${this.state.id}`), 3000);
      this.props.history.push(`/exercises/view/${this.state.id}`);
      //video/view/
      //window.location.reload(true);
    };
  }

  handleDeletePoster6Button = (event) => {
    alert('remove this file');

    // const {
    //   file_6_file,
    //   file_6_description,
    //   file_6_gender,
    //   file_6_length
    //   // small_poster_file_id
    // } = this.state;

    try {
      this.saveExerciseRecord({
        file_6_file: null,
        file_6_description: "",
        file_6_gender: null,
        file_6_length: ""
      })

    } catch (e) {
      console.log(e)
    } finally {
      this.setState({
        file_6_file: "",
        file_6_description: "",
        file_6_gender: null,
        file_6_length: null,
        video_6_url: null
      });

      //refresh the data
      setTimeout(() => this.getExerciseRecord(`${this.state.id}`), 3000);
      this.props.history.push(`/exercises/view/${this.state.id}`);
      //video/view/
      //window.location.reload(true);
    };

  }

  onRadioGender1Change = (e) => {
    // console.log(`radio checked:${e.target.value}`);
    this.setState({
      file_1_gender: e.target.value
    });
  }

  onRadioGender2Change = (e) => {
    // console.log(`radio checked:${e.target.value}`);
    this.setState({
      file_2_gender: e.target.value
    });
  }

  onRadioGender3Change = (e) => {
    // console.log(`radio checked:${e.target.value}`);
    this.setState({
      file_3_gender: e.target.value
    });
  }

  onRadioGender4Change = (e) => {
    // console.log(`radio checked:${e.target.value}`);
    this.setState({
      file_4_gender: e.target.value
    });
  }

  onRadioGender5Change = (e) => {
    // console.log(`radio checked:${e.target.value}`);
    this.setState({
      file_5_gender: e.target.value
    });
  }

  onRadioGender6Change = (e) => {
    // console.log(`radio checked:${e.target.value}`);
    this.setState({
      file_6_gender: e.target.value
    });
  }

  handle_submit = async event => {
    event.preventDefault();
    // console.log(event)
    this.setState({ isLoading: true });

    const {
      // id,
      // aws_cognito_id,
      // file_id,
      id,
      file_1_file,
      file_2_file,
      file_3_file,
      file_4_file,
      file_5_file,
      file_6_file,
      file_1_description,
      file_1_length,
      file_1_gender,
      file_2_description,
      file_2_length,
      file_2_gender,
      file_3_description,
      file_3_length,
      file_3_gender,
      file_4_description,
      file_4_length,
      file_4_gender,
      file_5_description,
      file_5_length,
      file_5_gender,
      file_6_description,
      file_6_length,
      file_6_gender


    } = this.state;
    try {
      await this.saveExerciseRecord({
        // aws_cognito_id: aws_cognito_id,
        // file_id,
        id,
        file_1_file,
        file_2_file,
        file_3_file,
        file_4_file,
        file_5_file,
        file_6_file,
        file_1_description,
        file_1_length,
        file_1_gender,
        file_2_description,
        file_2_length,
        file_2_gender,
        file_3_description,
        file_3_length,
        file_3_gender,
        file_4_description,
        file_4_length,
        file_4_gender,
        file_5_description,
        file_5_length,
        file_5_gender,
        file_6_description,
        file_6_length,
        file_6_gender
        // middle_name: middle_name,
        // last_name: last_name,
      });
      // this.props.history.push("/");
      // this.props.reload_user(this.state.aws_cognito_id);
    } catch (e) {
      // alert(e);
      // try {
      //   await this.saveExerciseRecord({
      //     // aws_cognito_id: aws_cognito_id,
      //     first_name: first_name,
      //     // middle_name: middle_name,
      //     last_name: last_name,
      //   });
      //   // this.props.history.push("/");
      //   // this.props.reload_user(this.state.aws_cognito_id);
      // } catch (e) {
      // alert(e);
      openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
      // }

      // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
    }

    //refresh data
    setTimeout(() => this.props.history.push(`/exercises/view/${this.state.id}`), 3000);

  };




  render() {
    // console.log(this.state);

    if (!this.props.exercise_id) return null;

    const radio_options_yes_no = [
      { label: "Male", value: 1 },
      { label: "Female", value: 2 },
    ]

    return (

      <React.Fragment>
        <Form layout="vertical" onSubmit={this.handle_submit}>

          <Row gutter={[96, 24]}>

            <Col xs={12}>
              <Card title="Exercise Version 1">
                {!this.state.file_1_file && <React.Fragment>
                  <Form.Item
                    label="Video Upload"
                    help={this.state.upload_error ? this.state.upload_error : "Please upload .mp4 video file only."}
                  >
                    <Dragger
                      {...this.uploader_props}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">
                        Support for a single video file only.
                </p>
                    </Dragger>
                  </Form.Item>
                </React.Fragment>
                }
                {this.state.file_1_file &&
                  <React.Fragment>
                    <Row gutter={[12, 0]}>
                      <Col xs={12}>

                        {this.state.video_1_url && <React.Fragment>
                          <Form.Item>
                            {/*<p>{this.state.file_1_file}</p>*/}
                            <div className='player-wrapper'>
                              <ReactPlayer url={this.state.video_1_url} //'https://tom-test-1234.s3-ap-southeast-1.amazonaws.com/Saturday+1.mp4'
                                //playing
                                width='100%'
                                height='100%'
                                controls
                              />
                            </div>
                            <Button
                              type="primary"
                              onClick={() => this.handleDeletePoster1Button(this.state.file_1_file)}
                            >
                              Remove
                          </Button>
                          </Form.Item>
                        </React.Fragment>
                        }
                      </Col>
                    </Row>
                  </React.Fragment>
                }

                <Form.Item
                  label="1-line Description"
                >
                  <Input
                    id="file_1_description"
                    value={this.state.file_1_description}
                    onChange={event => {
                      this.handleChange(event);
                    }}
                  />
                </Form.Item>
                <Row gutter={[96, 0]}>
                  <Col xs={12}>
                    <Form.Item
                      label="Length"
                      help="Specify length in seconds (e.g. 90 secs)"
                    >
                      <InputNumber
                        id="file_1_length"
                        value={this.state.file_1_length}
                        min={0}
                        max={600}
                        onChange={value => {
                          this.handleFile1NumberonChange(value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      label="Gender"
                    >
                      <Radio.Group
                        onChange={this.onRadioGender1Change}
                        value={this.state.file_1_gender || ""}
                        options={radio_options_yes_no}
                        optionType="button"
                        buttonStyle="solid"
                      >
                        <Radio value={1}>Male</Radio>
                        <Radio value={2}>Female</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col xs={12}>
              <Card title="Exercise Version 2">
                {!this.state.file_2_file && <React.Fragment>
                  <Form.Item
                    label="Video Upload"
                    help={this.state.upload_error ? this.state.upload_error : "Please upload .mp4 video file only."}
                  >
                    <Dragger
                      {...this.uploader_2_props}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">
                        Support for a single video file only.
                  </p>
                    </Dragger>
                  </Form.Item>
                </React.Fragment>
                }
                {this.state.file_2_file &&
                  <React.Fragment>
                    <Row gutter={[12, 0]}>
                      <Col xs={12}>

                        {this.state.video_2_url && <React.Fragment>
                          <Form.Item>
                            {/*<p>{this.state.file_2_file}</p>*/}
                            <div className='player-wrapper'>
                              <ReactPlayer url={this.state.video_2_url} //'https://tom-test-1234.s3-ap-southeast-1.amazonaws.com/Saturday+1.mp4'
                                //playing
                                width='100%'
                                height='100%'
                                controls
                              />
                            </div>
                            <Button
                              type="primary"
                              onClick={() => this.handleDeletePoster2Button(this.state.file_2_file)}
                            >
                              Remove
                            </Button>
                          </Form.Item>
                        </React.Fragment>
                        }
                      </Col>
                    </Row>
                  </React.Fragment>
                }
                <Form.Item
                  label="1-line Description"
                >
                  <Input
                    id="file_2_description"
                    value={this.state.file_2_description}
                    onChange={event => {
                      this.handleChange(event);
                    }}
                  />
                </Form.Item>
                <Row gutter={[96, 0]}>
                  <Col xs={12}>

                    <Form.Item
                      label="Length"
                      help="Specify length in seconds (e.g. 90 secs)"
                    >
                      <InputNumber
                        id="file_2_length"
                        value={this.state.file_2_length}
                        min={0}
                        max={600}
                        onChange={value => {
                          this.handleFile2NumberonChange(value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      label="Gender"
                    >
                      <Radio.Group
                        onChange={this.onRadioGender2Change}
                        value={this.state.file_2_gender || ""}
                        options={radio_options_yes_no}
                        optionType="button"
                        buttonStyle="solid"
                      >
                        <Radio value={1}>Male</Radio>
                        <Radio value={2}>Female</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>

              </Card>
            </Col>
          </Row>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Card title="Exercise Version 3">
                {!this.state.file_3_file && <React.Fragment>
                  <Form.Item
                    label="Video Upload"
                    help={this.state.upload_error ? this.state.upload_error : "Please upload .mp4 video file only."}
                  >
                    <Dragger
                      {...this.uploader_3_props}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">
                        Support for a single video file only.
                  </p>
                    </Dragger>
                  </Form.Item>
                </React.Fragment>
                }
                {this.state.file_3_file &&
                  <React.Fragment>
                    <Row gutter={[12, 0]}>
                      <Col xs={12}>

                        {this.state.video_3_url && <React.Fragment>
                          <Form.Item>
                            {/*<p>{this.state.file_3_file}</p>*/}
                            <div className='player-wrapper'>
                              <ReactPlayer url={this.state.video_3_url} //'https://tom-test-1234.s3-ap-southeast-1.amazonaws.com/Saturday+1.mp4'
                                //playing
                                width='100%'
                                height='100%'
                                controls
                              />
                            </div>
                            <Button
                              type="primary"
                              onClick={() => this.handleDeletePoster3Button(this.state.file_3_file)}
                            >
                              Remove
                            </Button>
                          </Form.Item>
                        </React.Fragment>
                        }
                      </Col>
                    </Row>
                  </React.Fragment>
                }
                <Form.Item
                  label="1-line Description"
                >
                  <Input
                    id="file_3_description"
                    value={this.state.file_3_description}
                    onChange={event => {
                      this.handleChange(event);
                    }}
                  />
                </Form.Item>
                <Row gutter={[96, 0]}>
                  <Col xs={12}>
                    <Form.Item
                      label="Length"
                      help="Specify length in seconds (e.g. 90 secs)"
                    >
                      <InputNumber
                        id="file_3_length"
                        value={this.state.file_3_length}
                        min={0}
                        max={600}
                        onChange={value => {
                          this.handleFile3NumberonChange(value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      label="Gender"
                    >
                      <Radio.Group
                        onChange={this.onRadioGender3Change}
                        value={this.state.file_3_gender || ""}
                        options={radio_options_yes_no}
                        optionType="button"
                        buttonStyle="solid"
                      >
                        <Radio value={1}>Male</Radio>
                        <Radio value={2}>Female</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={12}>
              <Card title="Exercise Version 4">
                {!this.state.file_4_file && <React.Fragment>
                  <Form.Item
                    label="Video Upload"
                    help={this.state.upload_error ? this.state.upload_error : "Please upload .mp4 video file only."}
                  >
                    <Dragger
                      {...this.uploader_4_props}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">
                        Support for a single video file only.
                  </p>
                    </Dragger>
                  </Form.Item>
                </React.Fragment>
                }
                {this.state.file_4_file &&
                  <React.Fragment>
                    <Row gutter={[12, 0]}>
                      <Col xs={12}>

                        {this.state.video_4_url && <React.Fragment>
                          <Form.Item>
                            {/*<p>{this.state.file_4_file}</p>*/}
                            <div className='player-wrapper'>
                              <ReactPlayer url={this.state.video_4_url} //'https://tom-test-1234.s3-ap-southeast-1.amazonaws.com/Saturday+1.mp4'
                                //playing
                                width='100%'
                                height='100%'
                                controls
                              />
                            </div>
                            <Button
                              type="primary"
                              onClick={() => this.handleDeletePoster4Button(this.state.file_4_file)}
                            >
                              Remove
                            </Button>
                          </Form.Item>
                        </React.Fragment>
                        }
                      </Col>
                    </Row>
                  </React.Fragment>
                }

                <Form.Item
                  label="1-line Description"
                >
                  <Input
                    id="file_4_description"
                    value={this.state.file_4_description}
                    onChange={event => {
                      this.handleChange(event);
                    }}
                  />
                </Form.Item>
                <Row gutter={[96, 0]}>
                  <Col xs={12}>
                    <Form.Item
                      label="Length"
                      help="Specify length in seconds (e.g. 90 secs)"
                    >
                      <InputNumber
                        id="file_4_length"
                        value={this.state.file_4_length}
                        min={0}
                        max={600}
                        onChange={value => {
                          this.handleFile4NumberonChange(value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      label="Gender"
                    >
                      <Radio.Group
                        onChange={this.onRadioGender4Change}
                        value={this.state.file_4_gender || ""}
                        options={radio_options_yes_no}
                        optionType="button"
                        buttonStyle="solid"
                      >
                        <Radio value={1}>Male</Radio>
                        <Radio value={2}>Female</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Card title="Exercise Version 5">
                {!this.state.file_5_file && <React.Fragment>
                  <Form.Item
                    label="Video Upload"
                    help={this.state.upload_error ? this.state.upload_error : "Please upload .mp4 video file only."}
                  >
                    <Dragger
                      {...this.uploader_5_props}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">
                        Support for a single video file only.
                  </p>
                    </Dragger>
                  </Form.Item>
                </React.Fragment>
                }
                {this.state.file_5_file &&
                  <React.Fragment>
                    <Row gutter={[12, 0]}>
                      <Col xs={12}>

                        {this.state.video_5_url && <React.Fragment>
                          <Form.Item>
                            {/*<p>{this.state.file_5_file}</p>*/}
                            <div className='player-wrapper'>
                              <ReactPlayer url={this.state.video_5_url} //'https://tom-test-1234.s3-ap-southeast-1.amazonaws.com/Saturday+1.mp4'
                                //playing
                                width='100%'
                                height='100%'
                                controls
                              />
                            </div>
                            <Button
                              type="primary"
                              onClick={() => this.handleDeletePoster5Button(this.state.file_5_file)}
                            >
                              Remove
                            </Button>
                          </Form.Item>
                        </React.Fragment>
                        }
                      </Col>
                    </Row>
                  </React.Fragment>
                }

                <Form.Item
                  label="1-line Description"
                >
                  <Input
                    id="file_5_description"
                    value={this.state.file_5_description}
                    onChange={event => {
                      this.handleChange(event);
                    }}
                  />
                </Form.Item>
                <Row gutter={[96, 0]}>
                  <Col xs={12}>

                    <Form.Item
                      label="Length"
                      help="Specify length in seconds (e.g. 90 secs)"
                    >
                      <InputNumber
                        id="file_5_length"
                        value={this.state.file_5_length}
                        min={0}
                        max={600}
                        onChange={value => {
                          this.handleFile5NumberonChange(value);
                        }}
                      />

                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      label="Gender"
                    >
                      <Radio.Group
                        onChange={this.onRadioGender5Change}
                        value={this.state.file_5_gender || ""}
                        options={radio_options_yes_no}
                        optionType="button"
                        buttonStyle="solid"
                      >
                        <Radio value={1}>Male</Radio>
                        <Radio value={2}>Female</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={12}>
              <Card title="Exercise Version 6">
                {!this.state.file_6_file && <React.Fragment>
                  <Form.Item
                    label="Video Upload"
                    help={this.state.upload_error ? this.state.upload_error : "Please upload .mp4 video file only."}
                  >
                    <Dragger
                      {...this.uploader_6_props}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">
                        Support for a single video file only.
                  </p>
                    </Dragger>
                  </Form.Item>
                </React.Fragment>
                }
                {this.state.file_6_file &&
                  <React.Fragment>
                    <Row gutter={[12, 0]}>
                      <Col xs={12}>

                        {this.state.video_6_url && <React.Fragment>
                          <Form.Item>
                            {/*<p>{this.state.file_6_file}</p>*/}
                            <div className='player-wrapper'>
                              <ReactPlayer url={this.state.video_6_url} //'https://tom-test-1234.s3-ap-southeast-1.amazonaws.com/Saturday+1.mp4'
                                //playing
                                width='100%'
                                height='100%'
                                controls
                              />
                            </div>
                            <Button
                              type="primary"
                              onClick={() => this.handleDeletePoster6Button(this.state.file_6_file)}
                            >
                              Remove
                            </Button>
                          </Form.Item>
                        </React.Fragment>
                        }
                      </Col>
                    </Row>
                  </React.Fragment>
                }
                <Form.Item
                  label="1-line Description"
                >
                  <Input
                    id="file_6_description"
                    value={this.state.file_6_description}
                    onChange={event => {
                      this.handleChange(event);
                    }}
                  />
                </Form.Item>
                <Row gutter={[96, 0]}>
                  <Col xs={12}>
                    <Form.Item
                      label="Length"
                      help="Specify length in seconds (e.g. 90 secs)"
                    >
                      <InputNumber
                        id="file_6_length"
                        value={this.state.file_6_length}
                        min={0}
                        max={600}
                        onChange={value => {
                          this.handleFile6NumberonChange(value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      label="Gender"
                    >
                      <Radio.Group
                        onChange={this.onRadioGender6Change}
                        value={this.state.file_6_gender || ""}
                        options={radio_options_yes_no}
                        optionType="button"
                        buttonStyle="solid"
                      >
                        <Radio value={1}>Male</Radio>
                        <Radio value={2}>Female</Radio>
                      </Radio.Group>
                    </Form.Item>

                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          {/*<Col xs={12}>

            <Form.Item
              label="Poster Upload"
              help={this.state.poster_upload_error ? this.state.poster_upload_error : "Please upload a poster image as close to 150px x 150px resolution as possible."}
            >
              <Dragger
                {...this.poster_uploader_props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                  Support for a single image file only.
                </p>
              </Dragger>
            </Form.Item>
          </Col>*/}

          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button type="primary" htmlType="submit" onClick={this.handle_submit}>
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const ExerciseFileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExerciseFile);

export default withRouter(ExerciseFileContainer);
