
import React, { Component } from 'react';
import { Table } from 'antd';

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";
import { NavLink } from "react-router-dom";

const moment = require("moment");
// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Divider } from 'antd';
// import { Card, Button } from 'antd';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

//display id, nickname, product nickname, term and price
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    render: text => <a>{text.toString().padStart(5, '0')}</a>,
  },
  // {
  //   title: 'description',
  //   dataIndex: 'description',
  // },
  // {
  //   title: 'plans_nickname',
  //   render: (text, record) => (
  //     <span>
  //       <p>{`${record.plans_nickname}`} </p>
  //     </span>
  //   ),
  // },
  {
    title: 'Coupon Code',
    render: (text, record) => (
      <span>
        <p>{`${record.coupon_code}`} </p>
      </span>
    ),
  },
  {
    title: 'Status',
    render: (text, record) => {
      let txt = ""
      switch (record.status_id) {
        case 1:
          txt = "Current";
          break
        case 2:
          txt = "Expired";
          break
      }
      return (
        <span>
          <p>{`${txt}`} </p>
        </span>
      )
    },
  },
  {
    title: 'Coupon Expiry',
    render: (text, record) => (
      <span>
        <p>{`${moment(record.end_date).format("DD-MM-YYYY")}`} </p>
      </span>
    ),
  },
  {
    title: 'Coupon Duration',
    dataIndex: 'coupon_duration',
  },
  {
    title: 'Percent Off',
    dataIndex: 'percentage_off',
  },
  {
    title: 'Fixed Discount',
    dataIndex: 'fixed_amount_discount',
  },
  {
    title: 'Actions',
    key: 'action',
    render: (text, record) => (
      <span>
        <NavLink to={`/promocodes/view/${record.id}`} >View</NavLink>
        {/* <Divider type="vertical" />
        <a>Delete</a> */}
      </span>
    ),
  },
];

class PromoCodeListPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: []
    }
  }

  //display id, nickname, product nickname, term and price
  componentDidMount() {
    API.get("promocodes", `/list`)
      .then(response => {
        console.log(response);
        // console.log(response.payload);

        let dataSource = [];
        for (let i = 0; i < response.payload.length; i++) {
          dataSource.push({
            key: response.payload[i].id,
            id: response.payload[i].id,
            status_id: response.payload[i].status_id,
            // description: response.payload[i].description,
            percentage_off: response.payload[i].percentage_off,
            fixed_amount_discount: response.payload[i].fixed_amount_discount,
            // plans_nickname: response.payload[i].plans_nickname,
            end_date: response.payload[i].end_date,
            coupon_code: response.payload[i].coupon_code,
            coupon_duration: response.payload[i].coupon_duration,
          });
        }
        // console.log(dataSource);
        this.setState({
          dataSource: dataSource
        })
      })
      .catch(error => {
        console.log(error);
      });
  }


  render() {
    // console.log(this.state);
    // console.log(this.dataSource);
    // console.log(this.props);
    return (
      <React.Fragment>
        <Table
          columns={columns}
          dataSource={this.state.dataSource}
          pagination={{
            pageSizeOptions: ['10', '20', '30'],
            showSizeChanger: true
          }}
        />
      </React.Fragment>
    );
  }
}

export default PromoCodeListPageComponent;
