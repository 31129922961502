import React, { Component } from 'react';
import withStyles from 'react-jss'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";

//import { Layout } from 'antd';
// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Steps } from 'antd';
// import { message } from 'antd';
// import { Card, Button } from 'antd';
// import { notification } from 'antd';

import { Tabs } from 'antd';

import StaffSummary from './StaffSummary';
import StaffContact from './StaffContact';
import StaffAddress from './StaffAddress';


const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: "right"
  },
};
const { TabPane } = Tabs;
function callback(key) {
  //console.log(key);
}

class StaffViewPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_step: 0,
    }
  }

  componentDidMount() {
    
  }

  handleSubmit = async (event) => {
    console.log(this.props.staffs.new_staff_data)
    API.post("staff", `/create/in-database`, {
      body: {
        ...this.props.staffs.new_staff_data
      }
    })
      .then(database_result => {
        console.log(database_result)
        // openNotificationWithIcon('success', "Record Created", "New client has been successfully created.")
        return database_result;
      })
      .then(database_result => {
        // console.log(database_result)
        // openNotificationWithIcon('success', "Record Created", "New client has been successfully created.")
        this.props.history.push(`/staff/list`);
      })
      .catch(error => {
        console.log(error);
      });
  }


  render() {
    // const { classes } = this.props;
    // const { current_step } = this.state;

    // console.log(this.props);
    if (!this.props.staffs && !this.props.staffs.new_staff_data) return null;

    return (

      <React.Fragment>

        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Staff Summary" key="1">

            <StaffSummary
              staff_id={this.props.staff_id}
             />

          </TabPane>
          <TabPane tab="Contact" key="2">
            <StaffContact 
              staff_id={this.props.staff_id}
            />
          </TabPane>
          <TabPane tab="Address" key="3">
            <StaffAddress 
              staff_id={this.props.staff_id}
            />
          </TabPane>
        </Tabs>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    staffs: state.staffs,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // startNewClientCreation: () => {
    //   dispatch(startNewClientCreation())
    // }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const StaffViewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(StaffViewPageComponent)));

export default StaffViewPageComponentContainer;
