
import React, { Component } from 'react';
import { connect } from "react-redux";

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";

//import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input } from 'antd';
import { Checkbox } from 'antd';

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class StaffSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //is_trainer: false
    }
    //console.log(this.props);
  }


  async componentDidMount() {
    const staff = await this.getStaffRecord(
      this.props.staff_id
    );
    // console.log(staff)
    this.setState({
      ...staff.payload
    })
}

  async componentWillReceiveProps(nextProps, nextContext) {
    //console.log(nextProps)
    if (nextProps.app_state.current_user_id && nextProps.app_state.current_user_aws_cognito_id) {
      const staff = await this.getStaffRecord(
        nextProps.staff_id
      );
      // console.log(staff)
      this.setState({
        ...staff.payload
      })
    }
  }


  async getStaffRecord(user_id) {
    return API.get("staff", `/get/id/${user_id}`);
  }

  async saveStaffRecord(staff_record) {
    console.log(staff_record);
    await API.put("staff", `/update/${this.state.id}`, {
      body: staff_record
    });
    openNotificationWithIcon('success', "Save Successful", "Your data has been saved successfully")
  }


  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleCheckbox = event => {
    console.log(`checked = ${event.target.checked}`);
    this.setState({
      is_trainer: event.target.checked
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    // console.log(event)
    this.setState({ isLoading: true });

    const {
      // id,
      // aws_cognito_id,
      first_name,
      middle_name,
      last_name,
      is_trainer
      // email,
    } = this.state;
    try {
      await this.saveStaffRecord({
        // aws_cognito_id: aws_cognito_id,
        first_name: first_name,
        middle_name: middle_name,
        last_name: last_name,
        is_trainer
      });
      // this.props.history.push("/");
      // this.props.reload_user(this.state.aws_cognito_id);
    } catch (e) {
      // alert(e);
      try {
        await this.saveStaffRecord({
          // aws_cognito_id: aws_cognito_id,
          first_name: first_name,
          middle_name: middle_name,
          last_name: last_name,
          is_trainer: is_trainer
        });
        // this.props.history.push("/");
        // this.props.reload_user(this.state.aws_cognito_id);
      } catch (e) {
        // alert(e);
        openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
        // this.setState({ isLoading: false });
      }

      // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
    }
  };

  render() {
    console.log(this.state);

    return (

      <React.Fragment>
        <Form layout="vertical">

          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item label="First Name" >
                    <Input
                      id="first_name"
                      value={this.state.first_name}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Middle Name(s)" >
                    <Input
                      id="middle_name"
                      value={this.state.middle_name}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Last Name" >
                <Input
                  id="last_name"
                  value={this.state.last_name}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Trainer" >
                <Checkbox checked={this.state.is_trainer} onChange={this.handleCheckbox}>Is a trainer</Checkbox>
              </Form.Item>
            </Col>
            
            <Col xs={12}>
              <Form.Item label="E-mail">
                <Input
                  id="email"
                  value={this.state.email}
                  disabled={true}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button 
                type="primary" 
                htmlType="submit" 
                onClick={this.handleSubmit}
              >
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const StaffSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StaffSummary);

export default StaffSummaryContainer;
