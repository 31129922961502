
import React, { Component } from 'react';
import { connect } from "react-redux";

// import { get_country_value } from "../../../library/countries";

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";

//import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input } from 'antd';
import { Select } from 'antd';

import { Typography } from 'antd';

const { Title } = Typography;

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class UserAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  async componentDidMount() {
    // await API.get("countries", `list`).then(response => {
    //     console.log(response);
    //     console.log(response.payload);
    //   }
    // ).catch(error => {
    //   console.log(error);
    // });

    const user = await this.getUserProfile(
      this.props.app_state.current_user_id
    );
    console.log(user)
    this.setState({
      ...user.payload
    })
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.app_state.current_user_id && nextProps.app_state.current_user_aws_cognito_id) {
      const user = await this.getUserProfile(
        nextProps.app_state.current_user_id
      );
      console.log(user)
      this.setState({
        ...user.payload
      })
    }
  }

  async getUserProfile(user_id) {
    return API.get("staff", `/get/id/${user_id}`);
  }

  async saveUserProfile(user) {
    await API.put("staff", `/update/${this.state.id}`, {
      body: user
    });
    openNotificationWithIcon('success', "Save Successful", "Your data has been saved successfully")
  }

  handleChange = (event) => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
    // this.props.updateNewClientCreation(event.target.id, event.target.value);

  };

  handleSelectChange = (item_id, option, name) => {
    // console.log(item_id)
    // console.log(option)
    // console.log(name)
    this.setState({
      [name]: item_id
    });
  };
  handleSelectChange_street_address_country = (value) => {
    this.setState({
      "street_address_country": value
    });
    //this.props.updateNewClientCreation("street_address_country", value);
  };

  handleSelectChange_postal_address_country = (value) => {
    this.setState({
      "postal_address_country": value
    });
    //this.props.updateNewClientCreation("postal_address_country", value);
  };

  handleSubmit = async event => {
    event.preventDefault();
    // console.log(event)
    this.setState({ isLoading: true });

    const {
      // aws_cognito_id,
      // first_name,
      // middle_name,
      // last_name,
      // email,
      street_address_line_1,
      street_address_line_2,
      street_address_suburb,
      street_address_state,
      street_address_postcode,
      street_address_country,
      postal_address_line_1,
      postal_address_line_2,
      postal_address_suburb,
      postal_address_state,
      postal_address_postcode,
      postal_address_country
    } = this.state;
    try {
      await this.saveUserProfile({
        // aws_cognito_id: aws_cognito_id,
        // first_name: first_name,
        // middle_name: middle_name,
        // last_name: last_name,
        street_address_line_1,
        street_address_line_2,
        street_address_suburb,
        street_address_state,
        street_address_postcode,
        street_address_country,
        postal_address_line_1,
        postal_address_line_2,
        postal_address_suburb,
        postal_address_state,
        postal_address_postcode,
        postal_address_country
      });
      // this.props.history.push("/");
      // this.props.reload_user(this.state.aws_cognito_id);
    } catch (e) {
      // alert(e);
      try {
        await this.saveUserProfile({
          // aws_cognito_id: aws_cognito_id,
          // first_name: first_name,
          // middle_name: middle_name,
          // last_name: last_name,
          street_address_line_1,
          street_address_line_2,
          street_address_suburb,
          street_address_state,
          street_address_postcode,
          street_address_country,
          postal_address_line_1,
          postal_address_line_2,
          postal_address_suburb,
          postal_address_state,
          postal_address_postcode,
          postal_address_country
        });
        // this.props.history.push("/");
        // this.props.reload_user(this.state.aws_cognito_id);
      } catch (e) {
        // alert(e);
        openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
        // this.setState({ isLoading: false });
      }

      // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
    }
  };

  render() {
    console.log(this.state);
    console.log(this.props);

    const { Option } = Select;

    if (!this.props.app_state || !this.props.app_state.data_countries_list) return null;

    const country_list_select_options = this.props.app_state.data_countries_list.map(d => <Option key={d.id} >{d.full_name}</Option>);


    return (

      <React.Fragment>
        <Form layout="vertical">
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Title level={4}>Street Address</Title>
              <Form.Item
                label="Street Address (Line 1)"
              >
                <Input
                  id="street_address_line_1"
                  value={this.state.street_address_line_1}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Street Address (Line 2)"
              >
                <Input
                  id="street_address_line_2"
                  value={this.state.street_address_line_2}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item label="Suburb">
                    <Input
                      id="street_address_suburb"
                      value={this.state.street_address_suburb}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item label="State">
                    <Input
                      id="street_address_state"
                      value={this.state.street_address_state}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={4}>
                  <Form.Item label="PostCode">
                    <Input
                      id="street_address_postcode"
                      value={this.state.street_address_postcode}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Country">
                <Select
                  id="street_address_country"
                  showSearch
                  value={
                    typeof this.state.street_address_country === "number"
                      ? this.state.street_address_country.toString()
                      : this.state.street_address_country
                  }
                  placeholder="Select Country"
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  }
                  onChange={this.handleSelectChange_street_address_country}
                >
                  {country_list_select_options}
                </Select>
              </Form.Item>
              <br />

            </Col>
            <Col xs={12}>

              <Title level={4}>Postal Address</Title>
              <Form.Item
                label="Postal Address (Line 1)"
              >
                <Input
                  id="postal_address_line_1"
                  value={this.state.postal_address_line_1}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Postal Address (Line 2)"
              >
                <Input
                  id="postal_address_line_2"
                  value={this.state.postal_address_line_2}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item label="Suburb">
                    <Input
                      id="postal_address_suburb"
                      value={this.state.postal_address_suburb}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item label="State">
                    <Input
                      id="postal_address_state"
                      value={this.state.postal_address_state}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={4}>
                  <Form.Item label="PostCode">
                    <Input
                      id="postal_address_postcode"
                      value={this.state.postal_address_postcode}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Country">
                <Select
                  id="postal_address_country"
                  showSearch
                  value={
                    typeof this.state.postal_address_country === "number"
                      ? this.state.postal_address_country.toString()
                      : this.state.postal_address_country
                  }
                  placeholder="Select Country"
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  }
                  onChange={this.handleSelectChange_postal_address_country}
                >
                  {country_list_select_options}
                </Select>
              </Form.Item>
            </Col>
          </Row>


          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={this.handleSubmit}
              >
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    //clients: state.clients,
    //language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // updateNewClientCreation: (key, value) => {
    //   dispatch(updateNewClientCreation(key, value))
    // }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const UserAddressContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAddress);

export default UserAddressContainer;
