import React, { Component } from 'react';
// import withStyles from 'react-jss'
// import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Steps } from 'antd';
// import { message } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';

import { Row, Col } from 'antd';
import { Form, Input } from 'antd';

import { Select } from 'antd';

const { Option } = Select;
const { TextArea } = Input;

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

// const { Step } = Steps;

class CategorySubNewPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    //this.props.startNewClientCreation();
    API.get("video-categories", `/main/list`).then(
      response => {
        console.log(response.payload);
        this.setState({
          data_main_categories: response.payload
        })
      }
    );
  }

  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    openNotificationWithIcon('info', "Record Creation", "New category record creation is starting.")
    //console.log(event);
    console.log(this.state);
    //console.log(this.props.clients.new_client_data)
    const {
      // id,
      file_by,
      main_category_id,
      description_long,
      description_short,
      url_slug
    } = this.state;

    try {
      API.post("video-categories", `/create-sub`, {
        body: {
          // id: id,
          file_by: file_by,
          main_category_id: main_category_id,
          description_long: description_long,
          description_short: description_short,
          url_slug: url_slug
          //...this.props.clients.new_client_data
        }
      })
      .then(response => {
        console.log(response)
        openNotificationWithIcon('success', "Record Created", "New category has been successfully created.")
        
        return response;
      })
      .then(database_result => {
        console.log(database_result)
        //this.props.history.push(`/tags/list`);
        window.location.assign(`/sub-categories/list`);
      })
      .catch(error => {
        console.log(error);
      });
    } catch (e) {
      alert(e);
      openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
      // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
    }

    this.setState({ isLoading: true });
    
  }

  handleSelectChange_main_category_id = (id) => {
    this.setState({
      main_category_id: id
    });
    API.get("video-categories", `/main/id/${id}`)
      .then((response) => {
        console.log(response)
        this.setState({
          main_category: response.payload,
        })
      }).catch(err => {
        console.log(err);
      });
  };

  onShortDescChange = ({ target: { value } }) => {
    this.setState({ 
      description_short: value 
    });
  };

  onLongDescChange = ({ target: { value } }) => {
    this.setState({ 
      description_long: value 
    });
  };

  render() {
    // console.log(this.state);
    // const { classes } = this.props;

    if (!this.state.data_main_categories) return null;
    const main_categories_option = this.state.data_main_categories.map(d => <Option key={d.id} >{d.file_by}</Option>);

    return (
      <React.Fragment>
        <Form layout="vertical">

          <Row gutter={[96, 24]}>
            <Col xs={12}>
              {/*<Form.Item
            label="ID"
          >
            <Input
              id="id"
              value={this.state.id}
              onChange={event => {
                this.handleChange(event);
              }}
            />
            </Form.Item>*/}
              <Form.Item
                label="Nickname"
              >
                <Input
                  id="file_by"
                  value={this.state.file_by}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Main Category"
              >
              <Select
              id="main_category_id"
              name="main_category_id"
              showSearch
              value={
                typeof this.state.main_category_id === "number"
                  ? this.state.main_category_id.toString()
                  : (this.state.main_category_id === " ")
                    ? "Select Main Category"
                    : this.state.main_category_id
              }
              placeholder="Select Category"
              showArrow={true}
              optionFilterProp="children"
              filterOption={(input, option) => {
                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
              onChange={this.handleSelectChange_main_category_id}
            >
              {main_categories_option}
            </Select>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Short Description"
              >
                <TextArea
                  value={this.state.description_short}
                  autoSize={{ minRows: 2, maxRows: 8 }}
                  onChange={this.onShortDescChange}
                />
              </Form.Item>

              <Form.Item
                label="Long Description"
              >
                <TextArea
                  value={this.state.description_long}
                  autoSize={{ minRows: 2, maxRows: 8 }}
                  onChange={this.onLongDescChange}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
            <Form.Item
                label="Url Slug"
              >
                <Input
                  id="url_slug"
                  value={this.state.url_slug}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
            </Col>

          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                disabled={!this.state.file_by || this.state.file_by === " "}
                htmlType="submit"
                onClick={this.handleSubmit}
              >
                Save
          </Button>
            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

// const mapStateToProps = state => {
//   return {
//     app_state: state.app_state,
//     language: state.language
//   };
// };

// const mapDispatchToProps = dispatch => {
//   return {
    // startNewClientCreation: () => {
    //   dispatch(startNewClientCreation())
    // },
    // updateNewClientCreation: (key, value) => {
    //   dispatch(updateNewClientCreation(key, value))
    // }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
//   }
// }

// const CategoryNewPageComponentContainer = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(withStyles(styles)(CategoryNewPageComponent)));

export default CategorySubNewPageComponent;
