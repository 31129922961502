import React, { Component } from 'react';
import withStyles from 'react-jss'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import {
//   startNewClientCreation

// } from "../../../../redux/actions/clients";

// import { API, Auth } from "aws-amplify";

//import { Layout } from 'antd';
// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Steps } from 'antd';
// import { message } from 'antd';
// import { Card, Button } from 'antd';
// import { notification } from 'antd';

import { Tabs } from 'antd';

import TagSummary from './tag_view/TagSummary';
// import TagBillingContainer from './tag_view/TagBilling';

const queryString = require('query-string');


const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: "right"
  },
};
const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}

class TagViewPageComponent extends Component {
  constructor(props) {
    super(props);
    const parsed = queryString.parse(this.props.location.search);
    this.state = {
      current_tab: parsed.tab || "summary",
    }
  }

  componentDidMount() {

  }

  // handleSubmit = async (event) => {
  //   console.log(this.props.clients.new_client_data)
  //   API.post("clients", `/create/in-database`, {
  //     body: {
  //       ...this.props.clients.new_client_data
  //     }
  //   })
  //     .then(database_result => {
  //       console.log(database_result)
  //       // openNotificationWithIcon('success', "Record Created", "New client has been successfully created.")
  //       return database_result;
  //     })
  //     .then(database_result => {
  //       // console.log(database_result)
  //       // openNotificationWithIcon('success', "Record Created", "New client has been successfully created.")
  //       this.props.history.push(`/clients/list`);
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }


  render() {
    // const { classes } = this.props;
    const { current_tab } = this.state;

    if (!this.props.clients && !this.props.clients.new_client_data) return null;

    return (

      <React.Fragment>

        <Tabs defaultActiveKey={current_tab} onChange={callback}>
          <TabPane tab="Tag Summary" key="summary">
            <TagSummary
              id={this.props.id}
            />
          </TabPane>
        </Tabs>
      </React.Fragment>
    );
  }
}

// export default withStyles(styles)(ClientNewPageComponent);

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // startNewClientCreation: () => {
    //   dispatch(startNewClientCreation())
    // }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const TagViewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(TagViewPageComponent)));

export default TagViewPageComponentContainer;
