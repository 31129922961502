
import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import { withRouter } from "react-router-dom";

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";

//import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input, Divider, InputNumber, DatePicker } from 'antd';
import { Select } from 'antd';

const { Option } = Select;

const form_layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

// var moment = require("moment");

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class PromoCodeSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  async componentDidMount() {
    const promocode = await this.getPromocodeRecord(
      this.props.promocode_id
    );
    console.log(promocode)
    this.setState({
      ...promocode.payload
    })
    switch (promocode.payload.status_id) {
      case 1:
        this.setState({
          status: "Current"
        })
        break;
      case 2:
        this.setState({
          status: "Expired"
        })
        break;
    }
    // await API.get("plans", `/list`).then(
    //   response => {
    //     console.log(response);

    //     this.setState({
    //       data_plan_list: response.payload
    //     })
    //   }
    // );
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    //console.log(nextProps)
    // if (nextProps.app_state.current_user_id && nextProps.app_state.current_user_aws_cognito_id) {
    //   const user = await this.getUserProfile(
    //     nextProps.app_state.current_user_aws_cognito_id
    //   );
    //   // console.log(user)
    //   this.setState({
    //     ...user.payload
    //   })
    // }

    // await API.get("plans", `/list`).then(
    //   response => {
    //     console.log(response);

    //     this.setState({
    //       data_plan_list: response.payload
    //     })
    //   }
    // );
  }

  async getPromocodeRecord(promocode_id) {
    return API.get("promocodes", `/id/${promocode_id}`);
  }

  // async savePlansRecord(product_record) {
  //   await API.put("plans", `/update/${this.state.id}`, {
  //     body: product_record
  //   });
  //   openNotificationWithIcon('success', "Save Successful", "Your Plan data has been saved successfully")
  // }

  async savePromocodeRecord(promocode_record) {
    //promocodes/update/{promocode_id}
    await API.put("promocodes", `/update/${this.state.id}`, {
      body: promocode_record
    });
    openNotificationWithIcon('success', "Save Successful", "Your Product data has been saved successfully")
  }

  // create_stripe_plan_id = () => {
  //   if (this.state.stripe_id) {
  //     openNotificationWithIcon('error', "Billing ID Exists", "A billing ID number already exists for this client")
  //     return;
  //   }
  //   // const {
  //   //   nickname,
  //   // } = this.state;

  //   let interval = "";
  //   switch (this.state.term) {
  //     case "1":
  //       interval = "day";
  //       break;
  //     case "2":
  //       interval = "week";
  //       break;
  //     case "3":
  //       interval = "month";
  //       break;
  //     case "4":
  //       interval = "year";
  //       break;
  //     default:
  //       break;
  //   }

  //   API.post("stripe", `/plans/create/${this.state.id}`, {
  //     body: {
  //       currency: "aud",
  //       interval,
  //       product: this.state.product_stripe_id,
  //       nickname: this.state.nickname,
  //       amount: Number.parseFloat(this.state.retail_price) * 100
  //     }
  //   })
  //     .then(response => {
  //       console.log(response)
  //       openNotificationWithIcon('success', "Record Created", "A Billing Account has been created for this client")
  //       this.setState({
  //         stripe_id: response.payload.stripe_result.id
  //       })
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });

  // }

  handleDateonChange = (date, dateString) => {
    console.log(date, dateString);
    // console.log(date);
    console.log(dateString);

    this.setState({
      end_date: dateString
    });
  }

  handleNumberonChange = (value) => {
    console.log('changed', value);
    this.setState({
      percentage_off: value * 0.01
    });
  }

  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSelectChange = (value) => {
    console.log(value);
    this.setState({
      plan_id: value
    });
  }

  handle_cancel = async event => {
    event.preventDefault();
    // console.log(event)
    this.setState({ isLoading: true });

    let {
      id,
      // plan_id,
      fixed_amount_discount,
      percentage_off,
      // description,
      coupon_code,
      coupon_duration,
      end_date
      // product_id,
      // product_nickname,
      // product_stripe_id,
      // nickname,
      // stripe_id,
      // term,
      // retail_price
    } = this.state;

    await API.post("email", `/interaction`, {
      body: {
        interaction: "Cancel Promo Code",
        data: {
          id,
          coupon_code,
          coupon_duration,
          percentage_off,
          fixed_amount_discount,
          end_date,
        },
        result: "Admin user requests promo code cancel"
      }
    })
      .then(response => {
        console.log(response);
        openNotificationWithIcon('success', "Cancel Requested", "Cancellation of this promo code has been requested.  Please give 30 minutes to apply.")
        this.props.history.push(`/promocodes/list`);

      })
      .catch(error => {
        console.log(error);
      });

  };

  render() {
    console.log(this.state);

    if (!this.props.app_state) return null;
    // const plan_list_select_options = this.state.data_plan_list.map(d => <Option key={d.id} >{d.nickname}</Option>);

    return (

      <React.Fragment>
        <Form
          {...form_layout}
          onSubmit={this.handle_cancel}>

          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Form.Item label="Status" >
                <Input
                  id="status"
                  disabled
                  value={this.state.status}
                  // onChange={event => {
                  //   this.handleChange(event);
                  // }}
                />
              </Form.Item>
              <Form.Item
                label="Coupon Code"
                help={"This is the code the customer will use (suggest make it short with no space"}
              >
                <Input
                  id="coupon_code"
                  value={this.state.coupon_code}
                  disabled
                // onChange={event => {
                //   this.handleChange(event);
                // }}
                />
              </Form.Item>
              <Form.Item
                label="End Date"
                help={"Code will automatically de-activate on this date"}
              >
                <DatePicker
                  placeholder={moment(this.state.end_date).format('DD-MM-YYYY')}
                  disabled
                  format={'DD-MM-YYYY'}
                // onChange={this.handleDateonChange}
                />
              </Form.Item>

              {/* <Form.Item
                label="Description"
                help={"For your internal use only"}
              >
                <Input
                  id="description"
                  value={this.state.description}
                // disabled
                />
              </Form.Item> */}
              {/* <Form.Item
                label="Percentage off"
                help={"must be between 0% and 100%"}
              >
                <InputNumber
                  defaultValue={this.state.percentage_off
                    ? this.state.percentage_off * 100
                    : "0"
                  }
                  min={0}
                  max={100}
                  disabled
                  formatter={value => `${value}%`}
                  parser={value => value.replace('%', '')}
                  // onChange={value => this.handleNumberonChange(value)}
                />
              </Form.Item> */}

              <Form.Item
                label="Percentage off"
                help={"must be between 0.00 and 1.00"}
              >
                <Input
                  id="percentage_off"
                  disabled
                  value={this.state.percentage_off}
                // onChange={event => {
                //   this.handleChange(event);
                // }}
                />
              </Form.Item>
              <Form.Item
                label="Fixed amount discount"
                help={"must be between 0.00 and 9999.99"}
              >
                <Input
                  id="fixed_amount_discount"
                  disabled
                  value={this.state.fixed_amount_discount}
                // onChange={event => {
                //   this.handleChange(event);
                // }}
                />
              </Form.Item>
              <Form.Item
                label="Coupon Duration"
              // help={"must be between 0.00 and 9999.99"}
              >
                <Input
                  id="coupon_duration"
                  disabled
                  value={this.state.coupon_duration}
                // onChange={event => {
                //   this.handleChange(event);
                // }}
                />
              </Form.Item>

              {/*<Form.Item 
                label="Retail Price (AUD$)"
              >
                <Input
                  id="retail_price"
                  value={this.state.retail_price}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
                </Form.Item>*/}

              {/* 
              <Form.Item
                label="End Date"
                help={"Code will automatically de-activate on this date"}
              >
                <DatePicker
                  placeholder={moment(this.state.end_date).format('DD-MM-YYYY')}
                  disabled
                  format={'DD-MM-YYYY'}
                  // onChange={this.handleDateonChange}
                />
              </Form.Item> */}




              {/*<Form.Item label="Plan Billing ID" >
                <Input
                  id="stripe_id"
                  value={this.state.stripe_id}
                  disabled
                />
                </Form.Item>*/}
            </Col>
            {/* <a onClick={this.create_stripe_plan_id}>Create Billing ID</a>
            <Col xs={12}>
              <Form.Item label="Product ID" style={{ marginBottom: 0 }}>
                <Input
                  id="product_id"
                  disabled
                  value={this.state.product_id}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Product Nickname" style={{ marginBottom: 0 }}>
                <Input
                  id="product_nickname"
                  value={this.state.product_nickname}
                  disabled
                />
              </Form.Item>
              <Form.Item label="Product Billing ID" >
                <Input
                  id="product_stripe_id"
                  value={this.state.product_stripe_id}
                  disabled
                />
              </Form.Item>
              </Col>*/}
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button type="primary" htmlType="submit">
                Cancel Coupon
              </Button>
            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const PromoCodeSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PromoCodeSummary);

export default withRouter(PromoCodeSummaryContainer);
