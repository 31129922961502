
import React, { Component } from 'react';
import { Table } from 'antd';

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";
import { NavLink } from "react-router-dom";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Divider } from 'antd';
// import { Card, Button } from 'antd';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    render: text => <a>{text.toString().padStart(5, '0')}</a>,
  },
  {
    title: 'nickname',
    dataIndex: 'nickname',
  },
  // {
  //   title: 'Email',
  //   dataIndex: 'email',
  // },
  // {
  //   title: 'First Name',
  //   dataIndex: 'first_name',
  // },
  // {
  //   title: 'Last Name',
  //   dataIndex: 'last_name',
  // },
  {
    title: 'Actions',
    key: 'action',
    render: (text, record) => (
      <span>
        <NavLink to={`/tags/view/${record.id}`} >View</NavLink>
        {/* <Divider type="vertical" />
        <a>Delete</a> */}
      </span>
    ),
  },
];

class TagListPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: []
    }
  }

  componentDidMount() {
    API.get("tags", `/list`)
      .then(response => {
        // console.log(response);
        // console.log(response.payload);

        let dataSource = [];
        for (let i = 0; i < response.payload.length; i++) {
          dataSource.push({
            key: response.payload[i].id,
            id: response.payload[i].id,
            nickname: response.payload[i].nickname,
            // email: response.payload[i].email,
            // first_name: response.payload[i].first_name,
            // last_name: response.payload[i].last_name,
          });
        }
        // console.log(dataSource);
        this.setState({
          dataSource: dataSource
        })
      })
      .catch(error => {
        console.log(error);
      });
  }


  render() {
    // console.log(this.state);
    // console.log(this.dataSource);
    // console.log(this.props);
    return (
      <React.Fragment>
        <Table
          columns={columns}
          dataSource={this.state.dataSource}
          pagination={{ 
            pageSizeOptions: ['10', '20', '30'], 
            showSizeChanger: true }}
        />
      </React.Fragment>
    );
  }
}

export default TagListPageComponent;
