
import React, { Component } from 'react';
import { connect } from "react-redux";

import {
  updateNewStaffCreation

} from "../../../../../redux/actions/staffs";

// import { API, Auth } from "aws-amplify";

//import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card, Button } from 'antd';
// import { notification } from 'antd';
import { Form, Input } from 'antd';

import { Typography } from 'antd';

const { Title } = Typography;

// const openNotificationWithIcon = (type, header, message) => {
//   notification[type]({
//     message: header,
//     description: message,
//   });
// };

class NewStaffWizard02 extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  async componentDidMount() {

  }

  async componentWillReceiveProps(nextProps, nextContext) {

  }




  handleChange = (event) => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
    this.props.updateNewStaffCreation(event.target.id, event.target.value);

  };



  render() {
    console.log(this.state);
    if (!this.props.staffs || !this.props.staffs.new_staff_data) return null;
    return (

      <React.Fragment>
        <Form onSubmit={this.handleSubmit}>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
            <Title level={4}>Contact Details</Title>
              <Form.Item label="Telephone (home)" style={{ marginBottom: 0 }}>
                <Input
                  id="telephone_home"
                  value={this.props.staffs.new_staff_data.telephone_home}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Telephone (work)" style={{ marginBottom: 0 }}>
                <Input
                  id="telephone_work"
                  value={this.props.staffs.new_staff_data.telephone_work}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Telephone (mobile)" style={{ marginBottom: 0 }}>
                <Input
                  id="telephone_mobile"
                  value={this.props.staffs.new_staff_data.telephone_mobile}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Primary Email" style={{ marginBottom: 0 }}>
                <Input
                  id="email"
                  value={this.props.staffs.new_staff_data.email}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Secondary Email" style={{ marginBottom: 0 }}>
                <Input
                  id="email_secondary"
                  value={this.props.staffs.new_staff_data.email_secondary}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
            <Title level={4}>Street Address</Title>
              <Form.Item
                label="Address (Line 1)"
                style={{ marginBottom: 0 }}
              >
                <Input
                  id="street_address_line_1"
                  value={this.props.staffs.new_staff_data.street_address_line_1}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Address (Line 2)"
                style={{ marginBottom: 0 }}
              >
                <Input
                  id="street_address_line_2"
                  value={this.props.staffs.new_staff_data.street_address_line_2}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item label="Suburb"
                    style={{ marginBottom: 0 }}
                  >
                    <Input
                      id="street_address_suburb"
                      value={this.props.staffs.new_staff_data.street_address_suburb}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    label="State"
                    style={{ marginBottom: 0 }}>
                    <Input
                      id="street_address_state"
                      value={this.props.staffs.new_staff_data.street_address_state}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={4}>
                  <Form.Item label="PostCode"
                    style={{ marginBottom: 0 }}
                  >
                    <Input
                      id="street_address_postcode"
                      value={this.props.staffs.new_staff_data.street_address_postcode}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Country">
                <Input
                  id="street_address_country"
                  value={this.props.staffs.new_staff_data.street_address_country}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <br/>
              <Title level={4}>Postal Address</Title>
              <Form.Item
                label="Address (Line 1)"
                style={{ marginBottom: 0 }}
              >
                <Input
                  id="postal_address_line_1"
                  value={this.state.postal_address_line_1}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Address (Line 2)"
                style={{ marginBottom: 0 }}
              >
                <Input
                  id="postal_address_line_2"
                  value={this.state.postal_address_line_2}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item label="Suburb"
                    style={{ marginBottom: 0 }}
                  >
                    <Input
                      id="postal_address_suburb"
                      value={this.state.postal_address_suburb}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    label="State"
                    style={{ marginBottom: 0 }}>
                    <Input
                      id="postal_address_state"
                      value={this.state.postal_address_state}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={4}>
                  <Form.Item label="PostCode"
                    style={{ marginBottom: 0 }}
                  >
                    <Input
                      id="postal_address_postcode"
                      value={this.state.postal_address_postcode}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Country">
                <Input
                  id="postal_address_country"
                  value={this.state.postal_address_country}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}


// export default UserSummary;



const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    staffs: state.staffs,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNewStaffCreation: (key, value) => {
      dispatch(updateNewStaffCreation(key, value))
    }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const NewStaffWizard02Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewStaffWizard02);

export default NewStaffWizard02Container;
