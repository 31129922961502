export default {
    s3: {
      REGION: "ap-southeast-2",
      BUCKET: "xolation.uploads.001"
    },
    apiGateway: {
      REGION: "us-east-1",
      // URL: "https://9s3zyajdba.execute-api.ap-southeast-2.amazonaws.com/dev"
    },
    cognito_staff: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_OTaQLc4DJ",
      APP_CLIENT_ID: "f5otdctr9l598j2k4snhgnohk",
      IDENTITY_POOL_ID: "us-east-1:859e6995-70c2-47a7-8a69-3d46e6ac4910"
    },
    cognito_clients: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_6YhSc1Y7p",
      APP_CLIENT_ID: "2vb9j5cldddbthu7mnct7rjacm",
      IDENTITY_POOL_ID: "us-east-1:74fb394e-74ba-43e9-be2a-fb4a243efd35"
    }
  };