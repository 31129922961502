
import React, { Component } from 'react';
// import { Table } from 'antd';

// import { API, Storage } from "aws-amplify";
// import { NavLink } from "react-router-dom";
// import { get_url } from "../../../../library/aws_library";

// import { Layout, Menu, Breadcrumb, Icon  } from 'antd'; 
// import { Divider } from 'antd';
// import { Card, Button } from 'antd';
// import { Tag } from 'antd';
import VideoListComponent from '../../../video/VideoListComponent';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

// var moment = require("moment");

class VideoListPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  async componentDidMount () {

  }

  render() {
    return (
      <React.Fragment>
        <VideoListComponent size="small" row_size="3"  views={this.props.views} favourites={this.props.favourites}/>
      </React.Fragment>
    );
  }
}

export default VideoListPageComponent;
