import React, { Component } from 'react';
import withStyles from 'react-jss'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";

//import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input, InputNumber } from 'antd';

import { Divider } from 'antd';
// import { Upload } from 'antd';
// import { message } from 'antd';
// import { InboxOutlined } from '@ant-design/icons';
// import { DashboardOutlined, VideoCameraOutlined } from '@ant-design/icons'
import { Select } from 'antd';
import { DatePicker } from 'antd';

// import { loadStripe } from '@stripe/stripe-js';

// import './DatePickerSample.css';
// import { DatePicker } from 'antd';
const moment = require("moment");

const { Option } = Select;
// const stripePromise = loadStripe('pk_live_1NNfbEQHqrpbF9ouaKrVFilx00Npb3yl14',
//   {
//     stripeAccount: 'acct_1GYvZVLdj3tiY8tr'
//   });

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: "right"
  },
};

// var moment = require("moment");

class PromoCodeNewPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plan_list: [],
    }
    API.get("plans", "/list").then(
      result => {
        console.log(result);
        this.setState({
          plan_list: result.payload
        })
      }
    ).catch(err => {
      console.log(err);
    });
  }

  componentDidMount = async () => {


  }

  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSelectChange_coupon_duration = value => {
    console.log(value);
    this.setState({
      //[event.target.id]: event.target.value
      coupon_duration: value
    });
  }

  onChangePublicDateTime = (date, dateString) => {
    console.log(date, dateString);
    console.log(moment(dateString).utc().format("YYYY-MM-DD"));
    this.setState({
      end_date: moment(dateString).utc().format("YYYY-MM-DD")
    });
  }

  handleNumberonChange = (value) => {
    console.log('changed', value);
    this.setState({
      percentage_off: (parseInt(value)*0.01+0.00).toFixed(2)
    });
  }

  handleSubmit = async (event) => {

    event.preventDefault();
    openNotificationWithIcon('info', "Record Creation", "New Promo Code record creation is starting.")
    //console.log(event);
    // console.log(this.state);
    //console.log(this.props.clients.new_client_data)
    const {
      // description,
      percentage_off,
      fixed_amount_discount,
      // plan_id,
      end_date,
      coupon_code,
      coupon_duration
    } = this.state;

    try {
      API.post("promocodes", `/create`, {
        body: {
          // description,
          percentage_off,
          fixed_amount_discount,
          // plan_id,
          end_date,
          coupon_code,
          coupon_duration
        }
      })
        .then(async response => {
          console.log(response)
          await API.post("email", `/interaction`, {
            body: {
              interaction: "New Promo Code Created",
              data: {
                // description,
                percentage_off,
                fixed_amount_discount,
                // plan_id,
                end_date,
                coupon_code,
                coupon_duration
              },
              result: "New Promo Code Created"
            }
          })
            .then(response => {
              console.log(response);
            })
            .catch(error => {
              console.log(error);
            });
          return response;
        })
        .then(database_result => {
          console.log(database_result)
          openNotificationWithIcon('success', "Record Created", "New Promo Code has been successfully created.")
          this.props.history.push(`/promocodes/list`);
        })
        .catch(error => {
          console.log(error);
        });
    } catch (e) {
      alert(e);
      openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
    }

    this.setState({ isLoading: true });

  };

  validationSubmitButton = () => {
    // if (!this.state.description || this.state.nickname === " ") {
    //   return true
    // }
    if (!this.state.coupon_duration) {
      return true
    }
    if (!this.state.coupon_code || this.state.coupon_code === " ") {
      return true
    }
    if (this.state.percentage_off && this.state.fixed_amount_discount) {
      return true
    }
    return false
  }

  render() {
    // const { classes } = this.props;
    // console.log(this.props.app_state.current_user_id)

    // let plan_list_select_options = this.state.plan_list.map(d => <Option key={d.id} >{d.nickname}</Option>);
    let coupon_duration_options = [
      <Option key={"forever"} >Forever</Option>,
      <Option key={"1_period"} >1 Subscription Period (year/month)</Option>,
      <Option key={"2_period"} >2 Subscription Period (year/month)</Option>,
      <Option key={"3_period"} >3 Subscription Period (year/month)</Option>,
    ];
    // console.log(product_list_select_options);
    console.log(this.state)

    return (

      <React.Fragment>

        <Form layout="vertical">
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Form.Item
                label="Coupon Code"
                help="This is the code which the customer will use to get the promo"
              >
                <Input
                  id="coupon_code"
                  placeholder="eg: SEP25%OFF"
                  value={this.state.coupon_code}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <br/>
              <Form.Item
                label="Coupon Expiry Date"
                help="After this date, customers will not longer be able to enter the coupon code"
              >
                <DatePicker
                  // showTime={{ format: 'HH:mm' }}
                  onChange={this.onChangePublicDateTime}
                />
              </Form.Item>

              {/* <Form.Item
                label="Description"
                help="For your internal use only"
              >
                <Input
                  id="description"
                  value={this.state.description}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item> */}
              {/* <Form.Item label="Plan" style={{ marginBottom: 0 }}>
                <Select
                  id="plan_id"
                  name="plan_id"
                  showSearch
                  value={
                    typeof this.state.product_id === "number"
                      ? this.state.plan_id.toString()
                      : (this.state.plan_id === " ")
                        ? "Select Plan"
                        : this.state.plan_id
                  }
                  placeholder="Select Plan"
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  }
                  onChange={this.handleSelectChange_plan}
                >
                  {
                    plan_list_select_options
                  }
                </Select>
              </Form.Item> */}
              <Divider />
              <p>
                Please select <strong>either</strong> and percentage discount <strong>or</strong> a fixed amount off.
              </p>
              <Row gutter={[96, 24]} >
                <Col xs={12}>

                  {//Reopen using inputnumber after fixed #143
                    
                    /*<Form.Item
                    label="Percentage Off"
                    help="Must be between 0.00 and 0.99"
                  >
                    <Input
                      id="percentage_off"
                      value={this.state.percentage_off}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                    </Form.Item>*/}
                  <Form.Item
                    label="Percentage off"
                    help={"must be between 0% and 100%"}
                  >
                    <InputNumber
                      defaultValue={this.state.percentage_off
                        ? this.state.percentage_off * 100
                        : "0"
                      }
                      min={0}
                      max={100}
                      // disabled
                      formatter={value => `${value}%`}
                      parser={value => value.replace('%', '')}
                      onChange={value => this.handleNumberonChange(value)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label="Fixed Amount Discount"
                    help="Must be between 0.00 and 9999.99"
                  >
                    <Input
                      id="fixed_amount_discount"
                      value={this.state.fixed_amount_discount}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Duration"
                help="How long the coupon will apply for"
              >
                <Select
                  id="coupon_duration"
                  name="coupon_duration"
                  showSearch
                  value={this.state.coupon_duration}
                  placeholder="Select Duration"
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  }
                  onChange={this.handleSelectChange_coupon_duration}
                >
                  {
                    coupon_duration_options
                  }
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                disabled={this.validationSubmitButton()}
                htmlType="submit"
                onClick={this.handleSubmit}
              >
                Create New PromoCode
              </Button>
            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {

  }
}

const PromoCodeNewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(PromoCodeNewPageComponent)));

export default PromoCodeNewPageComponentContainer;
