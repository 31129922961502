import { API } from "aws-amplify";

// export const UPDATE_CURRENT_BRAND = "UPDATE_CURRENT_BRAND";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";
export const DATA_COUNTRIES = "DATA_COUNTRIES";
export const DATA_CLIENTS_STATUS = "DATA_CLIENTS_STATUS";
export const DATA_SUBSCRIPTION_STATUS = "DATA_SUBSCRIPTION_STATUS";
export const DATA_EXERCISE_CATEGORIES = "DATA_EXERCISE_CATEGORIES";
// export const UPDATE_CURRENT_USER_ID = "UPDATE_CURRENT_USER_ID";
// export const UPDATE_CURRENT_USER_LOG_OUT = "UPDATE_CURRENT_USER_LOG_OUT";
// export const UPDATE_ECONOMIC_CALENDAR = "UPDATE_ECONOMIC_CALENDAR";


export function onLoginSuccess(user) {
  return async function(dispatch) {
    try {
      // await update_client_database(aws_cognito_id, {
      //   portal_last_login_datetime: new Date().toISOString().slice(0, 19).replace('T', ' ')
      // })
      // console.log(user);
      const staff_record = await get_current_staff_details(user);
      const countries_list = await get_countries();
      const client_status_list = await get_client_status();
      const subscription_status_list = await get_subscription_status();
      const exercise_categories_list = await get_exercise_categories();

      // const division_details = await get_current_division_details(client_record.division_id)
      // console.log(staff_record)
      // console.log(division_details)
      if (staff_record)
        dispatch(updateCurrentStaffIntoStore(staff_record));
        dispatch(updateCountriesIntoStore(countries_list));
        dispatch(updateClientStatusIntoStore(client_status_list));
        dispatch(updateSubscriptionStatusIntoStore(subscription_status_list));
        dispatch(updateExerciseCategoriesIntoStore(exercise_categories_list));
        
      // dispatch(createNewStaffMemberComplete());
    } catch (e) {
      // alert(e.message);
      console.log(e);
    }
  };
}

async function get_current_staff_details(user) {
  // console.log(user);
  if (user.username) {
    return API.get(
      "staff",
      `/get/aws-cognito/${user.username}`
    )
      .then(response => {
        // console.log(response.payload);
        return response.payload;
      })
      .catch(error => {
        console.log(error);
      })
    }
  else if (user.email) {
    return API.get(
      "staff",
      `/get/email/${user.email}`
    )
      .then(response => {
        // console.log(response.payload);
        return response.payload;
      })
      .catch(error => {
        console.log(error);
      })
    }
}

async function get_countries() {
  // console.log(user);
  return API.get(
    "countries",
    `/list`
  )
    .then(response => {
      // console.log(response.payload);
      return response.payload;
    })
    .catch(error => {
      console.log(error);
    })
}

async function get_client_status() {
  // console.log(user);
  return API.get(
    "client-status",
    `/list`
  )
    .then(response => {
      // console.log(response.payload);
      return response.payload;
    })
    .catch(error => {
      console.log(error);
    })
}

async function get_subscription_status() {
  // console.log(user);
  return API.get(
    "subscription-status",
    `/list`
  )
    .then(response => {
      // console.log(response.payload);
      return response.payload;
    })
    .catch(error => {
      console.log(error);
    })
}

async function get_exercise_categories() {
  // console.log(user);
  return API.get(
    "exercise-categories",
    `/list`
  )
    .then(response => {
      // console.log(response.payload);
      return response.payload;
    })
    .catch(error => {
      console.log(error);
    })
}

//updateClientStatusIntoStore
export function updateClientStatusIntoStore(client_status) {
  return {
    type: DATA_CLIENTS_STATUS,
    client_status: client_status,
  };
}

//updateSubscriptionStatusIntoStore
export function updateSubscriptionStatusIntoStore(subscription_status) {
  return {
    type: DATA_SUBSCRIPTION_STATUS,
    subscription_status: subscription_status,
  };
}

export function updateCountriesIntoStore(countries) {
  return {
    type: DATA_COUNTRIES,
    countries: countries,
  };
}

export function updateCurrentStaffIntoStore(staff_record) {
  return {
    type: UPDATE_CURRENT_USER,
    user_id: staff_record.id,
    aws_cognito_id: staff_record.aws_cognito_id,
    current_staff_record: staff_record,
    // brand: brand,
    // current_division: division_details
  };
}

export function updateExerciseCategoriesIntoStore(exercise_categories) {
  return {
    type: DATA_EXERCISE_CATEGORIES,
    exercise_categories: exercise_categories
  };
}
