
import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import uuid from "uuid";

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";
import { Storage } from "aws-amplify";
import { get_url } from "../../../../../library/aws_library";

import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

//import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input } from 'antd';
// import { Select } from 'antd';

import ImgCrop from 'antd-img-crop';

// const { Option } = Select;
const { TextArea } = Input;
const { Dragger } = Upload;

var moment = require("moment");

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class CategorySummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      small_poster_file_id: "",
      small_poster_url: ""
    }

    this.uploader_small_poster = {
      customRequest: ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials
      }) => {
        // console.log(file);
        const s3_filename = `main-category-small-poster-${moment().format("YYYYMMDD-HHmmss")}-${uuid.v1()}`;

        Storage.put(s3_filename, file, {
          contentType: file.type,
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            onProgress(
              {
                percent: Math.round((progress.loaded / progress.total) * 100)
              },
              file
            );
          }
        })
          .then(async (result) => {
            console.log(result);
            onSuccess(result);
            this.setState({ small_poster_file_id: result.key });
            let video_url = await get_url(result.key);
            this.setState({ small_poster_url: video_url });
          })
          .catch((err) => {
            console.log(err)
            this.setState({
              upload_error: err
            })
          });
      }
    };
  }


  async componentDidMount() {
    const video_category = await this.getVideoCategoryRecord(
      this.props.id
    );
    // console.log(video_category)
    // this.setState({
    //   ...video_category.payload
    // })
    this.setState({
      isLoading: true
    })
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    // console.log(nextProps)
    // if (nextProps.app_state.current_user_id && nextProps.app_state.current_user_aws_cognito_id) {
    //   const user = await this.getUserProfile(
    //     nextProps.app_state.current_user_aws_cognito_id
    //   );
    //   // console.log(user)
    //   this.setState({
    //     ...user.payload
    //   })
    // }
  }


  async getVideoCategoryRecord(id) {
    API.get("video-categories", `/main/id/${id}`).then(
      async (response) => {
        console.log(response)
        if (response.payload.small_poster_file_id) {
          let small_poster_url = await get_url(response.payload.small_poster_file_id);
          this.setState({ small_poster_url })
        }

        this.setState({
          ...response.payload
        })

        // return response;
      }
    );
  }

  async saveVideoCategoryRecord(video_category_record) {
    await API.put("video-categories", `/update-main/${this.state.id}`, {
      body: video_category_record
    });
    openNotificationWithIcon('success', "Save Successful", "Your data has been saved successfully")
  }


  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    // console.log(event)
    this.setState({ isLoading: true });

    const {
      id,
      file_by,
      description_short,
      description_long,
      small_poster_file_id
      //tag_color
    } = this.state;
    try {
      await this.saveVideoCategoryRecord({
        id,
        file_by,
        description_short,
        description_long,
        small_poster_file_id
        //tag_color
      });
      // this.props.history.push("/");
      // this.props.reload_user(this.state.aws_cognito_id);
    } catch (e) {
      // alert(e);
      // try {
      //   await this.saveClientRecord({
      //     // aws_cognito_id: aws_cognito_id,
      //     first_name: first_name,
      //     // middle_name: middle_name,
      //     last_name: last_name,
      //   });
      //   // this.props.history.push("/");
      //   // this.props.reload_user(this.state.aws_cognito_id);
      // } catch (e) {
      // alert(e);
      openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
      // }

      // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
    }
  };

  onLongDescChange = ({ target: { value } }) => {
    this.setState({ description_long: value });
  };

  onShortDescChange = ({ target: { value } }) => {
    this.setState({ description_short: value });
  };



  handleDeletePosterButton = (event) => {
    alert('remove this poster');

    const {
      file_by,
      description_short,
      description_long
      // small_poster_file_id
    } = this.state;

    try {
      this.saveVideoCategoryRecord({
        file_by: file_by,
        description_short: description_short,
        description_long: description_long,
        small_poster_file_id: null
      })

    } catch (e) {
      console.log(e)
    } finally {
      this.setState({
        small_poster_file_id: null,
        small_poster_url: null
      });

      //refresh the data
      setTimeout(() => this.getVideoCategoryRecord(`${this.state.id}`), 3000);
      this.props.history.push(`/main-categories/view/${this.state.id}`);
      //video/view/
      //window.location.reload(true);
    };

  }

  render() {
    // console.log(this.state);

    return (

      <React.Fragment>
        <Form layout="vertical" onSubmit={this.handleSubmit}>

          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Row gutter={[24, 0]}>
                <Col xs={12}>
                  <Form.Item label="ID">
                    <Input
                      id="id"
                      disabled
                      value={this.state.id}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Nickname">
                    <Input
                      id="file_by"
                      value={this.state.file_by}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Short Decription">
                    {/*<Input
                      id="description_short"
                      value={this.state.description_short}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />*/}
                    <TextArea
                      value={this.state.description_short}
                      onChange={this.onShortDescChange}
                      autoSize={{ minRows: 3, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Long Decription">
                    {/*<Input
                      id="description_long"
                      value={this.state.description_long}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />*/}
                    <TextArea
                      value={this.state.description_long}
                      onChange={this.onLongDescChange}
                      autoSize={{ minRows: 3, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              {this.state.small_poster_url &&
                <React.Fragment>
                  <Form.Item
                    label="Poster"
                  >
                    {this.state.small_poster_file_id && <React.Fragment>
                      <img src={this.state.small_poster_url} style={{ height: 180, maxWidth: 320 }}></img>
                    </React.Fragment>}
                  </Form.Item>
                  {this.state.small_poster_file_id && <React.Fragment>
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => this.handleDeletePosterButton(this.state.small_poster_file_id)}
                      >
                        Remove
                      </Button>
                    </Form.Item>
                  </React.Fragment>
                  }
                </React.Fragment>
              }

              {!this.state.small_poster_url &&
                <Form.Item
                  label="Poster Upload"
                  help={this.state.poster_upload_error ? this.state.poster_upload_error : ""}
                >
                  <ImgCrop aspect grid>
                    <Dragger
                      disabled={!this.state.isLoading}
                      {...this.uploader_small_poster}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">
                        Support for a single image file only.
                    </p>
                    </Dragger>
                  </ImgCrop>
                </Form.Item>
              }
            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button type="primary" htmlType="submit" onClick={this.handleSubmit}>
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const CategorySummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategorySummary);

export default withRouter(CategorySummaryContainer);
