
import React, { Component } from 'react';
import { connect } from "react-redux";

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";

//import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input } from 'antd';
import { Checkbox } from 'antd';

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class ClientBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }

    // API to get subscription plan list
    API.get("plans", `/list`)
      .then(response => {
        // console.log(response);
        // console.log(response.payload);

        let dataSource = [];
        for (let i = 0; i < response.payload.length; i++) {
          dataSource.push({
            key: i,
            id: response.payload[i].id,
            nickname: response.payload[i].nickname,
          });
        }

        this.setState({
          dataSource: dataSource
        })
      })
      .catch(error => {
        console.log(error);
      });
  }


  async componentDidMount() {
    const client = await this.getClientRecord(
      this.props.client_id
    );
    console.log(client)
    this.setState({
      ...client.payload
    })
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    // console.log(nextProps)
    // if (nextProps.app_state.current_user_id && nextProps.app_state.current_user_aws_cognito_id) {
    //   const user = await this.getUserProfile(
    //     nextProps.app_state.current_user_aws_cognito_id
    //   );
    //   // console.log(user)
    //   this.setState({
    //     ...user.payload
    //   })
    // }
  }


  async getClientRecord(aws_cognito_id) {
    return API.get("clients", `/id/${aws_cognito_id}`);
  }

  async saveClientRecord(client_record) {
    await API.put("clients", `/update/${this.state.id}`, {
      body: client_record
    });
    openNotificationWithIcon('success', "Save Successful", "Your data has been saved successfully")
  }


  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleCheckbox = event => {
    // console.log(`checked = ${event.target.checked}`);
    this.setState({
      vip: event.target.checked
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    // console.log(event)
    this.setState({ isLoading: true });

    const {
      // id,
      // aws_cognito_id,
      stripe_id,
      vip
    } = this.state;
    try {
      await this.saveClientRecord({
        // aws_cognito_id: aws_cognito_id,
        stripe_id,
        vip
        // middle_name: middle_name,
        // last_name: last_name,
      });
      // this.props.history.push("/");
      // this.props.reload_user(this.state.aws_cognito_id);
    } catch (e) {
      // alert(e);
      // try {
      //   await this.saveClientRecord({
      //     // aws_cognito_id: aws_cognito_id,
      //     first_name: first_name,
      //     // middle_name: middle_name,
      //     last_name: last_name,
      //   });
      //   // this.props.history.push("/");
      //   // this.props.reload_user(this.state.aws_cognito_id);
      // } catch (e) {
      // alert(e);
      openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
      // }

      // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
    }
  };

  create_stripe_customer_id = () => {
    if (this.state.stripe_id) {
      openNotificationWithIcon('error', "Billing ID Exists", "A billing ID number already exists for this client")
      return;
    }
    const {
      email,
      first_name,
      last_name
    } = this.state;
    let name = `${first_name} ${last_name}`;

    API.post("stripe", `/customer/create/${this.state.id}`, {
      body: {
        email,
        name
      }
    })
      .then(response => {
        console.log(response)
        openNotificationWithIcon('success', "Record Created", "A Billing Account has been created for this client")
        this.setState({
          stripe_id: response.payload.stripe_result.id
        })
        // return database_result;
      })
      // .then(database_result => {
      //   // console.log(database_result)
      //   // openNotificationWithIcon('success', "Record Created", "New client has been successfully created.")
      //   this.props.history.push(`/clients/list`);
      // })
      .catch(error => {
        console.log(error);
      });
  }

  email_cancel_subscription = async () => {
    openNotificationWithIcon('info', "Email Sending", "Cancellation email is being sent to client")
    await API.post("email", `/client/cancel-subscription`, {
      body: {
        id: this.state.id,
      }
    })
      .then(response => {
        console.log(response);
        openNotificationWithIcon('success', "Email Sent", "Cancellation email has been sent to client")
      })
      .catch(error => {
        console.log(error);
      });

  }

  render() {
    console.log(this.state);
    if (!this.props.app_state || !this.props.app_state.data_clients_status_list || !this.state.dataSource) return null;
    const clients_status_list_select_options = this.props.app_state.data_clients_status_list;
    const plans = this.state.dataSource;

    return (

      <React.Fragment>
        <Form layout="vertical">

          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Form.Item label="Stripe ID">
                <Input
                  id="stripe_id"
                  value={this.state.stripe_id}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <a onClick={this.create_stripe_customer_id}>Create Billing ID</a>
            </Col>
            <Col xs={12}>
              <Form.Item label="VIP status" >
                <Checkbox checked={this.state.vip} onChange={this.handleCheckbox}>Is a VIP</Checkbox>
              </Form.Item>
              <Form.Item label="Subscription Cancellation">
                <Button onClick={this.email_cancel_subscription}>
                  Send Cancel Email
            </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[96, 24]}>
          <Col xs={12}>
              <Form.Item label="Client Status" >
                {clients_status_list_select_options.find(d => d.id == this.state.status_id)?.nickname}
              </Form.Item>
              <Form.Item label="Current Subscription">
                {plans.find(d => d.id == this.state.subscriptions_plan_id)?.nickname}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={this.handleSubmit}
              >
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const ClientBillingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientBilling);

export default ClientBillingContainer;
