
import React, { Component } from 'react';
import { connect } from "react-redux";

import {
  updateNewStaffCreation

} from "../../../../../redux/actions/staffs";

// import { API, Auth } from "aws-amplify";

// import { Layout } from 'antd';
// import { Row, Col } from 'antd';
// import { Card, Button } from 'antd';
// import { notification } from 'antd';
import { Form } from 'antd';
// import { Input } from 'antd';
import { Checkbox } from 'antd';

// const openNotificationWithIcon = (type, header, message) => {
//   notification[type]({
//     message: header,
//     description: message,
//   });
// };

class NewStaffWizard03 extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  async componentDidMount() {

  }

  async componentWillReceiveProps(nextProps, nextContext) {

  }



  handleChange = (event) => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
    this.props.updateNewStaffCreation(event.target.id, event.target.value);

  };

  handleCheckbox = event => {
    console.log(`checked = ${event.target.checked}`);
    this.setState({
      is_trainer: event.target.checked
    });
    this.props.updateNewStaffCreation("is_trainer", event.target.checked);
  }

  render() {
    // console.log(this.state);

    if (!this.props.staffs || !this.props.staffs.new_staff_data) return null;
    return (

      <React.Fragment>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="Trainer" >
            <Checkbox checked={this.state.is_trainer} onChange={this.handleCheckbox}>Is a trainer</Checkbox>
          </Form.Item>
        </Form>
      </React.Fragment>
    );
  }
}


// export default UserSummary;



const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    staffs: state.staffs,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNewStaffCreation: (key, value) => {
      dispatch(updateNewStaffCreation(key, value))
    }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const NewStaffWizard03Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewStaffWizard03);

export default NewStaffWizard03Container;
