import React, { Component } from 'react';
import withStyles from 'react-jss'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  startNewStaffCreation

} from "../../../../redux/actions/staffs";

import { create_admin_cognito_account } from "../../../../library/staff";

// import styles from './NewClientWizard01.less';

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";

// import { Layout } from 'antd';
// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import { Steps } from 'antd';
// import { message } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';

import NewStaffWizard01Container from "./new_staff_wizard/NewStaffWizard01";
import NewStaffWizard02Container from "./new_staff_wizard/NewStaffWizard02";
import NewStaffWizard03Container from "./new_staff_wizard/NewStaffWizard03";
import NewStaffWizard04Container from "./new_staff_wizard/NewStaffWizard04";

var generator = require('generate-password');


const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: "right"
  },
};


const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

const { Step } = Steps;

const steps = [
  {
    title: 'Staff Details',
    content: <NewStaffWizard01Container />,
  },
  {
    title: 'Contact Details',
    content: <NewStaffWizard02Container />,
  },
  {
    title: 'Trainer',
    content: <NewStaffWizard03Container />,
  },
  {
    title: 'Confirm',
    content: <NewStaffWizard04Container />,
  },
];

class StaffNewPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_step: 0,
    }
  }

  componentDidMount() {
    this.props.startNewStaffCreation();
  }

  next_step() {
    const current_step = this.state.current_step + 1;
    this.setState({ current_step });
  }

  prev_step() {
    const current_step = this.state.current_step - 1;
    this.setState({ current_step });
  }

  handleSubmit = async (event) => {
    console.log(this.props.staffs.new_staff_data)
    API.post("staff", `/create`, {
      body: {
        ...this.props.staffs.new_staff_data
      }
    })
      .then(async response => {
        console.log(response)
        var password = generator.generate({
          length: 10,
          numbers: true
        });
        await create_admin_cognito_account(response.payload.insertId, this.props.staffs.new_staff_data.email, password)
        openNotificationWithIcon('success', "Record Created", "New staff has been successfully created.")
        return response;
      })
      .then(response => {
        // console.log(database_result)
        // openNotificationWithIcon('success', "Record Created", "New client has been successfully created.")
        this.props.history.push(`/staff/list`);
      })
      .catch(error => {
        console.log(error);
      });
  }


  render() {
    const { classes } = this.props;
    const { current_step } = this.state;
    // console.log(this.props);
    if (!this.props.staffs && !this.props.staffs.new_staff_data) return null;

    return (
      <React.Fragment>
        <Steps current={current_step}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className={classes.stepsContent}>
          {steps[current_step].content}
        </div>
        <div className={classes.stepsAction}>
          {current_step > 0 && (
            <Button style={{ marginLeft: 8 }} onClick={() => this.prev_step()}>
              Previous
            </Button>
          )}
          {current_step < steps.length - 1 && (
            <Button type="primary" onClick={() => this.next_step()}>
              Next
            </Button>
          )}
          {current_step === steps.length - 1 && (
            <Button type="primary" onClick={this.handleSubmit}>
              Done
            </Button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    staffs: state.staffs,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startNewStaffCreation: () => {
      dispatch(startNewStaffCreation())
    }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const StaffNewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(StaffNewPageComponent)));

export default StaffNewPageComponentContainer;
