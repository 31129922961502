import React, { Component } from 'react';

// import { API, Auth } from "aws-amplify";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import { Card } from 'antd';
import { PageHeader } from 'antd';
// import { Button } from 'antd';
import SubscriptionListPageComponent from '../../../components/pages/dashboard/subscription/SubscriptionListPageComponent';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class SubscriptionListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (

      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="Subscription List"
        />
        <Card
          style={{ minHeight: 360 }}
          title="View a list of current subscriptions"
        >
          <div style={{ paddingTop: 24 }}>
            <SubscriptionListPageComponent />
          </div>
        </Card>
      </div>


    );
  }
}

export default SubscriptionListPage;
