
import React, { Component } from 'react';
import { connect } from "react-redux";

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";

//import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input } from 'antd';
import { Select } from 'antd';

const { Option } = Select;

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class PlansSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  async componentDidMount() {
    const product = await this.getPlansRecord(
      this.props.product_id
    );
    //console.log(product)
    this.setState({
      ...product.payload
    })
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    //console.log(nextProps)
    // if (nextProps.app_state.current_user_id && nextProps.app_state.current_user_aws_cognito_id) {
    //   const user = await this.getUserProfile(
    //     nextProps.app_state.current_user_aws_cognito_id
    //   );
    //   // console.log(user)
    //   this.setState({
    //     ...user.payload
    //   })
    // }
  }


  async getPlansRecord(product_id) {
    return API.get("plans", `/id/${product_id}`);
  }

  async savePlansRecord(product_record) {
    await API.put("plans", `/update/${this.state.id}`, {
      body: product_record
    });
    openNotificationWithIcon('success', "Save Successful", "Your Plan data has been saved successfully")
  }

  async saveProductRecord(product_record) {
    await API.put("products", `/update/${this.state.product_id}`, {
      body: product_record
    });
    openNotificationWithIcon('success', "Save Successful", "Your Product data has been saved successfully")
  }

  create_stripe_plan_id = () => {
    if (this.state.stripe_id) {
      openNotificationWithIcon('error', "Billing ID Exists", "A billing ID number already exists for this client")
      return;
    }
    // const {
    //   nickname,
    // } = this.state;

    let interval = "";
    switch (this.state.term) {
      case "1":
        interval = "day";
        break;
      case "2":
        interval = "week";
        break;
      case "3":
        interval = "month";
        break;
      case "4":
        interval = "year";
        break;
      default:
        break;
    }

    API.post("stripe", `/plans/create/${this.state.id}`, {
      body: {
        currency: "aud",
        interval,
        product: this.state.product_stripe_id,
        nickname: this.state.nickname,
        amount: Number.parseFloat(this.state.retail_price) * 100
      }
    })
      .then(response => {
        console.log(response)
        openNotificationWithIcon('success', "Record Created", "A Billing Account has been created for this client")
        this.setState({
          stripe_id: response.payload.stripe_result.id
        })
      })
      .catch(error => {
        console.log(error);
      });

  }

  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSelectChange = (value) => {
    console.log(value);
    this.setState({
      term: value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    // console.log(event)
    this.setState({ isLoading: true });

    let {
      // id,
      // product_id,
      product_nickname,
      product_stripe_id,
      nickname,
      stripe_id,
      term,
      retail_price
    } = this.state;

    try {
      await this.saveProductRecord({
        // aws_cognito_id: aws_cognito_id,
        nickname: product_nickname,
        stripe_id: product_stripe_id,
      });

      await this.savePlansRecord({
        // aws_cognito_id: aws_cognito_id,
        stripe_id: stripe_id,
        nickname: nickname,
        term: term,
        retail_price: retail_price
      });
      // this.props.history.push("/");
      // this.props.reload_user(this.state.aws_cognito_id);
    } catch (e) {
      // alert(e);
      try {
        await this.saveProductRecord({
          // aws_cognito_id: aws_cognito_id,
          // first_name: first_name,
          // middle_name: middle_name,
          // last_name: last_name,
        });
        // this.props.history.push("/");
        // this.props.reload_user(this.state.aws_cognito_id);
      } catch (e) {
        // alert(e);
        openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
        // this.setState({ isLoading: false });
      }

      // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
    }
  };

  render() {
    // console.log(this.state);

    return (

      <React.Fragment>
        <Form layout="vertical">

          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Form.Item label="ID">
                <Input
                  id="id"
                  disabled
                  value={this.state.id}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Nickname">
                <Input
                  id="nickname"
                  value={this.state.nickname}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Term" >
                {/*<Input
                  id="term"
                  value={this.state.term}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />*/}
                <Select
                  id="term"
                  showSearch
                  value={
                    typeof this.state.term === "number"
                      ? this.state.term.toString()
                      : this.state.term
                  }
                  placeholder="Select terms"
                  //style={{ width: 180 }}
                  onChange={value => {
                    this.handleSelectChange(value)
                  }}
                >
                  <Option value="1">
                    daily
                  </Option>
                  <Option value="2">
                    weekly
                  </Option>
                  <Option value="3">
                    monthly
                  </Option>
                  <Option value="4">
                    annually
                   </Option>
                </Select>
              </Form.Item>
              <Form.Item label="Retail Price (AUD$)" >
                <Input
                  id="retail_price"
                  value={this.state.retail_price}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Plan Billing ID" >
                <Input
                  id="stripe_id"
                  value={this.state.stripe_id}
                  disabled
                />
              </Form.Item>
              <a onClick={this.create_stripe_plan_id}>Create Billing ID</a>
            </Col>
            <Col xs={12}>
              <Form.Item label="Product ID">
                <Input
                  id="product_id"
                  disabled
                  value={this.state.product_id}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Product Nickname">
                <Input
                  id="product_nickname"
                  value={this.state.product_nickname}
                  disabled
                />
              </Form.Item>
              <Form.Item label="Product Billing ID" >
                <Input
                  id="product_stripe_id"
                  value={this.state.product_stripe_id}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button 
                type="primary" 
                htmlType="submit"
                onClick={this.handleSubmit}
              >
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const PlansSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlansSummary);

export default PlansSummaryContainer;
