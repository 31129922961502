import React, { Component } from 'react';

// import { API, Auth } from "aws-amplify";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Card, Button } from 'antd';
import { Tabs } from 'antd';
// import { Form, Input } from 'antd';
import UserSummary from './UserSummary';
import UserContact from './UserContact';
import UserAddress from './UserAddress';

const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}


class UserProfilePageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  componentDidMount() {
    // API.get("clients", `/list`)
    //   .then(response => {
    //     console.log(response);
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
  }


  render() {
    // console.log(this.state);
    return (

      <React.Fragment>

        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="User Summary" key="1">

            <UserSummary />

          </TabPane>
          <TabPane tab="Contact" key="2">
            <UserContact />
          </TabPane>
          <TabPane tab="Address" key="3">
          <UserAddress />
          </TabPane>
        </Tabs>
      </React.Fragment>
    );
  }
}

export default UserProfilePageComponent;
