import React, { Component } from 'react';
import withStyles from 'react-jss'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import { API, Auth } from "aws-amplify";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Steps } from 'antd';
// import { message } from 'antd';
// import { Card, Button } from 'antd';
// import { notification } from 'antd';
// import { Tabs } from 'antd';

import VideoViewComponent from '../../../video/VideoViewComponent';

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: "right"
  },
};

// const { TabPane } = Tabs;
// function callback(key) {
//   console.log(key);
// }

class VideoViewPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_step: 0,
    }
  }

  componentDidMount() {

  }

  render() {

    return (
      <React.Fragment>
        <VideoViewComponent video_id={this.props.video_id} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
  }
}

const VideoViewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(VideoViewPageComponent)));

export default VideoViewPageComponentContainer;
