
import React, { Component } from 'react';
import { Table } from 'antd';
import { NavLink } from "react-router-dom";

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Divider } from 'antd';
import { Form, Input } from 'antd';
import { Tag } from 'antd';
import { Row, Col } from 'antd';
// import { Card, Button } from 'antd';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    render: (text, record) => <React.Fragment>
      <NavLink to={`/staff/view/${record.id}`} >
        {text.toString().padStart(5, '0')}
      </NavLink>
    </React.Fragment>
    //text => <a>{text.toString().padStart(5, '0')}</a>,
  },
  {
    title: 'Last Name',
    dataIndex: 'last_name',
  },
  {
    title: 'First Name',
    dataIndex: 'first_name',
  },
  {
    title: 'Status',
    dataIndex: 'staff_status',
    sorter: (a, b) => a.id - b.id,
    render: data_item => {
      switch (data_item) {
        case 1:
          return <Tag color={'green'} style={{ width: 100, textAlign: "center" }} key={data_item}>ACTIVE</Tag>
        // case 2:
        //   return <Tag color={'blue'} style={{ width: 100, textAlign: "center" }} key={data_item}>PENDING</Tag>
        // case 3:
        //   return <Tag color={'orange'} style={{ width: 100, textAlign: "center" }} key={data_item}>REGISTERED</Tag>
        // case 4:
        //   return <Tag color={'green'} style={{ width: 100, textAlign: "center" }} key={data_item}>ACTIVE</Tag>
        default:
          return <Tag color={'orange'} style={{ width: 100, textAlign: "center" }} key={data_item}>INACTIVE</Tag>
      }
    }
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Actions',
    key: 'action',
    render: (text, record) => (
      <span>
        <NavLink to={`/staff/view/${record.id}`} >View</NavLink>
        {/*<Divider type="vertical" />
         <a>Delete</a>*/}
      </span>
    ),
  },
];

const expanded_row_layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

class StaffListPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: []
    }
  }

  componentDidMount() {
    API.get("staff", `/list`)
      .then(response => {
        // console.log(response);

        let dataSource = [];
        for (let i = 0; i < response.payload.length; i++) {
          dataSource.push({
            key: response.payload[i].id,
            id: response.payload[i].id,
            email: response.payload[i].email,
            tel_work: response.payload[i].tel_work,
            tel_home: response.payload[i].tel_home,
            tel_mobile: response.payload[i].tel_mobile,
            first_name: response.payload[i].first_name,
            last_name: response.payload[i].last_name,
            staff_status: response.payload[i].staff_status
          });
        }
        // console.log(dataSource);
        this.setState({
          dataSource: dataSource
        })
      })
      .catch(error => {
        console.log(error);
      });
  }


  render() {
    // console.log(this.state);
    return (
      <React.Fragment>
        {/* STAFF LIST GOES HERE */}
        <Table
          columns={columns}
          dataSource={this.state.dataSource}
          pagination={{ 
            pageSizeOptions: ['10', '20', '30'], 
            showSizeChanger: true }}
          expandedRowRender={record => {
            // console.log(record);
            return (<React.Fragment>
              <Row gutter={[96, 24]}>
                <Row>
                </Row>
                <Col xs={12}>
                  <Form {...expanded_row_layout} onSubmit={this.handleSubmit}>
                    <Form.Item label="Tel (home)" >
                      <Input
                        id="tel_home"
                        value={record.tel_home}
                        disabled={true}
                      />
                    </Form.Item>
                    <Form.Item label="Tel (work)" >
                      <Input
                        id="tel_work"
                        value={record.tel_work}
                        disabled={true}
                      />
                    </Form.Item>
                    <Form.Item label="Tel (mobile)" >
                      <Input
                        id="tel_mobile"
                        value={record.tel_mobile}
                        disabled={true}
                      />
                    </Form.Item>
                  </Form>
                </Col>
                {/* <Col xs={12}>
                    <Form {...expanded_row_layout} onSubmit={this.handleSubmit}>
                      <Form.Item label="CTIN Portal" >
                        <a href="https://admin.ctin.com.au/customers/create" target="_blank">Create New Account</a>
                      </Form.Item>
                      <Form.Item label="Current SDA Product" >
                        <Tag color={record.products_badge_color} style={{ width: 100, textAlign: "center" }} key={record.current_product_id}>{record.products_nickname}</Tag>
                      </Form.Item>
                      <Form.Item label="Referring Client" >
                        <NavLink to={`/clients/view/${record.referring_client_id}`} >
                          <Tag color={record.referrer_badge_color} style={{ width: 20, textAlign: "center" }} key={record.referring_client_id}>&nbsp;</Tag>
                          {record.referring_client_nickname}
                        </NavLink>
                      </Form.Item>
                    </Form>
                  </Col> */}
              </Row>
            </React.Fragment>)
          }}
        />
      </React.Fragment>
    );
  }
}

export default StaffListPageComponent;
