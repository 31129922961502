import React, { Component } from 'react';

import { API } from "aws-amplify";

import {
  Button, notification, Row, Col, Form, Input, Radio
} from 'antd';

const { TextArea } = Input;

const radio_options_yes_no = [
  { label: "Yes", value: 1 },
  { label: "No", value: 0 },
];

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

// const { Step } = Steps;

class WorkoutNewPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file_by: '',
      exercise_details: '',
      client_id: '',
      showAll: ''
    }
  }

  componentDidMount() {
    //this.props.startNewClientCreation();
  }

  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    openNotificationWithIcon('info', "Record Creation", "New Workout record creation is starting.")
    //console.log(event);
    console.log(this.state);
    //console.log(this.props.clients.new_client_data)
    const {
      // id,
      file_by,
      exercise_details,
      client_id,
      showAll
    } = this.state;

    try {
      API.post("workouts", `/create`, {
        body: {
          // id: id,
          file_by: file_by,
          exercise_details: exercise_details,
          client_id: client_id,
          showAll: showAll
          //...this.props.clients.new_client_data
        }
      })
        .then(response => {
          console.log(response)
          openNotificationWithIcon('success', "Record Created", "New Workout has been successfully created.")

          return response;
        })
        .then(database_result => {
          console.log(database_result)
          openNotificationWithIcon('success', "Record Created", "New Workout has been successfully created.")
          //this.props.history.push(`/workouts/list`);
          window.location.assign(`/workouts/list`);
        })
        .catch(error => {
          console.log(error);
        });
    } catch (e) {
      alert(e);
      openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
      // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
    }

    this.setState({ isLoading: true });

  }

  onRadioChange = (e) => {
    // console.log(`radio checked:${e.target.value}`);
    this.setState({
      showAll: e.target.value,
    });
  };

  onTextAreaChange = ({ target: { value } }) => {
    this.setState({
      exercise_details: value,
    });
  };

  render() {
    // const { classes } = this.props;
    console.log(this.state)
    return (
      <React.Fragment>
        <Form layout="vertical">
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              {/*<Form.Item
            label="ID"
          >
            <Input
              id="id"
              value={this.state.id}
              onChange={event => {
                this.handleChange(event);
              }}
            />
            </Form.Item>*/}
              <Form.Item
                label="File By"
              >
                <Input
                  id="file_by"
                  value={this.state.file_by}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
            </Col>

          </Row>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Form.Item label="Exercise list">
                {/*<Input
                  id="exercise_details"
                  value={this.state.exercise_details}
                  onChange={(event) => {
                    this.handleChange(event);
                  }}
                />*/}
                <TextArea
                  value={this.state.exercise_details}
                  autoSize={{ minRows: 2, maxRows: 8 }}
                  onChange={this.onTextAreaChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item label="Client ID">
                    <Input
                      id="client_id"
                      value={this.state.client_id}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Show All">
                    <Radio.Group
                      onChange={this.onRadioChange}
                      value={this.state.showAll}
                      options={radio_options_yes_no}
                      optionType="button"
                      buttonStyle="solid"
                    >
                      <Radio value={1}>Yes</Radio>
                      <Radio value={0}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                disabled={!this.state.file_by || this.state.file_by === " "}
                htmlType="submit"
                onClick={this.handleSubmit}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

// const mapStateToProps = state => {
//   return {
//     app_state: state.app_state,
//     language: state.language
//   };
// };

// const mapDispatchToProps = dispatch => {
//   return {
// startNewClientCreation: () => {
//   dispatch(startNewClientCreation())
// },
// updateNewClientCreation: (key, value) => {
//   dispatch(updateNewClientCreation(key, value))
// }
// cancelCreateNewStaffMember: () => {
// dispatch(cancelCreateNewStaffMember())
// },
// confirmNewStaffMember: (data) => {
//   dispatch(confirmNewStaffMember(data))
// },
// selectStaffUi: (data) => {
//   dispatch(selectStaffUi(data))
// },
//   }
// }

// const CategoryNewPageComponentContainer = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(withStyles(styles)(CategoryNewPageComponent)));

export default WorkoutNewPageComponent;
