// import { API } from "aws-amplify";

export const CLIENTS_START_NEW_CLIENT_CREATION = "CLIENTS_START_NEW_CLIENT_CREATION";
export const CLIENTS_UPDATE_NEW_CLIENT_CREATION = "CLIENTS_UPDATE_NEW_CLIENT_CREATION";


export function startNewClientCreation() {
  return {
    type: CLIENTS_START_NEW_CLIENT_CREATION
  };
}

export function updateNewClientCreation(key, value) {
  // console.log(key, value);
  return {
    type: CLIENTS_UPDATE_NEW_CLIENT_CREATION,
    value: { key, value }
  };
}
