import DashboardPage from "../views/dashboard/DashboardPage";
import UserProfilePage from "../views/dashboard/UserProfilePage";
import ClientNewPage from "../views/dashboard/clients/ClientNewPage";
import ClientListPage from "../views/dashboard/clients/ClientListPage";
import ClientViewPage from "../views/dashboard/clients/ClientViewPage";
import StaffNewPage from "../views/dashboard/staff/StaffNewPage";
import StaffListPage from "../views/dashboard/staff/StaffListPage";
import StaffViewPage from "../views/dashboard/staff/StaffViewPage";
import VideoListPage from "../views/dashboard/videos/VideoListPage";
import VideoListViewsPage from "../views/dashboard/videos/VideoListViewsPage";
import VideoListFavouritesPage from "../views/dashboard/videos/VideoListFavouritesPage";
import UploadVideoPage from "../views/dashboard/videos/UploadVideoPage";
import VideoViewPage from "../views/dashboard/videos/VideoViewPage";
import SubscriptionListPage from "../views/dashboard/subscription/SubscriptionListPage";
import SubscriptionNewPage from "../views/dashboard/subscription/SubscriptionNewPage";
import SubscriptionSuccessPage from "../views/dashboard/subscription/SubscriptionSuccessPage";
import SubscriptionCancelPage from "../views/dashboard/subscription/SubscriptionCancelPage";
import SubscriptionViewPage from "../views/dashboard/subscription/SubscriptionViewPage";
import ProductListPage from "../views/dashboard/products/ProductListPage";
import ProductNewPage from "../views/dashboard/products/ProductNewPage";
import ProductViewPage from "../views/dashboard/products/ProductViewPage";
import PromoCodeListPage from "../views/dashboard/promocodes/PromoCodeListPage";
import PromoCodeNewPage from "../views/dashboard/promocodes/PromoCodeNewPage";
import PromoCodeViewPage from "../views/dashboard/promocodes/PromoCodeViewPage";
import PlansListPage from "../views/dashboard/plans/PlansListPage";
import PlansNewPage from "../views/dashboard/plans/PlansNewPage";
import PlansViewPage from "../views/dashboard/plans/PlansViewPage";
import TagsListPage from "../views/dashboard/tags/TagListPage";
import TagsNewPage from "../views/dashboard/tags/TagNewPage";
import TagsViewPage from "../views/dashboard/tags/TagViewPage";
//#119 - Disabling the code because we are not using it anymore by Tom
// import CategoryListPage from "../views/dashboard/categories/CategoryListPage";
import CategoryMainListPage from "../views/dashboard/categories/CategoryMainListPage";
import CategorySubListPage from "../views/dashboard/categories/CategorySubListPage";
import CategoryMainViewPage from "../views/dashboard/categories/CategoryMainViewPage";
import CategorySubViewPage from "../views/dashboard/categories/CategorySubViewPage";

import CategoryMainNewPage from "../views/dashboard/categories/CategoryMainNewPage";
import CategorySubNewPage from "../views/dashboard/categories/CategorySubNewPage";
import CategoryNewPage from "../views/dashboard/categories/CategoryNewPage";
import CategoryViewPage from "../views/dashboard/categories/CategoryViewPage";
import WorkoutListPage from "../views/dashboard/workouts/WorkoutListPage";
import WorkoutNewPage from "../views/dashboard/workouts/WorkoutNewPage";
import WorkoutViewPage from "../views/dashboard/workouts/WorkoutViewPage";
import WorkoutSetupPage from "../views/dashboard/workouts/WorkoutSetupPage";

import BrickListPage from "../views/dashboard/bricks/BrickListPage";
import BrickNewPage from "../views/dashboard/bricks/BrickNewPage";
import BrickViewPage from "../views/dashboard/bricks/BrickViewPage";

import ExerciseListPage from "../views/dashboard/exercises/ExerciseListPage";
import ExercisesNewPage from "../views/dashboard/exercises/ExercisesNewPage";
import ExercisesViewPage from "../views/dashboard/exercises/ExerciseViewPage";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardPage
  },
  {
    path: "/user-profile",
    name: "User Profile",
    component: UserProfilePage
  },
  {
    path: "/clients/new",
    name: "New Client",
    component: ClientNewPage
  },
  {
    path: "/clients/list",
    name: "Client List",
    component: ClientListPage
  },
  {
    path: "/clients/view/:client_id",
    name: "View Client",
    component: ClientViewPage
  },
  {
    path: "/staff/new",
    name: "Staff New",
    component: StaffNewPage
  },
  {
    path: "/staff/list",
    name: "Staff List",
    component: StaffListPage
  },
  {
    path: "/staff/view/:staff_id",
    name: "Staff View",
    component: StaffViewPage
  },
  {
    path: "/video/list/views",
    name: "Video List",
    component: VideoListViewsPage
  },
  {
    path: "/video/list/favourites",
    name: "Video List",
    component: VideoListFavouritesPage
  },
  {
    path: "/video/list",
    name: "Video List",
    component: VideoListPage
  },
  {
    path: "/video/upload",
    name: "Upload Video",
    component: UploadVideoPage
  },
  {
    path: "/video/view/:video_id",
    name: "Video",
    component: VideoViewPage
  },
  {
    path: "/subscription/list",
    name: "Subscription List",
    component: SubscriptionListPage
  },
  {
    path: "/subscription/new/success",
    name: "New Subscription",
    component: SubscriptionSuccessPage
  },
  {
    path: "/subscription/new/cancel",
    name: "New Subscription",
    component: SubscriptionCancelPage
  },
  {
    path: "/subscription/new",
    name: "New Subscription",
    component: SubscriptionNewPage
  },
  {
    path: "/subscription/view/:id",
    name: "Subscription",
    component: SubscriptionViewPage
  },
  {
    path: "/products/list",
    name: "Product List",
    component: ProductListPage
  },
  {
    path: "/products/new",
    name: "New Product",
    component: ProductNewPage
  },
  {
    path: "/products/view/:product_id",
    name: "Product",
    component: ProductViewPage
  },
  {
    path: "/promocodes/list",
    name: "PromoCode List",
    component: PromoCodeListPage
  },
  {
    path: "/promocodes/new",
    name: "New PromoCode",
    component: PromoCodeNewPage
  },
  {
    path: "/promocodes/view/:promocode_id",
    name: "PromoCode",
    component: PromoCodeViewPage
  },
  {
    path: "/plans/list",
    name: "Plan List",
    component: PlansListPage
  },
  {
    path: "/plans/new",
    name: "New Plans",
    component: PlansNewPage
  },
  {
    path: "/plans/view/:product_id",
    name: "Plans",
    component: PlansViewPage
  },
  {
    path: "/tags/list",
    name: "Tag List",
    component: TagsListPage
  },
  {
    path: "/tags/new",
    name: "New Tags",
    component: TagsNewPage
  },
  {
    path: "/tags/view/:id",
    name: "Tags",
    component: TagsViewPage
  },
  ////#119 - Disabling the code because we are not using it anymore by Tom
  // {
  //   path: "/categories/list",
  //   name: "Category List",
  //   component: CategoryListPage
  // },
  {
    path: "/categories/new",
    name: "New Category",
    component: CategoryNewPage
  },
  {
    path: "/categories/view/:id",
    name: "Categories",
    component: CategoryViewPage
  },
  {
    path: "/main-categories/list",
    name: "Main Category List",
    component: CategoryMainListPage
  },
  {
    path: "/main-categories/new",
    name: "New Main Category",
    component: CategoryMainNewPage
  },
  {
    path: "/main-categories/view/:id",
    name: "Main Categories",
    component: CategoryMainViewPage
  },
  {
    path: "/sub-categories/list",
    name: "Sub Category List",
    component: CategorySubListPage
  },
  {
    path: "/sub-categories/new",
    name: "New Sub Category",
    component: CategorySubNewPage
  },
  {
    path: "/sub-categories/view/:id",
    name: "Sub Categories",
    component: CategorySubViewPage
  },
  {
    path: "/workouts/list",
    name: "Workout List",
    component: WorkoutListPage
  },
  {
    path: "/workouts/new",
    name: "New Workout",
    component: WorkoutNewPage
  },
  {
    path: "/workouts/view/:id",
    name: "Workouts",
    component: WorkoutViewPage
  },
  {
    path: "/workout-builder/setup",
    name: "Workout Setup",
    component: WorkoutSetupPage
  },
  {
    path: "/bricks/list",
    name: "Brick List",
    component: BrickListPage
  },
  {
    path: "/bricks/new",
    name: "New Brick",
    component: BrickNewPage
  },
  {
    path: "/bricks/view/:id",
    name: "Bricks",
    component: BrickViewPage
  },
  {
    path: "/exercises/list",
    name: "Exercise List",
    component: ExerciseListPage
  },
  {
    path: "/exercises/new",
    name: "New Exercise",
    component: ExercisesNewPage
  },
  {
    path: "/exercises/view/:exercise_id",
    name: "Exercises",
    component: ExercisesViewPage
  },
  
  
  { redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" }
];

export default dashRoutes;
