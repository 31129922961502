import {
  CLIENTS_START_NEW_CLIENT_CREATION,
  CLIENTS_UPDATE_NEW_CLIENT_CREATION
} from "../actions/clients";

const initialState = {
  new_client_data: null,
  new_client_submitted: false,
  new_client_created: false
};

function clients(state = initialState, action) {
  switch (action.type) {
    case CLIENTS_START_NEW_CLIENT_CREATION:
      return Object.assign({}, state, {
        // staff_detail: action.staff_detail,
        new_client_data: {
          first_name: "",
          last_name: "",
          email: "",
          vip: false
        },
        new_client_submitted: false,
        new_client_created: false
        // client_detail_loaded: true
        // staff_detail_loaded: true
      });
      case CLIENTS_UPDATE_NEW_CLIENT_CREATION:
        return Object.assign({}, state, {
          new_client_data: Object.assign({}, state.new_client_data, {
            [action.value.key]: action.value.value
          }),
        });
    default:
      return state;
  }
}

// const rootReducer = combineReducers({
//   app_state
// });

export default clients;
