import React, { Component } from 'react';
import { connect } from "react-redux";

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";

//import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input } from 'antd';


const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class ProductSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  async componentDidMount() {
    const product = await this.getProductRecord(
      this.props.product_id
    );
    console.log(product)
    this.setState({
      ...product.payload
    })
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    // console.log(nextProps)
    // if (nextProps.app_state.current_user_id && nextProps.app_state.current_user_aws_cognito_id) {
    //   const user = await this.getUserProfile(
    //     nextProps.app_state.current_user_aws_cognito_id
    //   );
    //   // console.log(user)
    //   this.setState({
    //     ...user.payload
    //   })
    // }
  }


  async getProductRecord(product_id) {
    return API.get("products", `/get-by-id/${product_id}`);
  }

  async saveProductRecord(product_record) {
    await API.put("products", `/update/${this.state.id}`, {
      body: product_record
    });
    openNotificationWithIcon('success', "Save Successful", "Your data has been saved successfully")
  }


  create_stripe_product_id = () => {
    if (this.state.stripe_id) {
      openNotificationWithIcon('error', "Billing ID Exists", "A billing ID number already exists for this client")
      return;
    }
    const {
      nickname,
    } = this.state;

    API.post("stripe", `/product/create/${this.state.id}`, {
      body: {
        nickname
      }
    })
      .then(response => {
        console.log(response)
        openNotificationWithIcon('success', "Record Created", "A Billing Account has been created for this client")
        this.setState({
          stripe_id: response.payload.stripe_result.id
        })
      })
      .catch(error => {
        console.log(error);
      });

  }

  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    // console.log(event)
    this.setState({ isLoading: true });

    const {
      // id,
      // aws_cognito_id,
      nickname,
      stripe_id,
    } = this.state;
    try {
      await this.saveProductRecord({
        // aws_cognito_id: aws_cognito_id,
        nickname: nickname,
        stripe_id: stripe_id,
      });
      // this.props.history.push("/");
      // this.props.reload_user(this.state.aws_cognito_id);
    } catch (e) {
      // alert(e);
      try {
        await this.saveProductRecord({
          // aws_cognito_id: aws_cognito_id,
          // first_name: first_name,
          // middle_name: middle_name,
          // last_name: last_name,
        });
        // this.props.history.push("/");
        // this.props.reload_user(this.state.aws_cognito_id);
      } catch (e) {
        // alert(e);
        openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
        // this.setState({ isLoading: false });
      }

      // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
    }
  };

  render() {
    console.log(this.state);

    return (

      <React.Fragment>
        <Form layout="vertical">

          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Form.Item label="ID">
                <Input
                  id="id"
                  disabled
                  value={this.state.id}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Nickname">
                <Input
                  id="nickname"
                  value={this.state.nickname}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Stripe_id" >
                <Input
                  id="stripe_id"
                  value={this.state.stripe_id}
                  disabled
                // onChange={event => {
                //   this.handleChange(event);
                // }}
                />
              </Form.Item>
              <a onClick={this.create_stripe_product_id}>Create Billing ID</a>
            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={this.handleSubmit}
              >
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const ProductSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSummary);

export default ProductSummaryContainer;
