import React, { Component } from 'react';

import { 
  Card, PageHeader, Button 
} from 'antd';

import WorkoutSetupPageComponentContainer from '../../../components/pages/dashboard/workouts/WorkoutSetupPageComponent';

class WorkoutSetupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  handle_show_all = () => {
    this.props.history.push(`/workouts/list/`);
  }

  handle_new_workouts = () => {
    this.props.history.push(`/workouts/new`);
  }

  render() {
    // console.log(this.state);
    return (

      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="Workout Setup"
        />
        <Card
          style={{ minHeight: 360 }}
          title="Create a New Workout Record via these simple steps"
          extra={[
            <React.Fragment>
              {
                <span>
                  <Button key="1" onClick={this.handle_show_all}>
                    Show All
                  </Button>
                  <Button key="2" disabled onClick={this.handle_new_workouts}>
                    New Workouts
                  </Button>
                </span>
              }
            </React.Fragment>

          ]}
        >
          <div style={{ paddingTop: 24 }}>
            <WorkoutSetupPageComponentContainer />
          </div>
        </Card>
      </div>


    );
  }
}

export default WorkoutSetupPage;
