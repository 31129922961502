import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { API } from "aws-amplify";

import {
  Row, Col, Button, notification, Form, Input, Radio
} from "antd";
const moment = require("moment");
const { TextArea } = Input;

const radio_options_yes_no = [
  { label: "Yes", value: 1 },
  { label: "No", value: 0 },
];

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class WorkoutSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const workout = await this.getRecord(this.props.id);
    console.log(workout);
    this.setState({
      ...workout.payload,
    });
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    // console.log(nextProps)
    // if (nextProps.app_state.current_user_id && nextProps.app_state.current_user_aws_cognito_id) {
    //   const user = await this.getUserProfile(
    //     nextProps.app_state.current_user_aws_cognito_id
    //   );
    //   // console.log(user)
    //   this.setState({
    //     ...user.payload
    //   })
    // }
  }

  async getRecord(id) {
    return API.get("workouts", `/id/${id}`);
  }

  async saveRecord(workouts_record) {
    await API.put("workouts", `/update/${this.state.id}`, {
      body: workouts_record,
    });
    openNotificationWithIcon(
      "success",
      "Save Successful",
      "Your data has been saved successfully"
    );
  }

  handleChange = (event) => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    // console.log(event)
    this.setState({ isLoading: true });

    const {
      id,
      file_by,
      exercise_details,
      // created_datetime,
      client_id,
      showAll,
      deleted
    } = this.state;
    try {
      await this.saveRecord({
        id,
        file_by,
        exercise_details,
        // created_datetime,
        client_id,
        showAll,
        deleted
      });
      this.props.history.push("/workouts/list/");
      // this.props.reload_user(this.state.aws_cognito_id);
    } catch (e) {
      // alert(e);
      // try {
      //   await this.saveClientRecord({
      //     // aws_cognito_id: aws_cognito_id,
      //     first_name: first_name,
      //     // middle_name: middle_name,
      //     last_name: last_name,
      //   });
      //   // this.props.history.push("/");
      //   // this.props.reload_user(this.state.aws_cognito_id);
      // } catch (e) {
      // alert(e);
      openNotificationWithIcon(
        "error",
        "Save Failed",
        "An error occurred in data saving"
      );
      // this.setState({ isLoading: false });
      // }

      // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
    }
  };

  onRadioChange = (e) => {
    // console.log(`radio checked:${e.target.value}`);
    this.setState({
      showAll: e.target.value,
    });
  };

  onDeletedChange = (e) => {
    // console.log(`radio checked:${e.target.value}`);
    this.setState({
      deleted: e.target.value,
    });
  };

  onTextAreaChange = ({ target: { value } }) => {
    this.setState({
      exercise_details: value,
    });
  };

  render() {
    console.log(this.state);

    return (
      <React.Fragment>
        <Form layout="vertical">
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item label="ID">
                    <Input
                      id="id"
                      disabled
                      value={this.state.id}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="File By">
                    <Input
                      id="file_by"
                      value={this.state.file_by}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Form.Item label="Exercise list">
                {/*<Input
                  id="exercise_details"
                  value={this.state.exercise_details}
                  onChange={(event) => {
                    this.handleChange(event);
                  }}
                />*/}
                <TextArea
                  value={this.state.exercise_details}
                  autoSize={{ minRows: 2, maxRows: 8 }}
                  onChange={this.onTextAreaChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item label="Client ID">
                    <Input
                      id="client_id"
                      value={this.state.client_id}
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Show All">
                    <Radio.Group
                      onChange={this.onRadioChange}
                      value={this.state.showAll}
                      options={radio_options_yes_no}
                      optionType="button"
                      buttonStyle="solid"
                    >
                      <Radio value={1}>Yes</Radio>
                      <Radio value={0}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item label="Create Datetime">
                    <Input
                      id="created_datetime"
                      value={
                        this.state.created_datetime ?
                          moment(this.state.created_datetime).format('YYYY/MM/DD hh:mm:ss a') : ''
                      }
                      disabled
                      onChange={(event) => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <Form.Item label="Deleted">
                <Radio.Group
                  onChange={this.onDeletedChange}
                  value={this.state.deleted}
                  options={radio_options_yes_no}
                  optionType="button"
                  buttonStyle="solid"
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
              <p> To delete: click yes and press save record</p>
            </Col>
          </Row>

          <Row gutter={[96, 24]}>
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={this.handleSubmit}
              >
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    app_state: state.app_state,
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  };
};

const WorkoutSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WorkoutSummary));

export default WorkoutSummaryContainer;
