
import React, { Component } from 'react';
import { Table } from 'antd';

// import { API, Auth } from "aws-amplify";
import { API } from "aws-amplify";
import { NavLink } from "react-router-dom";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Divider } from 'antd';
import { Input, Button, Tag } from 'antd';

import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class ClientListPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: []
    }
    this.columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        render: text => <a>{text.toString().padStart(5, '0')}</a>,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        ...this.getColumnSearchProps('email'),
      },
      {
        title: 'First Name',
        dataIndex: 'first_name',
        ...this.getColumnSearchProps('first_name'),
      },
      {
        title: 'Last Name',
        dataIndex: 'last_name',
        ...this.getColumnSearchProps('last_name'),
      },
      {
        title: 'VIP',
        dataIndex: 'vip',
        //...this.getColumnSearchProps('vip'),
        filters: [
          { text: 'VIP', value: 1},
          { text: 'Not a VIP', value: 0}
        ],
        onFilter: (value, record) => record.vip === value,
        render: data_item => {
          switch (data_item) {
            case 1:
              return <Tag color={'green'} style={{ width: 100, textAlign: "center"}} key={data_item}> VIP </Tag>
            default:
              return ""
          }

        }
      },
      {
        title: 'Actions',
        key: 'action',
        render: (text, record) => (
          <span>
            <NavLink to={`/clients/view/${record.id}`} >View</NavLink>
            {/* <Divider type="vertical" />
            <a>Delete</a> */}
          </span>
        ),
      },
    ];
    
  }

  componentDidMount() {
    API.get("clients", `/list`)
      .then(response => {
        // console.log(response);
        // console.log(response.payload);

        let dataSource = [];
        for (let i = 0; i < response.payload.length; i++) {
          dataSource.push({
            key: response.payload[i].id,
            id: response.payload[i].id,
            email: response.payload[i].email,
            first_name: response.payload[i].first_name,
            last_name: response.payload[i].last_name,
            vip: response.payload[i].vip
          });
        }
        // console.log(dataSource);
        this.setState({
          dataSource: dataSource
        })
      })
      .catch(error => {
        console.log(error);
      });
  }


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
          text
        ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    // console.log(this.state);
    // console.log(this.dataSource);
    // console.log(this.props);
    return (
      <React.Fragment>
        <Table
          columns={this.columns}
          dataSource={this.state.dataSource}
          pagination={{ 
            pageSizeOptions: ['10', '20', '30'], 
            showSizeChanger: true }}
        />
      </React.Fragment>
    );
  }
}

export default ClientListPageComponent;
