import React, { Component } from 'react';

import { FormattedMessage } from 'react-intl';

// import { API, Auth } from "aws-amplify";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import { Card } from 'antd';
import { PageHeader } from 'antd';
// import { Button } from 'antd';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    // console.log(this.state);
    return (

      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title={<FormattedMessage id="dashboard_page.page_title"/>}
        />
 <Card
          style={{ minHeight: 360 }}
          title={<FormattedMessage id="dashboard_page.page_description"/>}
        >
          <div style={{ paddingTop: 24 }}>
            PAGE COMPONENT GOES HERE
          </div>
        </Card>
      </div>

    );
  }
}

export default DashboardPage;
