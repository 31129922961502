import React, { Component } from 'react';
import { connect } from "react-redux";

import { setLanguageSelectId } from "../../../redux/actions/language";

// import { API, Auth } from "aws-amplify";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Input } from 'antd';
import { PageHeader, Button } from 'antd';
// import { Descriptions } from 'antd';

import { Select } from 'antd';
import ReactCountryFlag from "react-country-flag"

const { Option } = Select;
// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class PagesHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }

  }

  handle_language_select = (value) => {
    this.props.setLanguageSelectId(value);
  }

  handleLogout = async event => {
    console.log(event);
    this.props.handleLogout();
  }

  render() {
    // console.log(this.state);
    return (
      <React.Fragment>
        <PageHeader
          ghost={false}
          // onBack={() => window.history.back()}
          title="XOlation - Admin Portal"
          // subTitle="This is a subtitle"
          extra={this.props.isAuthenticated ? [
            <Select
              defaultValue={this.props.language.language_current_ui}
              style={{ width: 180 }}
              onChange={this.handle_language_select}>
              <Option value="en">
                <ReactCountryFlag
                  className="emojiFlag"
                  countryCode="GB"
                  style={{
                    fontSize: '1.5em',
                    lineHeight: '1.5em',
                  }}
                  aria-label="English Language"
                /> English
              </Option>
              {/* <Option value="zh">
                <ReactCountryFlag
                  // className="emojiFlag"
                  countryCode="CN"
                  style={{
                    fontSize: '1.5em',
                    lineHeight: '1.5em',
                  }}
                  aria-label="Chinese Traditional Language"
                /> 繁體中文
              </Option> */}
            </Select>,
            <Button key="1" type="primary" onClick={this.handleLogout}>
              Log Out
          </Button>] : []}
        >
        </PageHeader>
      </React.Fragment>

    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLanguageSelectId: (language_id) => {
      dispatch(setLanguageSelectId(language_id))
    }
  }
}

const PagesHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PagesHeader);

export default PagesHeaderContainer;
