
import React, { Component } from 'react';

// import { API, Auth } from "aws-amplify";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import { Card } from 'antd';
import { PageHeader, Button } from 'antd';
import VideoListPageComponent from '../../../components/pages/dashboard/videos/VideoListPageComponent';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class VideoListFavouritesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  handle_show_all = () => {
    this.props.history.push(`/video/list`);
  }

  handle_upload_video = () => {
    this.props.history.push(`/video/upload`);
  }


  render() {
    return (

      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="Video List"
        />
        <Card
          style={{ minHeight: 360 }}
          title="View a list of current videos"
          extra={[
            <React.Fragment>
              {
                <span>
                  <Button key="1" disabled onClick={this.handle_show_all}>
                    Show All
                  </Button>
                  <Button key="2" onClick={this.handle_upload_video}>
                    Upload Video
                  </Button>
                </span>
              }
            </React.Fragment>

          ]}

        >
          <div style={{ paddingTop: 24 }}>
          <VideoListPageComponent favourites={true} />
          </div>
        </Card>
      </div>


    );
  }
}

export default VideoListFavouritesPage;
