
import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { API } from "aws-amplify";
// import { Auth } from "aws-amplify";

// import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input } from 'antd';
import { Select } from 'antd';

var moment = require("moment");

const { Option } = Select;

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class ClientSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  async componentDidMount() {
    const client = await this.getClientRecord(
      this.props.client_id
    );
    const last_login = await this.getLastLoginRecord(
      this.props.client_id
    );

    // console.log(client)
    // console.log(last_login)

    this.setState({
      ...client.payload,
    })

    //if null
    if (last_login.payload[0].last_login) {
      this.setState({
        last_login: moment(last_login.payload[0].last_login).format('MMMM Do YYYY, h:mm:ss a')
      })
    }


  }

  async componentWillReceiveProps(nextProps, nextContext) {
    // console.log(nextProps)
    // if (nextProps.app_state.current_user_id && nextProps.app_state.current_user_aws_cognito_id) {
    //   const user = await this.getUserProfile(
    //     nextProps.app_state.current_user_aws_cognito_id
    //   );
    //   // console.log(user)
    //   this.setState({
    //     ...user.payload
    //   })
    // }
  }

  async getLastLoginRecord(client_id) {
    return API.get("clients", `/log/id/${client_id}`);
  }

  async getClientRecord(aws_cognito_id) {
    return API.get("clients", `/id/${aws_cognito_id}`);
  }

  async saveClientRecord(client_record) {
    await API.put("clients", `/update/${this.state.id}`, {
      body: client_record
    });
    openNotificationWithIcon('success', "Save Successful", "Your data has been saved successfully")
  }

  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleDeleteSubscription = async (current_subscription_id) => {
    console.log("You are deleting ", current_subscription_id)

    try {
      await API.put("clients", `/update/${this.state.id}`, {
        body: {
          status_id: 2,
          current_subscription_id: null
        }
      });
    } catch (e) {
      console.log(e)
    }

    try {
      await API.put("subscription", `/delete/${current_subscription_id}`, {}).then(
        this.props.history.push(`/clients/list`)
      );
      openNotificationWithIcon('success', "Save Successful", "Your data has been saved successfully")
    } catch (e) {
      console.log(e)
    }



  }
    

  handleSelectChange_client_status = (item_id, option) => {
    console.log(item_id)
    console.log(option)
    // this.setState({
    //   [name]: item_id
    // });
    this.setState({
      //client_status: item_id
      status_id: item_id
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    // console.log(event)
    this.setState({ isLoading: true });

    const {
      // id,
      // aws_cognito_id,
      first_name,
      middle_name,
      last_name,
      status_id
      // email,
    } = this.state;
    try {
      await this.saveClientRecord({
        // aws_cognito_id: aws_cognito_id,
        first_name: first_name,
        middle_name: middle_name,
        last_name: last_name,
        status_id: status_id
      });
      // this.props.history.push("/");
      // this.props.reload_user(this.state.aws_cognito_id);
    } catch (e) {
      // alert(e);
      // try {
      //   await this.saveClientRecord({
      //     // aws_cognito_id: aws_cognito_id,
      //     first_name: first_name,
      //     // middle_name: middle_name,
      //     last_name: last_name,
      //   });
      //   // this.props.history.push("/");
      //   // this.props.reload_user(this.state.aws_cognito_id);
      // } catch (e) {
      // alert(e);
      openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
      // }

      // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
    }
  };

  render() {
    console.log(this.state);

    if (!this.props.app_state || !this.props.app_state.data_clients_status_list) return null;
    const clients_status_list_select_options = this.props.app_state.data_clients_status_list.map(d => <Option key={d.id} >{d.nickname}</Option>);

    return (

      <React.Fragment>
        <Form layout="vertical">

          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item label="First Name">
                    <Input
                      id="first_name"
                      value={this.state.first_name}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Middle Name(s)">
                    <Input
                      id="middle_name"
                      value={this.state.middle_name}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Last Name" >
                <Input
                  id="last_name"
                  value={this.state.last_name}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label="E-mail">
                <Input
                  id="email"
                  value={this.state.email}
                  // disabled={true}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Client Status">
                <Select
                  id="client_status"
                  showSearch
                  value={
                    typeof this.state.status_id === "number" //client_status
                      ? this.state.status_id.toString()
                      : this.state.status_id
                  }
                  placeholder="Select Client Status"
                  showArrow={true}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  }
                  onChange={this.handleSelectChange_client_status}
                >
                  {clients_status_list_select_options}
                </Select>
              </Form.Item>
              <Form.Item label="Last Login">
                <Input
                  id="last_login"
                  value={this.state.last_login}
                  disabled
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                disabled={this.state?.status_id == '2'}
                onClick={event => this.handleDeleteSubscription(this.state.current_subscription_id)}
              >
                Delete Subscription
              </Button>
            </Col>
          </Row>
          <br/>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={this.handleSubmit}
              >
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const ClientSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClientSummary));

export default ClientSummaryContainer;
