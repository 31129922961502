import React, { Component } from 'react';

// import { API, Auth } from "aws-amplify";

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import { Card } from 'antd';
import { PageHeader } from 'antd'; 
// import { Button } from 'antd';

import ExerciseViewPageComponentContainer from '../../../components/pages/dashboard/exercises/ExerciseViewPageComponent';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class ExerciseViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {

    // console.log(this.props.match.params.client_id)

    return (

      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="View Exercise"
        />
        <Card
          style={{ minHeight: 360 }}
          title="View details of current exercise"
        >
          <div style={{ paddingTop: 24 }}>
          <ExerciseViewPageComponentContainer
            exercise_id={this.props.match.params.exercise_id}
           />

          </div>
        </Card>
      </div>


    );
  }
}

export default ExerciseViewPage;
